import { Reels } from "@ruthless/component-library";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { selectCurrentWinnersTickets } from "../reducer";
import confettiCelebration from "../../../public/lotte/18353-confetti-celebration.json";
import { motion } from "framer-motion";
import { selectPlayerAliases } from "../../game/gameSlice";

const RuthlessRespin = () => {
  const isMobile = useMediaQuery({ query: "(max-device-width: 640px)" });
  const reelsRef = useRef();

  const currentWinnersTickets = useSelector(selectCurrentWinnersTickets);
  const playerAliases = useSelector(selectPlayerAliases);

  const winners = currentWinnersTickets.map((player) => player.playerAlias);

  return (
    <div>
      <div className="text-white font-bold text-center text-xl mt-2 mb-6">New finalists</div>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}
        onAnimationComplete={() => reelsRef.current.spin?.()}
        className="flex flex-col w-full items-center mt-5"
      >
        <Reels
          variant={isMobile ? "small" : "large"}
          ref={reelsRef}
          animationData={confettiCelebration}
          items={Array.from(new Set([...winners, ...playerAliases]))}
          winners={winners}
          lockedReels={currentWinnersTickets.length === 1 ? [1, 3] : currentWinnersTickets.length === 2 ? [3] : []}
        />
      </motion.div>
    </div>
  );
};

export default RuthlessRespin;
