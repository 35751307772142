import { motion } from "framer-motion";
import Currency from "../../ui/components/Currency";
import WinnersContainer from "../../ui/components/WinnersContainer";
import { useSelector } from "react-redux";
import { selectCashQualifierPrizes, selectRecentLineWinners } from "../gameSlice";

const WinnerModal = ({ currency, active }) => {
  const recentLineWinners = useSelector(selectRecentLineWinners);
  const cashQualifierPrizes = useSelector(selectCashQualifierPrizes);

  const getModalData = () => {
    const { type, winners } = recentLineWinners;

    const playerNames = winners.map((player) => player.playerAlias).join(", ") ?? "";

    switch (type) {
      case "oneLine":
        return {
          roundCompletedTitle: "Round 1",
          completedPrizeValue: cashQualifierPrizes["oneLine"],
          nextRoundTitle: "Round 2",
          nextRoundPrizeValue: cashQualifierPrizes["twoLine"],
          nextLinesText: "2 Lines",
          playerNames,
        };
      case "twoLine":
        return {
          roundCompletedTitle: "Round 2",
          completedPrizeValue: cashQualifierPrizes["twoLine"],
          nextRoundTitle: "Round 3",
          nextRoundPrizeValue: cashQualifierPrizes["fullHouse"],
          nextLinesText: "3 Lines",
          playerNames,
        };
      case "fullHouse":
        return {
          roundCompletedTitle: "Round 3",
          completedPrizeValue: cashQualifierPrizes["fullHouse"],
          nextRoundTitle: "Game Over",
          nextRoundPrizeValue: null,
          nextLinesText: null,
          playerNames,
        };
      default:
        return {
          roundCompletedTitle: null,
          completedPrizeValue: null,
          nextRoundTitle: null,
          nextRoundPrizeValue: null,
          nextLinesText: null,
          playerNames: "",
        };
    }
  };

  // const roundCompletedTitle = {
  //   twoLine: "Round 1",
  //   fullHouse: "Round 2",
  //   gameOver: "Round 3",
  // };

  // const completedPrizeValue = {
  //   twoLine: cashQualifierPrizes["oneLine"],
  //   fullHouse: cashQualifierPrizes["twoLine"],
  //   gameOver: cashQualifierPrizes["fullHouse"],
  // };

  // const nextRoundTitle = {
  //   twoLine: "Round 2",
  //   fullHouse: "Round 3",
  // };

  // const nextRoundPrizeValue = {
  //   twoLine: cashQualifierPrizes["twoLine"],
  //   fullHouse: cashQualifierPrizes["fullHouse"],
  // };

  // const nextLinesText = {
  //   twoLine: "2 Lines",
  //   fullHouse: "3 Lines",
  // };

  // const playerNames = lineWinners[gameRound]?.map((player) => player.playerAlias).join(", ") ?? "";

  const modalData = getModalData();

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={`z-[100] fixed inset-0 pointer-events-none`}>
      <div className="fixed inset-0 backdrop-blur modal-overlay" />
      <div className="text-white fixed inset-0 flex justify-center items-center">
        <div className="flex flex-col w-3/4 mt-16">
          <WinnersContainer winners={[modalData.playerNames]} title={modalData.roundCompletedTitle} />
          {modalData.roundCompletedTitle === "Round 3" ? (
            <div className="mt-6 text-center">
              <p className="w-3/4 mx-auto">Full House! Congratulations! Game Over!</p>
            </div>
          ) : (
            <div className="mt-6 text-center">
              <div className="text-5xl font-bold">
                <Currency value={parseInt(modalData.completedPrizeValue, 10)} currency={currency} style="currency" />
              </div>
              <div className="w-3/4 mx-auto">
                <div className="mt-4">
                  Get ready for <span className="text-semibold">{modalData.nextRoundTitle}</span> where we are playing for{" "}
                  <span className="font-bold text-[#FFC123]">
                    <Currency value={parseInt(modalData.nextRoundPrizeValue, 10)} currency={currency} style="currency" />
                  </span>
                </div>
                <p>{modalData.nextLinesText} to win!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default WinnerModal;
