import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LiveBadge from "./LiveBadge";
import { useLocation } from "react-router";
import { ref, onValue } from "firebase/database";
import moment from "moment";
import { selectUniqueScheduleIdsFromRooms, useGetScheduleQuery, useGetUserPurchasedTicketsQuery } from "../../../api/api";
import { selectLiveTickets, setLiveTickets } from "../../livegamesSlice";
import useFirebase from "../../../shared/hooks/useFirebase";
import { selectOperator } from "../../../session/sessionSlice";

const LiveGames = () => {
  const liveTickets = useSelector(selectLiveTickets);
  const operator = useSelector(selectOperator);
  const scheduleIds = useSelector(selectUniqueScheduleIdsFromRooms);

  const { data: schedules } = useGetScheduleQuery({ operator, scheduleIds }, { skip: scheduleIds.length === 0 });

  const [previousTickets, setPreviousTickets] = useState([]);
  const [refetchFirebaseCount, setRefetchFirebaseCount] = useState(0);

  const { purchasedTicketsWithinLastThreeHours, isPurchasedTicketsSuccess, fulfilledTimeStamp } = useGetUserPurchasedTicketsQuery(undefined, {
    pollingInterval: 30000,
    selectFromResult: ({ data, isSuccess, fulfilledTimeStamp }) => ({
      purchasedTicketsWithinLastThreeHours: data?.filter((ticket) => moment(ticket.scheduleTime).isBetween(moment().subtract(3, "h"), moment().add(3, "h"))) ?? [],
      isPurchasedTicketsSuccess: isSuccess,
      fulfilledTimeStamp,
    }),
    skip: !schedules,
  });

  const {
    firebase: { database, envName, isInitialized },
  } = useFirebase();

  const dispatch = useDispatch();
  const location = useLocation();

  const hideLiveBadge = location.pathname.startsWith(`/room/`) || location.pathname.startsWith(`/game_room/`) || location.pathname.startsWith(`/game/`);

  useEffect(() => {
    if (!isInitialized || !isPurchasedTicketsSuccess) return;

    const prevTickets = previousTickets.map((ticket) => `${ticket.room_id}-${ticket.schedule_id}-${ticket.scheduleTime}`);
    const currentTickets = purchasedTicketsWithinLastThreeHours.map((ticket) => `${ticket.room_id}-${ticket.schedule_id}-${ticket.scheduleTime}`);

    const hasTicketsChanged = !currentTickets.every((ticket) => prevTickets.includes(ticket)) || prevTickets.length !== currentTickets.length;

    if (hasTicketsChanged) {
      setRefetchFirebaseCount(refetchFirebaseCount + 1);
    }

    setPreviousTickets(purchasedTicketsWithinLastThreeHours);
  }, [isInitialized, fulfilledTimeStamp]);

  useEffect(() => {
    return fetchParticipatingGames();
  }, [refetchFirebaseCount]);

  const fetchParticipatingGames = () => {
    if (!database) return;

    const games = ref(database, `/${envName}/Client`);

    const unsubscribe = onValue(games, (snapshot) => {
      if (snapshot.exists()) {
        const games = snapshot.val();

        const liveTickets = purchasedTicketsWithinLastThreeHours.filter((ticket) => {
          const hasMatchingLiveGame = Object.keys(games).find((key) => {
            const details = games[key];

            const scheduleLocale = schedules.find((schedule) => schedule.id === ticket.schedule_id).locale;

            const formatScheduleTime = moment(ticket.scheduleTime).tz(scheduleLocale).format("YYYY-MM-DD HH:mm:ss");

            return details.scheduleTime === formatScheduleTime && details.scheduleId === ticket.schedule_id && details.hasGameEnded === false;
          });

          return hasMatchingLiveGame;
        });

        if (liveTickets?.length > 0) {
          dispatch(setLiveTickets(liveTickets));
        }
      }
    });

    return () => unsubscribe();
  };

  if (hideLiveBadge) return null;

  return <>{liveTickets?.length > 0 && <LiveBadge />}</>;
};

export default LiveGames;
