import { motion } from "framer-motion";
import moment from "moment";

const Message = ({ alias, message, timestamp }) => {
  const renderChatMessage = (message) => {
    const escape = (s) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

    const emojiMap = {
      ":)": "😊",
      ":(": "🙁",
      ":D": "😁",
      ";(": "😥",
      ":O": "😮",
      ";)": "😉",
      "8)": "😎",
      ">:@": "😡",
      ":P": "😛",
      ":fire:": "🔥",
    };
    const pattern = new RegExp(Object.keys(emojiMap).map(escape).join("|"), "g");
    return message.replace(pattern, (match) => emojiMap[match]);
  };

  return (
    <motion.div layout initial={{ y: 100 }} animate={{ y: 0 }} transition={{ type: "spring", mass: 1, stiffness: 100, damping: 15 }} className="px-2 text-xs short:text-sm">
      <div className="flex justify-between items-center">
        <div className="text-[#A8C8F7] font-bold">{alias}</div>
        <div className="text-[#A4A4A4]">{moment(timestamp).subtract(1, "s").fromNow()}</div>
      </div>
      <div className="text-white break-all">{renderChatMessage(message)}</div>
    </motion.div>
  );
};

export default Message;
