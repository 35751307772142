import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";

import Ticket from "./Ticket";
import { PulseLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import { Link, useHistory } from "react-router-dom";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { CTA } from "@ruthless/component-library";
import { useGetRoomsQuery, useGetUserPurchasedTicketsQuery } from "../../api/api";

const ticketWalletParent = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.15,
      when: "beforeChildren",
    },
  },
  exit: {},
};

const ticketWalletChild = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 35,
    },
  },
  exit: {
    opacity: 0,
  },
};

const TicketList = ({ type, schedule, date, listUserPurchases, operator }) => {
  const { liveTickets } = useSelector((state) => state.livegames);
  // const [tickets, setTickets] = useState({});
  const [startingSoonTickets, setStartingSoonTickets] = useState({});
  // const [extraTickets, setExtraTickets] = useState({});
  // const [endOfTickets, setEndOfTickets] = useState(false);
  const [page, setPage] = useState(1);
  // const [fetching, setFetching] = useState(false);
  // const scrollToTicket = useRef();
  const history = useHistory();

  const allCategories = ["All Games", "Starting Soon", "Live Now"];
  const TICKETS_PER_PAGE = 10;

  const { data: tickets, isLoading, isSuccess, isFetching } = useGetUserPurchasedTicketsQuery((page - 1) * TICKETS_PER_PAGE);

  //const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loadedTickets, setLoadedTickets] = useState([]);

  const dispatch = useDispatch();

  // const [roomsLoading, rooms] = useRooms(operator, { timeout: 600000 });
  const { data: rooms } = useGetRoomsQuery(operator);

  useEffect(() => {
    // set background music here if needed
    //setCategories(allCategories);
    setSelectedCategory("All Games");

    dispatch(setCurrentScreen(SCREENS.TICKETS));
  }, []);

  useEffect(() => {
    if (!isFetching && isSuccess) {
      setLoadedTickets((prev) =>
        tickets.reduce(
          (acc, curr) => {
            if (!acc.find((ticket) => ticket.ticketId === curr.ticketId)) {
              acc.push(curr);
            }

            return acc;
          },
          [...prev]
        )
      );
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isSuccess) return;

    if (selectedCategory === "Starting Soon") {
      filterTickets();
    }
  }, [selectedCategory, isSuccess]);

  const filterTickets = () => {
    const filteredStartingSoon = tickets.filter((ticket) => {
      const time = moment(ticket.scheduleTime);

      const timeSince = moment.duration(time.diff(Date.now())).asMinutes();

      const startingSoon = timeSince > 0.5 && timeSince <= 15;

      return startingSoon;
    });

    setStartingSoonTickets(filteredStartingSoon);
  };

  const gotoGame = (roomId, scheduleId, time) => {
    history.push({
      pathname: `/game/${roomId}/${scheduleId}/${time}`,
      state: {},
    });
  };

  const gotoRoom = (roomId) => {
    history.push({
      pathname: `/room/${roomId}`,
      state: {},
    });
  };

  const gotoPreGameRoom = (roomId, scheduleId) => {
    history.push({
      pathname: `/game_room/${roomId}?scheduleId=${scheduleId}`,
      state: {},
    });
  };

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <div className="text-white">Tickets</div>

      <motion.div variants={ticketWalletParent} initial="hidden" animate="visible" exit="exit" className="flex flex-col items-center">
        <motion.div variants={ticketWalletChild} className="w-full text-white bg-[#072149] py-1.5 px-2 rounded-[42px] mt-6 mb-4 md:mb-8 border border-white/40">
          <div className="flex justify-between">
            {allCategories.map((category, index) => {
              return (
                <motion.button
                  onClick={() => setSelectedCategory(category)}
                  type="button"
                  className="px-2.5 py-1.5 rounded-[32px] font-pdm-secondary font-bold uppercase"
                  style={{ background: selectedCategory === category ? "radial-gradient(170.51% 53.55% at 50% 0%, #E9F1FD 0%, #679EF2 38.94%, #3B7AFB 100%)" : "transparent" }}
                  key={index}
                >
                  {category}
                </motion.button>
              );
            })}
          </div>
        </motion.div>

        <motion.div variants={ticketWalletChild} className={`w-full mx-auto flex items-center justify-center flex-col`}>
          {selectedCategory === "All Games" && (
            <>
              {loadedTickets.map((ticket, index) => {
                // const ticket = tickets[key];
                const time = moment(ticket.scheduleTime);
                const room = rooms && rooms.filter((room) => room.id === ticket.room_id)[0];
                const timeSince = moment.duration(time.diff(Date.now())).asMinutes();
                const startingSoon = timeSince > 0.5 && timeSince <= 15;
                const findLiveTickets = liveTickets.find((el) => el["scheduleTime"] == ticket.scheduleTime && el["room_id"] == ticket.room_id) !== undefined;
                const status = () => {
                  if (startingSoon) {
                    return {
                      status: "STARTING SOON",
                      //styles: "bg-pdm-blues-deep text-pdm-main-white text-sm py-3.5 leading-4",
                    };
                  } else if (findLiveTickets) {
                    return {
                      status: "LIVE",
                      //styles: "bg-pdm-blues-deep text-pdm-main-white text-sm py-5",
                    };
                  } else {
                    return {
                      status: "ENDED",
                      //styles: "bg-pdm-blues-deep text-sm py-5",
                    };
                  }
                };

                return (
                  <Ticket
                    key={index}
                    room={room}
                    gotoGame={gotoGame}
                    gotoRoom={gotoRoom}
                    gotoPreGameRoom={gotoPreGameRoom}
                    ticket={ticket}
                    time={time}
                    reorder={true}
                    status={status().status}
                    //styles={status().styles}
                  />
                );
              })}
            </>
          )}
        </motion.div>

        <motion.div variants={ticketWalletChild} className={`w-full mx-auto flex items-center justify-center flex-col`}>
          {selectedCategory === "Starting Soon" && (
            <>
              {Object.entries(startingSoonTickets).length > 0 ? (
                <>
                  {Object.keys(startingSoonTickets).map((key, index) => {
                    const ticket = tickets[key];
                    const time = moment(ticket.scheduleTime);
                    const room = rooms && rooms.filter((room) => room.id === ticket.room_id)[0];

                    return (
                      <Ticket key={index} room={room} gotoGame={gotoGame} gotoRoom={gotoRoom} gotoPreGameRoom={gotoPreGameRoom} ticket={ticket} time={time} reorder={true} status="STARTING SOON" />
                    );
                  })}
                </>
              ) : (
                <motion.div variants={ticketWalletChild}>
                  <motion.div whileHover={{ scale: 1.05 }} className="text-white text-center mt-6">
                    <h2 className="text-xl font-bold mb-4">No Games Starting Soon Found.</h2>
                    <CTA variant="secondary" className="uppercase mx-auto" onClick={() => history.push("/schedule")}>
                      Buy tickets here
                    </CTA>
                  </motion.div>
                </motion.div>
              )}
            </>
          )}
        </motion.div>

        <motion.div variants={ticketWalletChild} className={`w-full mx-auto flex items-center justify-center flex-col`}>
          {selectedCategory === "Live Now" && (
            <>
              {liveTickets.length > 0 ? (
                <>
                  {liveTickets.map((ticket, index) => {
                    const time = moment(ticket.scheduleTime);
                    const room = rooms && rooms.filter((room) => room.id === ticket.room_id)[0];

                    return <Ticket key={index} ticket={ticket} time={time} room={room} gotoGame={gotoGame} gotoRoom={gotoRoom} gotoPreGameRoom={gotoPreGameRoom} reorder={true} status="LIVE" />;
                  })}
                </>
              ) : (
                <motion.div variants={ticketWalletChild}>
                  <motion.div whileHover={{ scale: 1.05 }} className="text-white text-center mt-6">
                    <h2 className="text-xl font-bold mb-4">No Live Games Found.</h2>
                    <CTA variant="secondary" className="uppercase mx-auto" onClick={() => history.push("/schedule")}>
                      Buy tickets here
                    </CTA>
                  </motion.div>
                </motion.div>
              )}
            </>
          )}
        </motion.div>
      </motion.div>

      {selectedCategory === "All Games" && (
        <div className="w-1/3 mx-auto flex flex-row justify-center opacity-50 items-center text-center mt-6 mb-24 text-white">
          <button onClick={() => setPage(page + 1)}>
            <p className="mb-3">Load More</p>
            <PulseLoader color={"blue"} loading={isFetching} />
          </button>
        </div>
      )}
    </div>
  );
};

export default TicketList;
