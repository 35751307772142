import { FormattedNumber } from "react-intl";

const Currency = ({ value, currency }) => {
  if (value === undefined) {
    return <p>...</p>;
  }

  if (currency === "BP") {
    return `${value} BP`;
  }

  if (currency === "BB") {
    return `${value} BB`;
  }

  if (isNaN(value)) {
    return value;
  }
  const currencyCode = typeof currency === "string" ? currency : currency.code;
  return <FormattedNumber value={value} currency={currencyCode} style="currency" />;
};

export default Currency;
