import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import LiveList from "./LiveList";
import { setListOpen } from "../../livegamesSlice";

const LiveBadge = () => {
  const { listOpen } = useSelector((state) => state.livegames);

  const dispatch = useDispatch();

  return (
    <div className="w-20 h-20 md:w-24 md:h-24 flex items-center justify-center fixed top-16 left-4 md:top-24 md:left-6 z-50 rounded-full">
      <div className="relative w-full h-full flex items-center justify-center rounded-full">
        {!listOpen && (
          <motion.div
            initial={{ scale: 1 }}
            animate={{ scale: 1.15 }}
            transition={{ duration: 1, repeat: Infinity, repeatType: "mirror" }}
            className="absolute top-0 left-0 bottom-0 right-0 z-40 bg-green-400 rounded-full filter blur-md"
          />
        )}

        <motion.button
          initial={{ scale: 1 }}
          animate={{ scale: listOpen ? 0.3 : 1 }}
          onClick={() => dispatch(setListOpen(!listOpen))}
          className={`absolute w-full h-full ${listOpen ? "bg-red-600" : "bg-green-500"}  rounded-full  text-pdm-main-white uppercase font-bold text-shadow-sm leading-5 z-50`}
        >
          <span className={`opacity-100 text-center ${listOpen ? "text-5xl" : "text-md"}`}>{listOpen ? "X" : "YOUR GAMES"}</span>
        </motion.button>

        <AnimatePresence>{listOpen && <LiveList />}</AnimatePresence>
      </div>
    </div>
  );
};

export default LiveBadge;
