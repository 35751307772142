import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { Container, UserIcon } from "@ruthless/component-library";
import { useDispatch, useSelector } from "react-redux";
import Balance from "../../navigation/components/Balance";

import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { useGetBalanceQuery } from "../../api/api";

const Profile = () => {
  const { data: balanceData, isLoading: isLoadingBalance } = useGetBalanceQuery();

  const playerName = useSelector((state) => state.player?.name);
  //const playerBalance = useSelector((state) => state.player.balance.balance);
  //const currency = useSelector((state) => state.player.balance.currency);
  const routes = useSelector((state) => state.session.routes);
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.PROFILE));
    //dispatch(getBalance());
  }, []);

  return (
    <section className="flex flex-col justify-center items-center">
      <div className="bg-black/30 p-2 rounded-full border border-white/20 mb-4 short:mb-8">
        <div className="profileAvatar flex justify-center items-center p-4 rounded-full border border-white/20 relative z-10">
          <UserIcon className="stroke-[#A8C8F7] w-6 short:w-12 h-6 short:h-12" />
        </div>
      </div>
      <div className="text-white">
        <div className="text-center">Your Profile</div>
        <div className="text-center font-bold text-2xl short:text-4xl">{playerName}</div>
      </div>
      <Container className="w-full p-6 font-bold text-base short:text-lg mt-8 lg:max-w-xl rounded-2xl">
        <div className="flex justify-between">
          <div className="text-[#A8C8F7]">Your balance</div>
          <div>
            <div className="text-[#FFC123] font-pdm-secondary"> {isLoadingBalance ? null : <Balance currentBalance={balanceData.balance.price} currency={balanceData.balance.currency} />}</div>
          </div>
        </div>
        <div className="text-white mt-4 short:mt-8 cursor-pointer relative z-10">
          {routes.wallet && (
            <div className="py-3 border-b-[1px] border-black/10" onClick={() => (window.location = routes.wallet)}>
              Manage funds
            </div>
          )}
          <div className="py-3 border-b-[1px] border-black/10" onClick={() => history.push("/tickets")}>
            Tickets
          </div>
          {routes.password && (
            <div className="py-3 border-b-[1px] border-black/10" onClick={() => (window.location = routes.password)}>
              Change password
            </div>
          )}
          {routes.logout && (
            <div className="py-3" onClick={() => (window.location = routes.logout)}>
              Logout
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default Profile;
