import { VolumeUpIcon, VolumeOffIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMute, setBackgroundVolume, setEffectsVolume, setControlsOpen } from "../reducer";
import { useClickAway } from "react-use";

const Controls = () => {
  const menuRef = useRef(null);

  const { volume, mute } = useSelector((state) => state.sound);
  // const [unmuteValue, setUnmuteValue] = useState({
  //   background: null,
  //   effects: null,
  // });

  const dispatch = useDispatch();

  useClickAway(menuRef, (e) => {
    if (!e.target.matches("[data-icon-container-name='sound'] *")) {
      dispatch(setControlsOpen(false));
    }
  });

  // useEffect(() => {
  //   if (volume.background > 0 || volume.effects > 0) {
  //     dispatch(setMute(false));
  //   }
  //   if (volume.background === 0 && volume.effects === 0) {
  //     dispatch(setMute(true));
  //   }
  // }, [volume.background, volume.effects]);

  const onMute = () => {
    if (!mute) {
      //setUnmuteValue({ background: volume.background, effects: volume.effects });
      dispatch(setMute(!mute));
      // dispatch(setBackgroundVolume(0));
      // dispatch(setEffectsVolume(0));
    }
    if (mute) {
      dispatch(setMute(!mute));
      // dispatch(setBackgroundVolume(unmuteValue.background));
      // dispatch(setEffectsVolume(unmuteValue.effects));
    }
  };

  return (
    <motion.div
      ref={menuRef}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      style={{ originX: 1, originY: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
      className="soundControl bg-white bg-opacity-90 py-4 px-4 rounded-lg z-50 absolute top-full right-0 flex flex-col space-y-3 w-56 shadow-md select-none"
    >
      <div className="text-center text-pdm-main-nav-blue font-bold rounded-lg uppercase">Volume Controls</div>
      <div className="flex">
        <span className="w-[100px]">Music:</span>
        <input className="cursor-pointer ml-auto w-full" type="range" min={0} max={1} step={0.02} value={volume.background} onChange={(e) => dispatch(setBackgroundVolume(e.target.valueAsNumber))} />
      </div>
      <div className="flex">
        <span className="w-[100px]">Effects:</span>
        <input className="cursor-pointer ml-auto w-full" type="range" min={0} max={1} step={0.02} value={volume.effects} onChange={(e) => dispatch(setEffectsVolume(e.target.valueAsNumber))} />
      </div>

      <div className="flex items-center">
        <span>{mute ? "Unmute:" : "Mute:"}</span>
        <button onClick={onMute} className={`h-4 w-4 ${mute ? "ml-2" : "ml-7"} `}>
          {!mute ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </button>
      </div>
    </motion.div>
  );
};

export default Controls;
