import { Container, UserIcon } from "@ruthless/component-library";
import { useLottie } from "lottie-react";
import confettiCelebration from "../../../public/lotte/18353-confetti-celebration.json";
import { useEffect } from "react";

const WinnersContainer = ({ winners, title = "", onConfettiAnimationComplete }) => {
  const { View, goToAndPlay } = useLottie({
    animationData: confettiCelebration,
    loop: false,
    autoplay: false,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
    onComplete: onConfettiAnimationComplete,
    renderer: "canvas",
  });

  useEffect(() => {
    goToAndPlay(0, false);
  }, [title]);

  return (
    <div className="flex flex-col items-center justify-center w-full md:max-w-xl mx-auto relative">
      <div className="fixed inset-0 -z-[1]">{View}</div>
      <div className="text-lg font-bold text-white">{title}</div>
      <div className="modal-winner-text font-bold text-5xl mt-1 uppercase">{winners.length <= 1 ? "winner" : "winners"}</div>
      <div className="w-full border border-[#E4A400] p-4 xs:p-6 mt-6 rounded-[32px] shadow-[0px_0px_32px_#FFC123] space-y-2 text-white text-base xs:text-xl font-bold bg-pdm-blues-darkest">
        {winners.map((winner) => (
          <Container key={winner} className={`flex justify-center items-center py-3.5 rounded-2xl ${winners.length <= 1 ? "h-[90px]" : ""}`}>
            <div className="flex justify-between items-center space-x-3">
              <UserIcon className="w-6 h-6 fill-[#679EF2]" />
              <div>{winner}</div>
            </div>
          </Container>
        ))}
      </div>
    </div>
  );
};

export default WinnersContainer;
