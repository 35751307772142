import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import confettiCelebration from "../../../../public/lotte/18353-confetti-celebration.json";

import { Container, StealShare } from "@ruthless/component-library";
import { setCurrentBackground, setCurrentScreen, setCurrentStageLightColor } from "../../../ui/uiSlice";
import { useLottie } from "lottie-react";
import { SCREENS } from "../../../globals";
import { selectContestantsChoices } from "../../reducer";
import useDelay from "../../../shared/hooks/useDelay";

const Reveal = () => {
  const [showFinalResults, setShowFinalResults] = useState(false);

  const delay = useDelay();
  const { View, goToAndPlay } = useLottie({ animationData: confettiCelebration, loop: false, autoplay: false, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } });
  const dispatch = useDispatch();

  const contestantsChoices = useSelector(selectContestantsChoices);

  const [stage, setStage] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    initReveal();
    dispatch(setCurrentScreen(SCREENS.REVEAL));

    return () => {
      dispatch(setCurrentBackground(null));
      dispatch(setCurrentStageLightColor(null));
    };
  }, []);

  const initReveal = async () => {
    await delay(2000);
    setStage(0);
  };

  const handleAnimationComplete = async (result) => {
    setResults((prev) => [...prev, result]);
    setStage((prev) => (prev === 2 ? 2 : prev + 1));
    dispatch(setCurrentStageLightColor(result.choice === "steal" ? "red" : "green"));
    dispatch(setCurrentBackground(result.choice === "steal" ? "red" : "green"));
    goToAndPlay(0, false);

    if (stage === 2) {
      await delay(3000);
      setShowFinalResults(true);
    }
  };

  if (showFinalResults) {
    return (
      <div className="text-center text-white font-bold mt-2 w-full md:w-3/4 md:mx-auto">
        <div className="text-lg">The final the results are</div>
        <div className="mt-5 space-y-3">
          {contestantsChoices.map((result) => (
            <Container key={`reveal-${result.playerAlias}`} className="rounded-2xl p-4 flex justify-between items-center">
              <div>{result.playerAlias}</div>
              <StealShare variant={result.choice}>{result.choice}</StealShare>
            </Container>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="relative h-full">
      <div className="absolute inset-0">{View}</div>
      <AnimatePresence exitBeforeEnter>
        {stage !== null && (
          <motion.div key={`reveal-${stage}`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="text-white text-center mt-12" transition={{ duration: 2 }}>
            <div>
              <div className="text-lg font-bold h-10">{contestantsChoices[stage].playerAlias ?? ""}</div>
              <div>has chosen to</div>
            </div>
            <motion.div
              className="flex justify-center mt-10"
              initial={{ opacity: 0, y: -150, scale: 0 }}
              animate={{ opacity: 1, y: 0, scale: 2 }}
              transition={{ delay: 4 }}
              onAnimationComplete={() => handleAnimationComplete(contestantsChoices[stage])}
            >
              <div className="p-1 rounded-[48px] bg-pdm-blues-darkest">
                <StealShare variant={contestantsChoices[stage]?.choice ?? "steal"}>{contestantsChoices[stage]?.choice ?? "steal"}</StealShare>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {results.length > 0 && (
        <div className="mt-14 space-y-4 md:w-3/4 md:mx-auto">
          {results.map((result) => (
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              key={`reveal-${result.uuid}`}
              className="flex justify-between items-center text-white font-bold"
            >
              <div>{result.playerAlias ?? ""}</div>
              <StealShare variant={result.choice ?? "steal"}>{result.choice ?? "steal"}</StealShare>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Reveal;
