import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { initGame, setName, letsBegin } from "../reducer";

import PlayerNameModal from "../components/PlayerNameModal";
import { RECEIVE_TOKEN } from "../../config/actionTypes";

// const onConfirmName = (name) => (dispatch, getState) =>
//   Promise.resolve()
//     .then(() =>
//       dispatch({
//         type: "api/operator/alias",
//         method: "post",
//         data: { name },
//       })
//     )
//     .then((data) => {
//       if (data.error) {
//         throw "failed to set player name";
//       } else {
//         dispatch({
//           type: RECEIVE_TOKEN,
//           token: data.data.locals.accessToken,
//         });
//         dispatch(setName({ name }));
//         dispatch(letsBegin());
//       }
//     })
//     .catch((e) => {
//       console.error(e);
//       throw "Something went wrong.";
//     });

const onStartGame = () => (dispatch) =>
  Promise.resolve().then(() => {
    dispatch(initGame());
  });

const mapStateToProps = (state, ownProps) => {
  const { player } = state;
  const { name: playerName, page: pageNumber, welcomeBack } = player;
  return {
    playerName: playerName,
    page: pageNumber,
    welcomeBack,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onStartGame: () => dispatch(onStartGame()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerNameModal));
