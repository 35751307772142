import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { BingoTicket } from "@ruthless/component-library";
import useDelay from "../../shared/hooks/useDelay";

const BingoTickets = ({ remainingNumberOfBalls, ballsToGoInRows, ticketData, purchaseId, ballHistory, currentRound }) => {
  const initialDrop = useRef(false);
  const delay = useDelay();

  const [currentToGo, setCurrentToGo] = useState(remainingNumberOfBalls);
  const [ballNumbers, setBallNumbers] = useState([]);

  useEffect(() => {
    if (initialDrop.current) {
      delay(1000).then(() => {
        setCurrentToGo(remainingNumberOfBalls);
        setBallNumbers(getTicketBalls(remainingNumberOfBalls));
      });
    } else {
      setCurrentToGo(remainingNumberOfBalls);
      setBallNumbers(getTicketBalls(remainingNumberOfBalls));
    }

    initialDrop.current = true;
  }, [ballsToGoInRows]);

  const getTicketBalls = (remainingBallsNumber) => {
    switch (remainingBallsNumber) {
      case 1:
      case 2:
      case 3:
        return getTicketBallsToGo(remainingBallsNumber);
      default:
        return [...ticketData.map(getDabbed).flat().filter(Boolean)];
    }
  };

  const getDabbed = (numberRow) => {
    return numberRow.map((number) => {
      if (number !== "_undefined_") {
        const dabbed = ballHistory.includes(number);
        return {
          value: number,
          selected: dabbed,
        };
      }
    });
  };

  const getTicketBallsToGo = (remainingBallsNumber) => {
    const parseNumbers = (array) => array.filter((value) => !ballHistory.includes(value) && Number(value)).map((number) => ({ value: number, selected: false }));

    if (currentRound === "Round 3") {
      return parseNumbers([...ticketData[0], ...ticketData[1], ...ticketData[2]]);
    }

    if (currentRound === "Round 2") {
      if (ballsToGoInRows[0] + ballsToGoInRows[1] === remainingBallsNumber) {
        return parseNumbers([...ticketData[0], ...ticketData[1]]);
      } else if (ballsToGoInRows[0] + ballsToGoInRows[2] === remainingBallsNumber) {
        return parseNumbers([...ticketData[0], ...ticketData[2]]);
      } else if (ballsToGoInRows[1] + ballsToGoInRows[2] === remainingBallsNumber) {
        return parseNumbers([...ticketData[1], ...ticketData[2]]);
      }
    }

    if (currentRound === "Round 1") {
      const rowIndex = ballsToGoInRows.findIndex((row) => row === remainingBallsNumber);

      return parseNumbers(ticketData[rowIndex] ?? []);
    }

    return [];
  };

  return (
    <div className="relative md:mx-auto md:max-w-[400px] h-[100px] short:h-[120px]">
      <AnimatePresence>
        {Array(Math.min(currentToGo + 2, 5) - currentToGo + 1)
          .fill()
          .map((_, i) => currentToGo + i)
          .map((value, idx) => (
            <motion.div
              key={value}
              initial={{ opacity: 0, y: idx === 0 ? -100 : 0 }}
              animate={{ opacity: 1, y: idx * 10, zIndex: 3 - idx, scale: idx === 0 ? 1 : 1 - idx * 0.05 }}
              exit={{ opacity: 0 }}
              className="absolute w-full"
            >
              <BingoTicket
                ticketId={purchaseId}
                numbers={idx === 0 ? ballNumbers.map((ball) => ({ value: ball.value, selected: ballHistory.includes(ball.value) })) : []}
                toGo={value}
                color={value === 1 ? "gold" : "blue"}
              />
            </motion.div>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default BingoTickets;
