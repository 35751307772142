import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export const createCalendarReminder = (scheduleTime) => {
  const uuid = uuidv4();
  const dateStamp = new Date().toISOString().replace(/:|-|\..+/g, "");

  let startTimeMoment = moment(scheduleTime);
  const startTime = startTimeMoment.format("YYYYMMDDTHHmmss");

  const calendarString = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//PDM Enlivon//Ruthless Calendar Reminder Version 1.0//EN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nDTSTAMP:${dateStamp}\nUID:${uuid}\nDTSTART:${startTime}\nDTEND:${startTime}\nSUMMARY:Ruthless\nDESCRIPTION:Reminder to Play Ruthless\nLOCATION:${process.env.OPERATOR_URL}\nBEGIN:VALARM\nTRIGGER:-PT5M\nREPEAT:1\nDURATION:PT5M\nACTION:DISPLAY\nDESCRIPTION:Reminder\nEND:VALARM\nEND:VEVENT\nEND:VCALENDAR`;

  let blob = new Blob([calendarString], { type: "text/calendar;charset=utf-8" });

  if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
    // Open/Save link in IE and Edge
    window.navigator.msSaveBlob(blob, `.ics`);
  } else {
    // Open/Save link in Modern Browsers
    window.open(encodeURI("data:text/calendar;charset=utf8," + calendarString));
  }
};
