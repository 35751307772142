import { createSlice } from "@reduxjs/toolkit";

const soundSlice = createSlice({
  name: "sound",
  initialState: {
    bgMusic: "",
    bgVolume: 0.25,
    volume: {
      background: 0.25,
      effects: 0.4,
    },
    track: "",
    mute: false,
    formats: ["webm", "mp3"],
    music: ["general-1", "general-2", "ruthlessWinner", "ruthlessIntro", "gameover", "best-time", "soft-beat", "happy-times", "whip"],
    effects: {},
    effect: "",
    effectPlaying: false,
    playbackRate: 1,
    loaded: 0,
    controlsOpen: false,
  },
  reducers: {
    setBgMusic(state, action) {
      if (action.payload) {
        const { track, volume } = action.payload;
        if (state.music.includes(track)) {
          state.bgMusic = track;
        } else {
          console.log(`${track} not found`);
        }
        if (volume !== undefined) {
          state.masterVolume = volume;
        }
      } else {
        state.bgMusic = undefined;
      }
    },
    addLoaded(state, action) {
      state.loaded += 1;
    },
    setBackgroundVolume(state, action) {
      state.volume.background = action.payload;
    },
    setEffectsVolume(state, action) {
      state.volume.effects = action.payload;
    },
    setEffect(state, action) {
      state.effect = action.payload;
      state.effectPlaying = true;
    },
    setPlaybackRate(state, action) {
      state.playbackRate = action.payload;
    },
    setEffectPlaying(state, action) {
      state.effectPlaying = action.payload;
    },
    setMute(state, action) {
      state.mute = action.payload;
    },
    setControlsOpen(state, action) {
      state.controlsOpen = action.payload;
    },
    setTrack(state, action) {
      state.track = action.payload;
    },
  },
});

export const { setBackgroundVolume, setEffectsVolume, setEffect, setEffectPlaying, setPlaybackRate, setBgMusic, addLoaded, setMute, setControlsOpen, setTrack } = soundSlice.actions;

export default soundSlice.reducer;
