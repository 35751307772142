import { useDispatch, useSelector } from "react-redux";
import { letsBegin } from "../reducer";
import { CTA } from "@ruthless/component-library";
import { selectTestFeatureOptions, setTestFeatureOptions } from "../../ui/uiSlice";

const IntroScreen = ({ active, skipNameScreen, onStartGame }) => {
  const dispatch = useDispatch();
  const { isCanvasRenderer, hideFrameLights, hideBgAnimation } = useSelector(selectTestFeatureOptions);

  if (!active) return null;

  return (
    <div className={`flex flex-col h-full w-full justify-center items-center`}>
      <CTA
        onClick={() => {
          if (skipNameScreen) {
            dispatch(letsBegin({ skipped: true }));
          } else {
            onStartGame();
          }
        }}
      >
        Start Game
      </CTA>
      <div className="flex text-white mt-2 justify-center items-center text-xs">
        <div>Enable canvas renderer (Test feature):</div>
        <input className="ml-2" type="checkbox" checked={isCanvasRenderer} onChange={(e) => dispatch(setTestFeatureOptions({ isCanvasRenderer: e.target.checked }))} />
      </div>
      <div className="flex text-white mt-2 justify-center items-center text-xs">
        <div>Hide frame light (Test feature):</div>
        <input className="ml-2" type="checkbox" checked={hideFrameLights} onChange={(e) => dispatch(setTestFeatureOptions({ hideFrameLights: e.target.checked }))} />
      </div>
      <div className="flex text-white mt-2 justify-center items-center text-xs">
        <div>Hide background animations (Test feature):</div>
        <input className="ml-2" type="checkbox" checked={hideBgAnimation} onChange={(e) => dispatch(setTestFeatureOptions({ hideBgAnimation: e.target.checked }))} />
      </div>
      <p className="text-center font-light opacity-70 text-white">{process.env.REACT_APP_DEPLOYMENT_RELEASE}</p>
    </div>
  );
};

export default IntroScreen;
