import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Game from "./Game";
import { useDispatch, useSelector } from "react-redux";
import { resetGameState, selectGameHasEnded, selectGameTechDifficulties, selectGameUuid, setGameTechDifficulties } from "../gameSlice";
import TechniacalDifficulties from "../../tech-difficulties/components/TechnicalDifficulties";
import Host from "../../host/components/Host";
import EndCap from "./EndCap";
import { setCurrentSection } from "../../ui/uiSlice";
import { SECTIONS } from "../../globals";
import { resetPrizeBallState } from "../../prizeball/reducer";
import { resetRuthlessState } from "../../ruthlessFinal/reducer";
import WinnerModal from "./WinnerModal";
import { useGetScheduleQuery, useGetTicketTypeForScheduleIdQuery, useGetTicketsForGameQuery } from "../../api/api";
import { AnimatePresence } from "framer-motion";
import useFirebase from "../../shared/hooks/useFirebase";
import { setChatRoomId } from "../../chat/chatSlice";
import { sendMessageFromFrameToParent } from "../../shared/utils/browserUtils";
import useHostConnection from "../../host/hooks/useHostConnection";

const GameScreen = ({ match }) => {
  const { onValueListener } = useFirebase();
  const dispatch = useDispatch();

  const operator = useSelector((s) => s.session.operator);

  const { schedule } = useGetScheduleQuery({ operator, scheduleIds: [match.params.scheduleId] }, { selectFromResult: ({ data }) => ({ schedule: data?.[0] }) });

  const { data: ticketType, isLoading } = useGetTicketTypeForScheduleIdQuery(schedule?.ticket_type_id, { skip: !schedule });
  const { data: tickets } = useGetTicketsForGameQuery(
    { roomId: match.params.roomId, scheduleId: match.params.scheduleId, scheduleTime: parseInt(match.params.gameStart, 10), scheduleLocale: schedule?.locale },
    { skip: !schedule }
  );

  const [winnerModalActive, setWinnerModalActive] = useState(false);

  const gameUuid = useSelector(selectGameUuid);
  const gameTechDifficulties = useSelector(selectGameTechDifficulties);
  const hasGameEnded = useSelector(selectGameHasEnded);
  const platformTechDifficulties = useSelector((state) => state?.techDifficulties.platform);
  const isHostLive = useSelector((state) => state.host.isLive);

  useHostConnection(match.params.roomId, gameUuid);

  useEffect(() => {
    dispatch(setCurrentSection(SECTIONS.CASH_QUALIFIER));
    dispatch(setChatRoomId(`${match.params.scheduleId}::${match.params.gameStart}`));

    sendMessageFromFrameToParent("joinedGame", { roomId: match.params.roomId, scheduleId: match.params.scheduleId, scheduleTime: match.params.gameStart });

    return () => {
      dispatch(resetGameState());
      dispatch(resetPrizeBallState());
      dispatch(resetRuthlessState());
      dispatch(setCurrentSection(null));
      dispatch(setChatRoomId(null));

      sendMessageFromFrameToParent("leftGame", null);
    };
  }, []);

  useEffect(() => {
    if (!gameUuid) return;

    const unsubscribe = onValueListener(`/technicalDifficulties/${gameUuid}`, (snapshot) => {
      dispatch(setGameTechDifficulties(snapshot.val() === null ? false : snapshot.val()));
    });

    return () => {
      unsubscribe();
    };
  }, [gameUuid]);

  if (isLoading) {
    return null;
  }

  if (platformTechDifficulties || gameTechDifficulties) {
    return <TechniacalDifficulties />;
  }

  // We check if we have at least one valid ticket, probably better to move gameUuid to the root of the request.
  if (!tickets?.tickets?.[0].gameUuid) {
    return null;
  }

  if (hasGameEnded) {
    return <EndCap />;
  }

  return (
    <>
      {gameUuid && isHostLive && <Host roomId={match.params.roomId} gameId={gameUuid} />}
      <Game winnerModalActive={winnerModalActive} setWinnerModalActive={setWinnerModalActive} currency={ticketType.currency} tickets={tickets} initialGameUuid={tickets.tickets?.[0].gameUuid} />
      <AnimatePresence>{winnerModalActive && <WinnerModal currency={ticketType.currency.code} />}</AnimatePresence>
    </>
  );
};

export default withRouter(GameScreen);
