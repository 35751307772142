import { useState, useEffect } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { Ball, Decider, CTA } from "@ruthless/component-library";
import useInterval from "@use-it/interval";
import { useDispatch, useSelector } from "react-redux";
import { selectGameUuid } from "../../game/gameSlice";
import { selectRuthlessContestantDecisionTime } from "../reducer";
import API from "../../shared/libs/API";
import useDelay from "../../shared/hooks/useDelay";

const ContestantsView = ({ tickets }) => {
  const [voted, setVoted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [voteValue, setVoteValue] = useState(null);
  const [defaultChoice, setDefaultChoice] = useState(null);

  const ruthlessContestantDecisionTime = useSelector(selectRuthlessContestantDecisionTime);

  const [count, setCount] = useState(ruthlessContestantDecisionTime / 1000);

  const gameUuid = useSelector(selectGameUuid);
  const userRef = useSelector((state) => state.session.ref);

  const delay = useDelay();

  useInterval(() => {
    setCount((prev) => (prev > 0 ? prev - 1 : 0));
  }, 1000);

  useEffect(() => {
    if (count === 0 && !voted && defaultChoice) {
      setVoteValue(defaultChoice);
      setVoted(true);
    }
  }, [count]);

  useEffect(() => {
    API.getDefaultChoice().then(({ data }) => {
      if (data.ruthlessChoice) {
        setDefaultChoice(data.ruthlessChoice);
      }
    });

    delay(1000).then(() => {
      setOpenModal(true);
    });
  }, []);

  const handleConfirm = async () => {
    try {
      await API.postRuthlessChoice(voteValue, gameUuid, tickets.tickets[0].uuid, userRef);

      setVoted(true);
    } catch (error) {
      console.error("Could not post ruthless choice", error);
    }
  };

  return (
    <div className="w-full">
      <div className="text-center text-white text-lg mt-24 font-bold">It’s time to decide!</div>

      <AnimatePresence>
        {openModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ background: "radial-gradient(76.67% 35.43% at 50% 39.1%, rgba(103, 158, 242, 0.9) 0%, rgba(7, 33, 73, 0.9) 100%)" }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[100]"
          >
            <div className="w-full h-full flex flex-col items-center">
              <div className="relative top-52 z-10">
                <Ball variant="timer" className="w-[170px]" number={count ?? 0} subtitle="seconds" color="red" total={30} />
                <div className="absolute -top-9 -bottom-9 -right-9 -left-9 border border-white/20 aspect-square rounded-full" />
                <div className="absolute -top-32 -bottom-32 -right-32 -left-32 border border-white/20 aspect-square rounded-full" />
                <div className="absolute -top-80 -bottom-80 -right-80 -left-80 border border-white/20 aspect-square rounded-full" />
                <div className="absolute -top-96 -bottom-96 -right-96 -left-96 border border-white/20 aspect-square rounded-full" />
              </div>
              <div className="z-10 absolute bottom-12">
                <div className="flex flex-col justify-center mb-4">
                  <AnimatePresence>
                    {voteValue === "steal" || voteValue === "share" ? (
                      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="text-center text-white font-bold">
                        <div className="text-lg">You’ve selected</div>
                        <motion.div key={voteValue} initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="capitalize text-6xl">
                          {voteValue}
                        </motion.div>
                      </motion.div>
                    ) : (
                      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="text-center text-white text-lgfont-bold">
                        Swipe left or right
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <div className="relative z-10">
                  <Decider onValueChange={(choice) => setVoteValue(choice)} value={voteValue} />
                  <CTA className="w-11/12 mx-auto mt-6" disabled={count === 0 || voted || !voteValue} onClick={handleConfirm}>
                    {voted ? "Waiting for others..." : "Confirm"}
                  </CTA>
                </div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: voteValue === "share" ? 1 : 0 }}
                  className="w-[50vw] fixed top-0 left-0 bottom-0"
                  style={{ background: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #0F3B1A 100%)" }}
                />
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: voteValue === "steal" ? 1 : 0 }}
                  className="w-[50vw] fixed top-0 right-0 bottom-0"
                  style={{ background: "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(90, 2, 3, 0.9) 100%)" }}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ContestantsView;
