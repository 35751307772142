import { useDispatch } from "react-redux";
import Countdown from "../../pregame/components/Countdown";
import { playEffect } from "../../sound/actions";
import { clickFx } from "../../sound/utils/constants";
import { useEffect } from "react";
import { setTrack } from "../../sound/reducer";
import { motion } from "framer-motion";
import { Swiper } from "@ruthless/component-library";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { useIntl } from "react-intl";
import { useGetTicketTypeForScheduleIdQuery } from "../../api/api";

// Step 5 - Ruthless Storyboard V3
const ClaimTicket = ({ countDown, setJoinable, onTick, cutoffSeconds, allowedToBuy, nextGameStart, numTicketsPurchased, setTicketClaimed }) => {
  const dispatch = useDispatch();
  const { formatNumber } = useIntl();

  const { data: ticketType, isLoading } = useGetTicketTypeForScheduleIdQuery(nextGameStart.schedule.ticket_type_id);

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.CLAIM_TICKET));
    dispatch(setTrack(""));
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <motion.div className="w-full flex flex-col items-center justify-start" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="flex flex-col items-center relative">
        <div className="flex flex-col items-center mt-4 mb-10 text-black">
          <h3 className="text-lg text-white font-bold">Next game starts in</h3>
          <Countdown
            classNames="text-[64px] font-pdm-secondary font-bold leading-[96px]"
            //countDownRef={countDown}
            nextGameStart={nextGameStart}
          />
        </div>
        <div>
          <div className="text-white text-center mb-4">Claim Your Ticket</div>
          <Swiper
            placeholder="Swipe to claim"
            title={formatNumber(parseFloat(ticketType.ticket?.price), { currency: ticketType.currency?.code, style: "currency" })}
            onChange={(value) => {
              dispatch(playEffect(clickFx));
              setTicketClaimed(value);
            }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default ClaimTicket;
