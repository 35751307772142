import { useState, useEffect, useRef, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { ref, limitToLast, query, onChildAdded, off } from "firebase/database";
import Message from "./Message";

import { useDispatch } from "react-redux";
import { toggleChat } from "../../chatSlice";
import { Input } from "@ruthless/component-library";
import { playEffect } from "../../../sound/actions";
import useFirebase from "../../../shared/hooks/useFirebase";
import { useSendChatMessageMutation } from "../../../api/api";

const Chat = ({ roomId }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const [sendChatMessage] = useSendChatMessageMutation();

  const messagesEnd = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(toggleChat(false));
    };
  }, []);

  const {
    firebase: { database, envName },
  } = useFirebase();

  // XXX: Get Firebase sync
  useEffect(() => {
    setMessages([]);

    if (!database) return;

    const fbRef = ref(database, `${envName}/Chat/${roomId}`);

    onChildAdded(query(fbRef, limitToLast(10)), (data) => {
      const details = data.val();

      setMessages((messages) => [
        ...messages.slice(messages.length - 30, messages.length),
        { alias: details.alias, message: details.text, emoji: details.emoji, id: uuidv4(), timestamp: details.timestamp },
      ]);
    });

    return () => {
      console.log("Chat subscriptions terminated");
      off(fbRef);
    };
  }, [database, roomId]);

  useEffect(() => {
    dispatch(playEffect("message-pop"));

    messagesEnd.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [messages]);

  const handleChatInputChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const sendMessage = () => {
    sendChatMessage({ message, roomId });

    setMessage("");
  };

  const handleSendChatMessage = (e) => {
    if (message === "" || e.key !== "Enter") return;

    e.preventDefault();

    sendMessage();
  };

  const handleChatIconClick = () => {
    if (message === "") return;

    sendMessage();
  };

  return (
    <>
      <div className="z-[51] mt-4">
        <div className="xs:text-base space-y-2 xl:space-y-4 short:space-y-4 overflow-y-auto overflow-x-hidden max-h-40 xl:max-h-[40vh]">
          {messages.map((data) => (
            <Message key={data.id} alias={data.alias} message={data.message} timestamp={data.timestamp} />
          ))}
          <div ref={messagesEnd} />
        </div>
      </div>
      <Input
        variant="chat"
        placeholder="Tap to type"
        value={message}
        onChange={handleChatInputChange}
        onKeyDown={handleSendChatMessage}
        onChatButtonClick={handleChatIconClick}
        className="text-xs short:text-base"
      />
    </>
  );
};

export default Chat;
