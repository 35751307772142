import { combineReducers } from "@reduxjs/toolkit";
import gameReducer from "./game/gameSlice";
import { reducer as player } from "./player";
import { reducer as config } from "./config";
import sessionReducer from "./session/sessionSlice";
import { reducer as sound } from "./sound";
import { reducer as prizeBall } from "./prizeball";
import { reducer as ruthless } from "./ruthlessFinal";
import uiReducer from "./ui/uiSlice";
import { reducer as techDifficulties } from "./tech-difficulties";
import api from "./api/api";
import liveGamesReducer from "./livegames/livegamesSlice";
import chatReducer from "./chat/chatSlice";
import hostReducer from "./host/hostSlice";

const rootReducer = combineReducers({
  config,
  game: gameReducer,
  session: sessionReducer,
  player,
  sound,
  chat: chatReducer,
  livegames: liveGamesReducer,
  host: hostReducer,
  prizeBall,
  ruthless,
  ui: uiReducer,
  techDifficulties,
  [api.reducerPath]: api.reducer,
});

export default rootReducer;
