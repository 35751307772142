import { useEffect, useRef, useState } from "react";
import ThisIsRuthlessVideo from "../../../public/video/thisRuthless.mp4";
import PrizeballIntro from "../../../public/video/prizeball-intro.mp4";
import PrizeballAvailable from "../../../public/video/prizeball-available.mp4";
import PrizeballPrize from "../../../public/video/prizeball-prize.mp4";
import PrizeballCongrats from "../../../public/video/prizeball-congrats.mp4";
import RuthlessIntroVid from "../../../public/video/screen35-36.mp4";
import RofIntro from "../../../public/video/rof-intro.mp4";
import GameOutcomeVid from "../../../public/video/screen37-38.mp4";
import Screen47Vid from "../../../public/video/screen-47.mp4";
import Screen4849Vid from "../../../public/video/screen-48-49.mp4";
import IntroRuthlessFinalVid from "../../../public/video/screen-39-40-41.mp4";
import CashQEnd from "../../../public/video/cashq-end.mp4";
import { useDispatch, useSelector } from "react-redux";
import { SCREENS } from "../../globals";
import { setCurrentVideo } from "../uiSlice";
import { isBrowser } from "../../shared/utils/browserUtils";
import useDelay from "../../shared/hooks/useDelay";
import VideoOverlay from "../../host/components/VideoOverlay";

export const VideoPlaceholder = ({ src, required = true, onVideoEnd, duration = null }) => {
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(isBrowser("Safari"));

  const hostIsLive = useSelector((s) => s.host.isLive);
  const audioOnly = useSelector((s) => s.host.audioOnly);
  const isChatOpen = useSelector((state) => state.chat.chatOpen);
  const isLiveHostVisible = useSelector((state) => state.ui.isLiveHostVisible);

  const currentScreen = useSelector((state) => state.ui.currentScreen);

  const delay = useDelay();

  useEffect(() => {
    if (hostIsLive && !required && duration !== null) {
      delay(duration).then(() => {
        onVideoEnd?.();
      });
    }

    window.addEventListener("click", handleUnmuteVideo, { once: true });
    window.addEventListener("touchstart", handleUnmuteVideo, { once: true });

    return () => {
      window.removeEventListener("click", handleUnmuteVideo);
      window.removeEventListener("touchstart", handleUnmuteVideo);
    };
  }, []);

  const handleUnmuteVideo = () => {
    const videoElement = videoRef.current;

    if (videoElement && videoElement.muted) {
      {
        videoRef.current.muted = false;
        setIsVideoMuted(false);
      }
    }
  };

  const handleVideoLoad = () => setIsVideoLoaded(true);

  if (!src || (hostIsLive && !required)) {
    return null;
  }

  const shouldHideCurrentVideo = [SCREENS.ROF_GAME_OUTCOMES, SCREENS.PRE_GAME_SPLASH].includes(currentScreen) || (hostIsLive && !audioOnly && isLiveHostVisible) || isChatOpen;
  const isOverlayVisible = isVideoLoaded && isVideoMuted;
  console.log("shouldHideCurrentVideo", shouldHideCurrentVideo, src, {
    hostIsLive,
    audioOnly,
    isLiveHostVisible,
    isChatOpen,
    first: [SCREENS.ROF_GAME_OUTCOMES, SCREENS.PRE_GAME_SPLASH].includes(currentScreen),
    second: hostIsLive && !audioOnly && isLiveHostVisible,
    third: isChatOpen,
    isMuted: isVideoMuted,
    isSafari: isBrowser("Safari"),
  });

  return (
    <div className={`fixed bottom-0 left-1/2 -translate-x-1/2 flex justify-center max-h-[250px] short:max-h-[300px] pointer-events-none`}>
      {/* <Video shouldHideVideo={shouldHideCurrentVideo} src={src} onVideoEnd={onVideoEnd} /> */}
      <div className={`opacity-80 flex justify-center ${shouldHideCurrentVideo ? "invisible" : "visible"}`}>
        <div className="relative h-auto max-h-full">
          <video
            ref={videoRef}
            controlsList={"nodownload nofullscreen noremoteplayback"}
            disablePictureInPicture={true}
            disableRemotePlayback={true}
            loop={false}
            playsInline={true}
            autoPlay={true}
            muted={isVideoMuted}
            className={"h-auto max-h-full"}
            onEnded={() => onVideoEnd?.()}
            onLoadedData={handleVideoLoad}
          >
            <source src={src} type={"video/mp4"} />
          </video>
          <VideoOverlay isVisible={isOverlayVisible} />
        </div>
      </div>
    </div>
  );
};

// TODO: remove this component once VideoPlaceholder is fully tested
export const useVideoManager = (screen, onVideoEnded, duration) => {
  const hostIsLive = useSelector((s) => s.host.isLive);
  const currentVideo = useSelector((state) => state.ui.currentVideo);
  const dispatch = useDispatch();
  const delay = useDelay();

  useEffect(() => {
    if (hostIsLive) {
      delay(duration).then(() => {
        onVideoEnded();
      });

      return;
    }

    if (currentVideo.name === screen && !currentVideo.isPlaying) {
      onVideoEnded();

      dispatch(setCurrentVideo({ isPlaying: false, name: null }));
    }
  }, [currentVideo.isPlaying, hostIsLive]);
};

// TODO: remove this component once VideoPlaceholder is fully tested
const VideoManager = () => {
  const currentScreen = useSelector((state) => state.ui.currentScreen);
  const hostIsLive = useSelector((s) => s.host.isLive);

  const dispatch = useDispatch();

  const getVideoForScreen = () => {
    switch (currentScreen) {
      case SCREENS.PRE_GAME_SPLASH:
        return { src: ThisIsRuthlessVideo, required: true }; // AboutToStart
      case SCREENS.PRIZE_BALL_INTRO:
        return { src: PrizeballIntro, required: false }; //Intro
      case SCREENS.PRIZES_NOT_LOADED:
        return { src: PrizeballAvailable, required: false }; // AvailablePrizes
      case SCREENS.WINNING_PRIZE:
        return { src: PrizeballPrize, required: false }; // WinningPrize
      case SCREENS.PRIZE_BALL_CONGRATS:
        return { src: PrizeballCongrats, required: false }; // PrizeBallCongrats
      case SCREENS.CASH_QUALIFIER_END:
        return { src: CashQEnd, required: false }; // IntroROF
      case SCREENS.ROF_GET_READY:
        return { src: RofIntro, required: false }; // GetReady
      case SCREENS.TIME_FOR_RUTHLESS:
        return { src: RuthlessIntroVid, required: false }; // TimeForRuthless
      case SCREENS.ROF_GAME_OUTCOMES:
        return { src: GameOutcomeVid, required: false }; // GameOutcomes
      case SCREENS.AUDIENCE_HOST:
        return { src: Screen47Vid, required: false }; // AudienceHost
      case SCREENS.BIG_REVEAL_INTRO:
        return { src: Screen4849Vid, required: false }; // TheBigRevealIntro
      case SCREENS.RUTHLESS_INTRO:
        return { src: IntroRuthlessFinalVid, required: false }; // IntroRuthlessFinal
      default:
        return { src: null, required: false };
    }
  };

  const handleVideoStart = () => {
    dispatch(setCurrentVideo({ isPlaying: true, name: currentScreen }));
  };

  const handleVideoEnd = () => {
    dispatch(setCurrentVideo({ isPlaying: false, name: currentScreen }));
  };
  //(isChatOpen && !isDesktopOrLarger && hostIsLive) || (isChatOpen && hostIsLive)
  const shouldHideCurrentVideo = () => [SCREENS.ROF_GAME_OUTCOMES, SCREENS.PRE_GAME_SPLASH].includes(currentScreen) || hostIsLive;
  const video = getVideoForScreen();

  if (!video.src || (hostIsLive && !video.required)) {
    return null;
  }
  console.log("vIDEO", video); // remove right-[300px]
  return (
    <div key={currentScreen} className={`relative opacity-80 right-[300px] ${shouldHideCurrentVideo() ? "invisible" : ""}`}>
      <video
        controlsList={"nodownload nofullscreen noremoteplayback"}
        disablePictureInPicture={true}
        disableRemotePlayback={true}
        loop={false}
        playsInline={true}
        autoPlay={true}
        muted={isBrowser("Safari")}
        className={"w-full h-auto max-h-full"}
        onEnded={handleVideoEnd}
        onPlay={handleVideoStart}
      >
        <source src={video.src} type={"video/mp4"} />
      </video>
    </div>
  );
};

export default VideoManager;
