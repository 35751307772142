import GetReady from "./GetReady";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setMatches } from "../reducer";

import { setCurrentSection } from "../../ui/uiSlice";
import { SECTIONS, SPLASH_SCREENS } from "../../globals";
import IntroROF from "../../game/components/IntroROF";
import { selectAmIContestant, selectRuthlessStep } from "../../ruthlessFinal/reducer";
import ROF from "./ROF";
import TimeForRuthless from "./TimeForRuthless";
import GameOutcomes from "./GameOutcomes";
import IntroRuthlessFinal from "../../ruthlessFinal/components/IntroRuthlessFinal";
import ContestantsView from "../../ruthlessFinal/components/ContestantsView";
import AudienceView from "../../ruthlessFinal/components/AudienceView";
import TheBigRevealIntro from "../../ruthlessFinal/components/CommonViews/TheBigRevealIntro";
import VotingResults from "../../ruthlessFinal/components/CommonViews/VotingResults";
import Reveal from "../../ruthlessFinal/components/CommonViews/Reveal";
import RuthlessRespin from "./RuthlessRespin";
import RuthlessClaimPrize from "./RuthlessClaimPrize";

import RuthlessEnding from "../../ruthlessFinal/components/CommonViews/RuthlessEnding";
import useSplashScreen from "../../shared/hooks/useSplashScreen";

const ROFScreen = ({ tickets }) => {
  const { showSplashScreen, isSplashScreenActive } = useSplashScreen();
  const ruthlessStep = useSelector(selectRuthlessStep);

  const amIContestant = useSelector((state) => selectAmIContestant(state, tickets.tickets));

  const dispatch = useDispatch();

  useEffect(() => {
    // Maybe not the best approach, but works ok for now. Later we can get rid of sections in favour of steps.
    const rofScreens = ["intro_rof_screen", "get_ready_screen", "rof_screen", "time_for_ruthless_screen", "outcomes_example_screen"];
    const ruthlessScreens = ["intro_ruthless_screen", "contestants_decision_screen", "big_reveal_intro", "voting_results_screen", "reveal_screen", "game_outcomes_screen"];

    showSplashScreenForStep();

    if (rofScreens.includes(ruthlessStep)) {
      dispatch(setCurrentSection(SECTIONS.ROF));
    }

    if (ruthlessScreens.includes(ruthlessStep)) {
      dispatch(setCurrentSection(SECTIONS.RUTHLESS_FINAL));
    }
  }, [ruthlessStep]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentSection(SECTIONS.CASH_QUALIFIER));
    };
  }, []);

  const showSplashScreenForStep = () => {
    switch (ruthlessStep) {
      case "intro_rof_screen":
        showSplashScreen(SPLASH_SCREENS.ROF);
        break;
      case "time_for_ruthless_screen":
        showSplashScreen(SPLASH_SCREENS.RUTHLESS_TIME_TO_PLAY);
        break;
      case "intro_ruthless_screen":
        showSplashScreen(SPLASH_SCREENS.RUTHLESS_FINAL);
        break;
      case "big_reveal_intro":
        showSplashScreen(SPLASH_SCREENS.RUTHLESS_REVEAL);
        break;
      default:
        break;
    }
  };

  if (isSplashScreenActive) {
    return null;
  }

  if (ruthlessStep === "intro_rof_screen") {
    console.log("Screen: IntroROF");
    return <IntroROF />;
  }

  if (ruthlessStep === "get_ready_screen") {
    console.log("Screen: GetReady");
    return <GetReady />;
  }

  // Screen 34
  if (ruthlessStep === "rof_screen") {
    console.log("Screen: ROFContainer -> ROF");
    return <ROF />;
  }

  if (ruthlessStep === "time_for_ruthless_screen") {
    console.log("Screen: ROFContainer -> TimeForRuthless");
    return <TimeForRuthless />;
  }

  if (ruthlessStep === "outcomes_example_screen") {
    console.log("Screen: ROFContainer -> GameOutcomes");
    return <GameOutcomes />;
  }

  if (ruthlessStep === "intro_ruthless_screen") {
    return (
      <div className="w-full h-full">
        <IntroRuthlessFinal />
      </div>
    );
  }

  if (ruthlessStep === "contestants_decision_screen" && amIContestant) {
    console.log("Screen: RuthlessFinal -> ContestantsView");
    return (
      <div className="w-full h-full">
        <ContestantsView tickets={tickets} />
      </div>
    );
  }

  if (ruthlessStep === "contestants_decision_screen" && !amIContestant) {
    console.log("Screen: RuthlessFinal -> AudienceView");
    return (
      <div className="w-full h-full">
        <AudienceView />
      </div>
    );
  }

  if (ruthlessStep === "big_reveal_intro") {
    console.log("Screen: RuthlessFinal -> CommonView -> TheBigRevealIntro");
    return (
      <div className="w-full h-full flex items-center justify-center">
        <TheBigRevealIntro />
      </div>
    );
  }

  if (ruthlessStep === "voting_results_screen") {
    console.log("Screen: RuthlessFinal -> CommonView -> VotingResults");
    return <VotingResults />;
  }

  if (ruthlessStep === "reveal_screen") {
    console.log("Screen: RuthlessFinal -> CommonView -> Reveal");
    return <Reveal />;
  }

  if (ruthlessStep === "rof_respin_screen" || (ruthlessStep === "ruthless_claim_prize_screen" && !amIContestant)) {
    return <RuthlessRespin />;
  }

  if (ruthlessStep === "ruthless_claim_prize_screen" && amIContestant) {
    return <RuthlessClaimPrize tickets={tickets.tickets} />;
  }

  if (ruthlessStep === "ruthless_end_screen") {
    console.log("Screen: RuthlessFinal -> CommonView -> GameOutcomesMain");
    return <RuthlessEnding />;
  }

  return null;
};

export default ROFScreen;
