import { Logo } from "@ruthless/component-library";
import LogoScreen from "../../shared/components/LogoScreen";

const TechniacalDifficulties = ({ platform }) => {
  return (
    <>
      {platform ? (
        <LogoScreen techDifficulties={true} />
      ) : (
        <div>
          <div style={{ width: "max-content", margin: "0 auto" }}>
            <Logo className="z-[1]" variant="large" showLights showGradient />;
          </div>
          <h1 className="text-yellow-400 font-bold text-shadow-xs text-center text-5xl">We Are Currently Experiencing Technical Difficulties</h1>;
        </div>
      )}
    </>
  );
};

export default TechniacalDifficulties;
