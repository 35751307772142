import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ConfigureRoutes from "./src/configureRoutes";
import LiveGames from "./src/livegames/components/LiveGames/LiveGames";
import FooterNav from "./src/navigation/components/FooterNav";
import Navigation from "./src/navigation/components/Navigation";
import { PlayerNameModal } from "./src/player/containers/index";
import { StageLights, Frame, ProgressBar, IconContainer, ChatIcon } from "@ruthless/component-library";
import { AnimatePresence, motion } from "framer-motion";
import { SCREENS, SECTIONS } from "./src/globals";
import { useMediaQuery } from "react-responsive";
import SplashScreen from "./src/ui/components/SplashScreen";
import TechniacalDifficulties from "./src/tech-difficulties/components/TechnicalDifficulties";
import useSplashScreen from "./src/shared/hooks/useSplashScreen";
import { selectTestFeatureOptions } from "./src/ui/uiSlice";
import Chat from "./src/chat/components/Chat/Chat";
import { toggleChat } from "./src/chat/chatSlice";
import Popup from "./src/shared/components/Popup";
import { getSearchParams, sendMessageFromFrameToParent } from "./src/shared/utils/browserUtils";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "./tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

const AppFlow = ({ location, history }) => {
  const isTallScreen = useMediaQuery({ query: fullConfig.theme.screens.short.raw });

  const isLaptoptOrSmaller = useMediaQuery({ query: "(max-width: 1024px)" });
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const isDesktopOrLarger = useMediaQuery({ query: "(min-width: 1280px)" });

  const searchParams = getSearchParams(location.search);

  const [isActiveGameModalOpen, setIsActiveGameModalOpen] = useState(
    (searchParams.has("roomId") && searchParams.has("scheduleId") && searchParams.has("scheduleTime")) || !!window.sessionStorage.getItem("gameData")
  );
  const [welcomed, setWelcomed] = useState(false);

  const { currentSplashScreen, isSplashScreenActive } = useSplashScreen();

  const currentScreen = useSelector((state) => state.ui.currentScreen);
  const currentSection = useSelector((state) => state.ui.currentSection);
  const currentBackground = useSelector((state) => state.ui.currentBackground);
  const currentStageLightColor = useSelector((state) => state.ui.currentStageLightColor);
  const isChatOpen = useSelector((state) => state.chat.chatOpen);
  const chatRoomId = useSelector((state) => state.chat.chatRoomId);

  const { isCanvasRenderer, hideBgAnimation, hideFrameLights } = useSelector(selectTestFeatureOptions);

  const dispatch = useDispatch();
  const platformTechDifficulties = useSelector((state) => state?.techDifficulties.platform);

  const reconnectToTheRunningGame = () => {
    if (location.search) {
      const roomId = searchParams.get("roomId");
      const scheduleId = searchParams.get("scheduleId");
      const scheduleTime = searchParams.get("scheduleTime");

      if (roomId && scheduleId && scheduleTime) {
        const hasGameStarted = parseInt(scheduleTime, 10) - Date.now() < 0;

        if (hasGameStarted) {
          history.replace(`/game/${roomId}/${scheduleId}/${scheduleTime}`);
        } else {
          history.replace(`/game_room/${roomId}?scheduleId=${scheduleId}&date=${scheduleTime}`);
        }
      }
    }
  };

  const isMatchingCurrentScreen = (screens = [], sections = []) => {
    return screens.includes(currentScreen) || sections.includes(currentSection);
  };

  const getFrameLightPosition = () => {
    switch (currentScreen) {
      case SCREENS.CLAIM_TICKET:
      case SCREENS.ABOUT_TO_START:
      case SCREENS.PRE_GAME_ROOM:
        return "top";
      default:
        break;
    }

    switch (currentSection) {
      case SECTIONS.PRIZE_BALL:
      case SECTIONS.CASH_QUALIFIER:
      case SECTIONS.ROF:
      case SECTIONS.RUTHLESS_FINAL:
      case SECTIONS.GAME_END:
        return "top";
      default:
        return "center";
    }
  };

  const getBackgroundForScreen = () => {
    const BACKGROUNDS = {
      RED: !isLaptoptOrSmaller
        ? "radial-gradient(144.74% 45.46% at 50% 0%, #E80E14 0%, #530507 15.62%, #0E4AA6 52.08%, #0B3577 100%"
        : "radial-gradient(170.51% 53.55% at 50% 0%, #E80E14 0%, #530507 27.08%, #0E4AA6 52.08%, #0B3577 100%",
      GOLD: !isLaptoptOrSmaller
        ? "radial-gradient(143.96% 45.21% at 50% 0%, #E4A400 0%, #684A00 17.19%, #0E4AA6 52.08%, #0B3577 100%)"
        : "radial-gradient(170.51% 53.55% at 50% 0%, #E4A400 0%, #684A00 27.08%, #0E4AA6 52.08%, #0B3577 100%)",
      GREEN: !isLaptoptOrSmaller
        ? "radial-gradient(144.74% 45.46% at 50% 0%, #ADF438 0%, #0F3B1A 15.62%, #0E4AA6 52.08%, #0B3577 100%)"
        : "radial-gradient(170.51% 53.55% at 50% 0%, #ADF438 0%, #0F3B1A 52.65%, #0A2C60 81.64%, #0B3577 100%)",
      // Currently only used for ruthless ending screen.
      "RUTHLESS-WINNER": !isLaptoptOrSmaller ? "linear-gradient(to bottom, #db9d00, #2a1e00)" : "linear-gradient(to bottom, #db9d00, #2a1e00)",
    };

    if (currentBackground) {
      return BACKGROUNDS[(currentBackground ?? "").toUpperCase()] ?? "";
    }

    switch (currentScreen) {
      case SCREENS.RUTHLESS_WINNERS:
        return BACKGROUNDS["RUTHLESS-WINNER"];
      default:
        break;
    }

    switch (currentSection) {
      case SECTIONS.PRIZE_BALL:
      case SECTIONS.GAME_END:
        return BACKGROUNDS.GOLD;
      case SECTIONS.ROF:
      case SECTIONS.RUTHLESS_FINAL:
        return BACKGROUNDS.RED;
      default:
        return "radial-gradient(170.51% 53.55% at 50% 0%, #3b7afb 0%, #0e4aa6 38.94%, #0b3577 100%)";
    }
  };

  const getStageLightColor = () => {
    if (currentStageLightColor) {
      return currentStageLightColor;
    }

    switch (currentScreen) {
      default:
        break;
    }

    switch (currentSection) {
      case SECTIONS.PRIZE_BALL:
      case SECTIONS.GAME_END:
        return "gold";
      case SECTIONS.ROF:
      case SECTIONS.RUTHLESS_FINAL:
        return "red";
      default:
        return "blue";
    }
  };

  const getBottomMarginStyles = (bottomNav, progressBar) => {
    if (bottomNav && progressBar) {
      return isTallScreen ? "pb-[120px]" : "pb-[80px]";
    }

    if (bottomNav) {
      return isTallScreen ? "pb-[75px]" : "pb-[58px]";
    }

    if (progressBar) {
      return isTallScreen ? "pb-[43px]" : "pb-[25px]";
    }

    return "";
  };

  const getCurrentProgress = () => {
    const index = [SECTIONS.CASH_QUALIFIER, SECTIONS.PRIZE_BALL, SECTIONS.ROF, SECTIONS.RUTHLESS_FINAL, SECTIONS.GAME_END].findIndex((name) => name === currentSection);

    return index === -1 ? 1 : index + 1;
  };

  const showProgressBar = isMatchingCurrentScreen([], [SECTIONS.PRIZE_BALL, SECTIONS.ROF, SECTIONS.CASH_QUALIFIER, SECTIONS.RUTHLESS_FINAL, SECTIONS.GAME_END]);

  return (
    <>
      {platformTechDifficulties ? (
        <TechniacalDifficulties platform={true} />
      ) : (
        <>
          {welcomed && (
            <motion.div
              data-tag="tutorialed"
              className="w-full h-full overflow-auto overflow-x-hidden flex flex-col"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{ background: getBackgroundForScreen() }}
            >
              <LiveGames />
              <StageLights
                color={getStageLightColor()}
                showLights={isMatchingCurrentScreen(
                  [SCREENS.CHOOSE_PRIZE, SCREENS.PRE_GAME_ROOM],
                  [SECTIONS.PRIZE_BALL, SECTIONS.ROF, SECTIONS.CASH_QUALIFIER, SECTIONS.RUTHLESS_FINAL, SECTIONS.GAME_END]
                )}
                showBackgroundVisuals={isMatchingCurrentScreen([SCREENS.PRE_GAME_ROOM], [SECTIONS.PRIZE_BALL, SECTIONS.ROF, SECTIONS.CASH_QUALIFIER, SECTIONS.RUTHLESS_FINAL, SECTIONS.GAME_END])}
                renderer={isCanvasRenderer ? "canvas" : "svg"} // If does not improve performance, continue with background-* branch.
                className="container"
                isDisabled={hideBgAnimation}
              >
                <Navigation />

                <Frame
                  position={getFrameLightPosition()}
                  showFrame={!isMatchingCurrentScreen([SCREENS.ROOM_LIST, SCREENS.TICKETS, SCREENS.PROFILE])}
                  showLights={
                    !hideFrameLights &&
                    isMatchingCurrentScreen(
                      [SCREENS.CLAIM_TICKET, SCREENS.ABOUT_TO_START, SCREENS.PRE_GAME_ROOM, SCREENS.GAME_SPONSORS],
                      [SECTIONS.PRIZE_BALL, SECTIONS.ROF, SECTIONS.CASH_QUALIFIER, SECTIONS.RUTHLESS_FINAL]
                    )
                  }
                  className={`flex flex-col flex-1 px-3 md:px-0 mb-2.5 ${getBottomMarginStyles(!isTabletOrLarger, showProgressBar)}`}
                >
                  <ConfigureRoutes />

                  {(isChatOpen || isDesktopOrLarger) &&
                    chatRoomId &&
                    !isSplashScreenActive &&
                    isMatchingCurrentScreen([SCREENS.PRE_GAME_ROOM, SCREENS.CASH_QUALIFIER_END, SCREENS.ROF_GET_READY, SCREENS.TIME_FOR_RUTHLESS], [SECTIONS.PRIZE_BALL, SECTIONS.CASH_QUALIFIER]) && (
                      <motion.div className="flex flex-col mx-auto w-full md:w-11/12 xl:fixed xl:right-0 xl:bottom-0 chat-desktop-bg xl:p-6 xl:w-[350px] mt-auto">
                        <Chat roomId={chatRoomId} />
                      </motion.div>
                    )}
                </Frame>

                <div className="fixed bottom-0 left-0 right-0 md:max-w-2xl lg:max-w-xl md:mx-auto z-[52]">
                  {showProgressBar && (
                    <div className="mb-1.5 short:mb-3 px-3 xs:px-6 md:px-0">
                      <ProgressBar total={5} step={getCurrentProgress()} color={currentScreen === SCREENS.RUTHLESS_WINNERS ? "gold" : "blue"} />
                    </div>
                  )}
                  {!isTabletOrLarger && <FooterNav showChatRoute={chatRoomId !== null} />}
                </div>
              </StageLights>

              <AnimatePresence>{isSplashScreenActive && <SplashScreen currentSplashScreen={currentSplashScreen} />}</AnimatePresence>
              {chatRoomId && (
                <div className="fixed right-5 bottom-5 hidden md:block xl:hidden">
                  <IconContainer items={[{ icon: ChatIcon, onClick: () => dispatch(toggleChat()), isSelected: isChatOpen }]} />
                </div>
              )}
            </motion.div>
          )}

          {!welcomed && (
            <PlayerNameModal
              gotoGreeter={() => {
                setWelcomed(true);
              }}
            />
          )}

          <AnimatePresence>
            {isActiveGameModalOpen && (
              <Popup
                title="Game In Progress ⏳"
                body="You've left during your current game. Would you like to continue where you left off?"
                confirmButtonText="Rejoin"
                onConfirmCallback={() => {
                  reconnectToTheRunningGame();
                  setWelcomed(true);
                  setIsActiveGameModalOpen(false);
                }}
                onCancelCallback={() => {
                  setIsActiveGameModalOpen(false);
                  sendMessageFromFrameToParent("leftGame", null);
                }}
              />
            )}
          </AnimatePresence>
        </>
      )}
    </>
  );
};

export default withRouter(AppFlow);
