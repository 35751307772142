import { createSlice } from "@reduxjs/toolkit";

const liveGames = createSlice({
  name: "livegames",
  initialState: {
    listOpen: false,
    liveTickets: [],
  },
  reducers: {
    setListOpen(state, action) {
      state.listOpen = action.payload;
    },
    setLiveTickets(state, action) {
      state.liveTickets = action.payload;
    },
  },
});

// Selectors
export const selectLiveTickets = (state) => state.livegames.liveTickets;

export const { setListOpen, setLiveTickets } = liveGames.actions;

export default liveGames.reducer;
