import { useEffect, useState } from "react";
import Countdown from "../../pregame/components/Countdown";
import ThisIsRuthlessVideo from "../../../public/video/thisRuthless.mp4";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS, SPLASH_SCREENS } from "../../globals";
import useSplashScreen from "../../shared/hooks/useSplashScreen";
import { VideoPlaceholder } from "../../ui/components/VideoManager";
import { withRouter } from "react-router-dom";
import useDelay from "../../shared/hooks/useDelay";

// Step 9, 10, 11 - Ruthless Storyboard V3
const AboutToStart = ({ roomId, allowedToBuy, countDown, onTick, setJoinable, cutoffSeconds, nextGameStart, numTicketsPurchased, history }) => {
  const dispatch = useDispatch();
  const delay = useDelay();
  const { showSplashScreen } = useSplashScreen();

  const [isRuthless, setIsRuthless] = useState(false);

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.ABOUT_TO_START));
    setIsRuthless(true);

    delay(4000).then(() => {
      showSplashScreen(
        SPLASH_SCREENS.PRE_GAME,
        () => {
          // joinGame(roomId, nextGameStart.id, nextGameStart.date.getTime()); // join route /game_room/:inRoomId

          if (nextGameStart) {
            history.push({
              pathname: `/game_room/${roomId}?scheduleId=${nextGameStart.id}&date=${nextGameStart.date.getTime()}`,
              state: {},
            });
          }
        },
        6000
      );
    });
  }, []);

  if (!isRuthless) {
    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className="text-center mt-12">
          <div className="text-white font-bold">Show starts in</div>
          <Countdown
            classNames="text-[64px] font-bold font-pdm-secondary leading-[96px]"
            //countDownRef={countDown}
            nextGameStart={nextGameStart}
          />
        </div>
      </motion.div>
    );
  }

  return <VideoPlaceholder src={ThisIsRuthlessVideo} />;
};

export default withRouter(AboutToStart);
