import { animate, useMotionValue } from "framer-motion";
import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import Currency from "../../ui/components/Currency";

const Balance = ({ currentBalance, currency }) => {
  const motionBalance = useMotionValue(0);
  const [displayedBalance, setDisplayedBalance] = useState(0);

  useEffect(() => {
    if (currentBalance !== undefined && !isNaN(currentBalance)) {
      animate(motionBalance, new BigNumber(currentBalance).toNumber(), {
        type: "tween",
        duration: 0.4,
      });
    }
  }, [currentBalance]);

  useEffect(() => {
    motionBalance.onChange(() => setDisplayedBalance(motionBalance.get()));

    return () => {
      motionBalance.clearListeners();
    };
  }, []);

  if (currency === undefined) return null;

  return <Currency value={displayedBalance} currency={currency} style="currency" />;
};

export default Balance;
