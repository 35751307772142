import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useHistory } from "react-router-dom";
import { playEffect } from "../../sound/actions";
import { ChatIcon, HomeIcon, MobileNav, UserIcon } from "@ruthless/component-library";
import { toggleChat } from "../../chat/chatSlice";

const Navigation = ({ showChatRoute }) => {
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState("/");
  const dispatch = useDispatch();

  const isChatOpen = useSelector((state) => state.chat.chatOpen);
  const gameTechDifficulties = useSelector((state) => state?.game.gameTechDifficulties);

  useEffect(() => {
    const splitLoc = location.pathname.split("/");
    if (location.pathname.startsWith("/operator")) {
      setPage("/");
    } else {
      setPage(splitLoc[1] || "/");
    }
  }, [location]);

  return (
    <MobileNav
      routes={[
        {
          id: "home",
          onClick: () => {
            dispatch(playEffect("sbb-fx-tap-fuzzy"));
            history.push("/");
          },
          isSelected: location.pathname === "/",
          icon: HomeIcon,
        },
        showChatRoute
          ? {
              id: "chat",
              showNotification: isChatOpen,
              onClick: () => {
                dispatch(playEffect("sbb-fx-tap-fuzzy"));
                dispatch(toggleChat(gameTechDifficulties ? false : !isChatOpen));
              },
              isSelected: isChatOpen,
              icon: ChatIcon,
            }
          : null,
        {
          id: "profile",
          onClick: () => {
            dispatch(playEffect("sbb-fx-tap-fuzzy"));
            history.push("/profile");
          },
          isSelected: location.pathname === "/profile" || location.pathname === "/tickets",
          icon: UserIcon,
        },
      ].filter(Boolean)}
    />
  );
};

export default Navigation;
