import { motion } from "framer-motion";
import { CTA, Logo } from "@ruthless/component-library";
import { withRouter } from "react-router-dom";

const EndCap = ({ history }) => {
  return (
    <div className="fixed inset-0 bg-cobalt-blue-900 z-[60] appBg md:bg-contain flex flex-col justify-between items-center">
      <Logo className="top-32 short:top-52 md:top-44" variant="large" showLights showGradient />
      <motion.div initial={{ opacity: 0, x: 150 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.5 }}>
        <CTA className="bottom-28 md:bottom-10" variant="secondary" onClick={() => history.push("/")}>
          Go To Lobby
        </CTA>
      </motion.div>
    </div>
  );
};

export default withRouter(EndCap);
