export const isBrowser = (browser) => {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf("Chrome") > -1;

  // Detect Internet Explorer
  let IExplorerAgent = userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1;

  // Detect Firefox
  let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

  // Detect Safari
  let safariAgent = userAgentString.indexOf("Safari") > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  let operaAgent = userAgentString.indexOf("OP") > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;

  switch (browser) {
    case "Chrome":
      return chromeAgent;
    case "IE":
      return IExplorerAgent;
    case "Firefox":
      return firefoxAgent;
    case "Safari":
      return safariAgent;
    case "Opera":
      return operaAgent;
    default:
      return false;
  }
};

/**
 * Used to persist current game in the parent's url.
 */
export const sendMessageFromFrameToParent = (type, gameData) => {
  if (type === "joinedGame") {
    window.sessionStorage.setItem("gameData", new URLSearchParams(gameData).toString());
  } else {
    window.sessionStorage.removeItem("gameData");
  }

  window.parent.postMessage({ type, gameData }, process.env.NODE_ENV === "development" ? "*" : "https://staging.enlivon.com");
};

/**
 * Used to get search params required for reconnecting. Fallbacks to sessionStorage if unavailable
 */
export const getSearchParams = (params) => {
  const item = new URLSearchParams(params);

  if (item.has("roomId") && item.has("scheduleId") && item.has("scheduleTime")) {
    console.info("Using search params for reconnecting.");
    return item;
  }

  console.info("Using sessionStorage for reconnecting.");
  return new URLSearchParams(window.sessionStorage.getItem("gameData"));
};
