import { Reels } from "@ruthless/component-library";
import { useDispatch, useSelector, useStore } from "react-redux";
import useInterval from "@use-it/interval";
import { useAnimation, motion } from "framer-motion";
import { useEffect, useRef, useState, useCallback } from "react";

import { useMediaQuery } from "react-responsive";
import confettiCelebration from "../../../public/lotte/18353-confetti-celebration.json";
import { selectCurrentWinnersTickets, selectSpinNumber } from "../reducer";
import { selectGameUuid, selectPlayerAliases } from "../../game/gameSlice";
import useDelay from "../../shared/hooks/useDelay";

const PrizeBallReels = () => {
  const isMobile = useMediaQuery({ query: "(max-device-width: 640px)" });

  const reelsRef = useRef();
  const delay = useDelay();

  const playerAliases = useSelector(selectPlayerAliases);
  const currentWinnersTickets = useSelector(selectCurrentWinnersTickets);
  const spinNumber = useSelector(selectSpinNumber);

  useEffect(() => {
    delay(2500).then(() => {
      reelsRef.current.spin?.();
    });
  }, [spinNumber]);

  const numberOfNewWinners = currentWinnersTickets.filter((ticket) => !ticket.isClaimed).length;
  const winners = currentWinnersTickets.map((player) => player.playerAlias);

  return (
    <div>
      <div className="text-white text-lg font-bold text-center mt-2 mb-6">And the winners are</div>
      <motion.div initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 2 }}>
        <Reels
          variant={isMobile ? "small" : "large"}
          ref={reelsRef}
          lockedReels={[1, 2, 3].slice(numberOfNewWinners)}
          winners={winners}
          items={Array.from(new Set([...winners, ...playerAliases]))}
          animationData={confettiCelebration}
        />
      </motion.div>
    </div>
  );
};

export default PrizeBallReels;
