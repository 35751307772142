import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onAudienceVotesForWinners } from "../../actions";
import VoteOnAudience from "./VoteOnAudience";
import VoteOnContestants from "./VoteOnContestants";
import { setCurrentScreen } from "../../../ui/uiSlice";
import { SCREENS } from "../../../globals";
import { selectGameUuid } from "../../../game/gameSlice";
import API from "../../../shared/libs/API";
import useDelay from "../../../shared/hooks/useDelay";

const AudienceVotes = ({ setWhatWouldYouDo }) => {
  // const { gameId } = useSelector((state) => state.game.data);
  const gameUuid = useSelector(selectGameUuid);

  const [totalVotes, setTotalVotes] = useState([]);
  const [voted, setVoted] = useState(false);

  const delay = useDelay();
  const dispatch = useDispatch();

  const handleAudienceVote = async () => {
    // return dispatch(onAudienceVotesForWinners(totalVotes, gameUuid));

    try {
      await API.postAudienceVoteForWinners(totalVotes, gameUuid);

      await delay(3000);
    } catch (error) {
      console.error("Could not post audience vote for winners", error);
    }
  };

  // const getOnAudienceVote = async () => {
  //   onAudienceVote();
  // };

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.AUDIENCE_VOTE));
  }, []);

  useEffect(() => {
    if (totalVotes.length === 3) {
      handleAudienceVote().then(() => setVoted(true));
    }
  }, [totalVotes]);

  if (!voted) {
    console.log("Screen: RuthlessFinal -> AudienceView -> AudienceVotes -> !voted");

    // AnimatePresence most likely does not work. In future consider removing.
    return (
      <div className="w-full h-full">
        <AnimatePresence exitBeforeEnter>
          <VoteOnContestants setTotalVotes={setTotalVotes} totalVotes={totalVotes} />
        </AnimatePresence>
      </div>
    );
  }

  if (voted) {
    console.log("Screen: RuthlessFinal -> AudienceView -> AudienceVotes -> voted");
    return (
      <div className="w-full h-full flex flex-col items-center justify-evenly">
        <AnimatePresence exitBeforeEnter>
          <VoteOnAudience setWhatWouldYouDo={setWhatWouldYouDo} />
        </AnimatePresence>
      </div>
    );
  }
};

export default AudienceVotes;
