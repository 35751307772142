import { createSlice } from "@reduxjs/toolkit";

const uiReducer = createSlice({
  name: "ui",
  initialState: {
    currentScreen: null,
    currentSection: null,
    currentBackground: null,
    currentStageLightColor: null,
    currentVideo: {
      name: null,
      isPlaying: false,
    },
    testFeatureOptions: {
      isCanvasRenderer: false,
      hideFrameLights: false,
      hideBgAnimation: false,
    },
  },
  reducers: {
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload;
    },
    setCurrentBackground: (state, action) => {
      state.currentBackground = action.payload;
    },
    setCurrentStageLightColor: (state, action) => {
      state.currentStageLightColor = action.payload;
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setCurrentVideo: (state, action) => {
      const { name, isPlaying } = action.payload;

      state.currentVideo.name = name ?? null;
      state.currentVideo.isPlaying = isPlaying ?? false;
    },
    // can be removed after testing
    setTestFeatureOptions: (state, action) => {
      const previousOptions = state.testFeatureOptions;

      state.testFeatureOptions = {
        ...previousOptions,
        ...action.payload,
      };
    },
  },
});

// Selectors
export const selectCurrentSplashScreen = (state) => state.ui.currentSplashScreen;
export const selectTestFeatureOptions = (state) => state.ui.testFeatureOptions;

export const { setCurrentScreen, setCurrentBackground, setCurrentStageLightColor, setCurrentSection, setCurrentSplashScreen, setCurrentVideo, setTestFeatureOptions } = uiReducer.actions;

export default uiReducer.reducer;
