import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Hostplaceholder from "../../../ui/components/Hostplaceholder";
import Screen47Vid from "../../../../public/video/screen-47.mp4";
import { selectContestantsChoices } from "../../reducer";
import { Container, LockIcon, UserIcon } from "@ruthless/component-library";
import { setCurrentScreen } from "../../../ui/uiSlice";
import { SCREENS } from "../../../globals";
import { VideoPlaceholder } from "../../../ui/components/VideoManager";

const AudienceHost = () => {
  const contestantsChoices = useSelector(selectContestantsChoices);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.AUDIENCE_HOST));

    console.log("audience only host - screen 47");

    return () => {
      dispatch(setCurrentScreen(null));
    };
  }, []);

  return (
    <>
      <div className="space-y-2 mt-10 md:max-w-xl">
        {contestantsChoices.map((result) => (
          <Container key={result.uuid} className="flex p-4 space-x-3 rounded-2xl text-white font-bold" color={result.choiceMade ? "gold" : ""}>
            {result.choiceMade ? <LockIcon className="w-6 h-6 stroke-white" /> : <UserIcon className="w-6 h-6 fill-[#A8C8F7]" />}
            <div className="flex items-center">{result.playerAlias}</div>
          </Container>
        ))}
      </div>
      {/* <Hostplaceholder clip={Screen47Vid} required={false} /> */}
      <VideoPlaceholder
        src={Screen47Vid}
        required={false}
        // onVideoEnd={() => {
        //   console.log("VideoPlaceholder: AudienceHost");
        //   //dispatch(setAudienceFinished(true));
        // }}
        // duration={34600}
      />
    </>
  );
};

export default AudienceHost;
