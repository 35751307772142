import { useEffect, useRef } from "react";

import Countdown from "./Countdown";
import ResponsivePicture from "../../ui/components/ResponsiveImage";

import { useDispatch } from "react-redux";

import { playEffect } from "../../sound/actions";
import { clickFx } from "../../sound/utils/constants";
import { withRouter } from "react-router-dom";

import { CTA, Container, TimerIcon } from "@ruthless/component-library";
import useNextGame from "../hooks/useNextGame";

// TODO get cutoff from somewhere
const cutoffSeconds = 10;

const Card = ({ roomId, onBuyTicket, onRequestRoom, image, name, operator, index, isSelected, history }) => {
  const [nextGameStart, numTickets] = useNextGame(roomId, operator);

  //const [allowedToBuy, setAllowedToBuy] = useState(false);
  const countDown = useRef(null);

  const dispatch = useDispatch();

  // Start timer is nextGameStart changes and not in cutoff period
  useEffect(() => {
    if (nextGameStart && countDown && countDown.current) {
      countDown.current.start();
      // if (countDown.current.calcTimeDelta().total / 1000 > cutoffSeconds) {
      //   setAllowedToBuy(true);
      // }
    }
  }, [nextGameStart, countDown]);

  const handleJoinGame = (scheduleId, date) => {
    const now = new Date().getTime();
    if (date - now < 15000) {
      history.push({
        pathname: `/game/${roomId}/${scheduleId}/${date}`,
        state: {},
      });
    } else {
      history.push({
        pathname: `/game_room/${roomId}?scheduleId=${scheduleId}&date=${date}`,
        state: {},
      });
    }
  };

  const handleButtonClick = () => {
    dispatch(playEffect(clickFx));

    if (numTickets > 0) {
      handleJoinGame(nextGameStart.id, nextGameStart.date.getTime());
    } else {
      onRequestRoom(roomId);
    }
  };

  if (!nextGameStart) return null;

  return (
    <div className="relative max-w-md short:max-w-none mx-auto short:mx-0">
      <div className={isSelected ? "absolute w-[105%] h-[102%] left-1/2 -translate-x-1/2 top-3 bg-[#0A2C60] rounded-[32px] border-dashed border border-white/30" : ""} />

      <Container className="p-3 rounded-2xl">
        <div>
          <p className="text-center text-white font-bold mb-2">{name} Room</p>
        </div>
        <div>
          <ResponsivePicture className="w-full pointer-events-none h-[180px] short:h-[260px]" imgPath={`${image}`} alt="Bingo90" />
        </div>
        <div className="flex justify-between mt-4">
          <div className="flex justify-center items-center">
            <TimerIcon className="stroke-[#F54C52] mr-2" />
            <Countdown classNames="px-0 text-base font-pdm-secondary font-bold" countDownRef={countDown} nextGameStart={nextGameStart} />
          </div>
          <CTA onClick={handleButtonClick}>{numTickets > 0 ? "JOIN GAME" : "TICKETS"}</CTA>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(Card);
