import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setWelcomeFinished } from "../reducer";

import Hostplaceholder from "../../ui/components/Hostplaceholder";
import RofIntro from "../../../public/video/rof-intro.mp4";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { VideoPlaceholder, useVideoManager } from "../../ui/components/VideoManager";

// SCREEN 33 STORYBOARDS V3
const GetReady = () => {
  const dispatch = useDispatch();

  // useVideoManager(SCREENS.ROF_GET_READY, () => {
  //   dispatch(setWelcomeFinished(true));
  // });

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.ROF_GET_READY));

    return () => {
      dispatch(setCurrentScreen(null));
    };
  }, []);

  return (
    <VideoPlaceholder
      src={RofIntro}
      required={false}
      // onVideoEnd={() => {
      //   console.log("VideoPlaceholder: GetReady");
      //   dispatch(setWelcomeFinished(true));
      // }}
      // duration={64600}
    />
  );
  // return <Hostplaceholder clip={RofIntro} required={false} />;
};

export default GetReady;
