/* eslint-disable react/display-name */
import { Switch, Route } from "react-router-dom";
import RoomList from "./pregame/components/RoomList";
import { ErrorBoundary } from "react-error-boundary";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-use";
import Profile from "./player/components/Profile";
import GameScreen from "./game/components/GameScreen";
import Error from "./shared/components/Error";
import PreGameRoom from "./pregame/components/PreGameRoom";
import PreGamePurchase from "./pregame/components/PreGamePurchase";
import TicketList from "./pregame/components/TicketList";

const ConfigureRoutes = () => {
  const location = useLocation();

  return (
    <ErrorBoundary FallbackComponent={Error} onReset={() => {}}>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/tickets">
            <TicketList />
          </Route>

          <Route path="/game/:roomId/:scheduleId/:gameStart">
            <GameScreen />
          </Route>

          <Route path="/game_room/:roomId">
            <PreGameRoom />
          </Route>

          <Route path="/room/:roomId">
            <PreGamePurchase />
          </Route>

          <Route path="/profile">
            <Profile />
          </Route>

          <Route path="/">
            <RoomList />
          </Route>
        </Switch>
      </AnimatePresence>
    </ErrorBoundary>
  );
};

export default ConfigureRoutes;
