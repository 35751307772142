import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatOpen: true,
    chatRoomId: null,
  },
  reducers: {
    toggleChat(state, action) {
      const toggle = (input) => !input;

      state.chatOpen = action.payload === undefined ? toggle(state.chatOpen) : action.payload;
    },
    setChatRoomId: (state, action) => {
      state.chatRoomId = action.payload;
    },
  },
});

// Selectors
export const selectIsChatOpen = (state) => state.chat.chatOpen;

export const { toggleChat, setChatRoomId } = chatSlice.actions;

export default chatSlice.reducer;
