import { motion } from "framer-motion";
import { confirmationFx } from "../../sound/utils/constants";
import { useDispatch } from "react-redux";
import { playEffect } from "../../sound/actions";
import { CTA, NumberPicker, Ball } from "@ruthless/component-library";

// Step 6 - Ruthless Storyboard V3
const LuckyFateNumber = ({ setLuckyFateSelected, luckyFateNumber, setLuckyFateNumber }) => {
  const dispatch = useDispatch();

  const handlePickerChange = (value) => {
    setLuckyFateNumber(value.toString());
  };

  const handleConfirm = async () => {
    if (luckyFateNumber === "") return;
    dispatch(playEffect(confirmationFx));

    setLuckyFateSelected(true);
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="relative flex justify-center">
        <Ball
          variant="luckyFateNumber"
          title={luckyFateNumber}
          number={luckyFateNumber}
          onChange={setLuckyFateNumber}
          showLight={false}
          subtitle="Lucky Fate Number"
          className="absolute -top-10 z-10 w-[196px]"
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="mb-4 text-center text-white">Slide or type in the number</div>
        <NumberPicker min={1} max={90} onChange={handlePickerChange} defaultValue={parseInt(luckyFateNumber) || 1} />
        <CTA variant="primary" className="w-[280px] mt-4 md:w-fit" onClick={handleConfirm}>
          Confirm
        </CTA>
      </div>
    </motion.div>
  );
};

export default LuckyFateNumber;
