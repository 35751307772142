import { useEffect } from "react";
import RuthlessIntroVid from "../../../public/video/screen35-36.mp4";

import Hostplaceholder from "../../ui/components/Hostplaceholder";
import { useDispatch } from "react-redux";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { VideoPlaceholder, useVideoManager } from "../../ui/components/VideoManager";

const TimeForRuthless = () => {
  const dispatch = useDispatch();

  // useVideoManager(SCREENS.TIME_FOR_RUTHLESS, () => {
  //   setGameOutcome(true);
  // });

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.TIME_FOR_RUTHLESS));

    console.log("time for ruthless - screen 35-36");
    // setTimeout(() => {
    //   setGameOutcome(true);
    // }, 34000);
  }, []);

  return (
    <VideoPlaceholder
      src={RuthlessIntroVid}
      required={false}
      // onVideoEnd={() => {
      //   console.log("VideoPlaceholder: TimeForRuthless");
      //   setGameOutcome(true);
      // }}
      // duration={34000}
    />
  );
  // return <Hostplaceholder clip={RuthlessIntroVid} required={false} />;
};

export default TimeForRuthless;
