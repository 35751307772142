import decode from "jwt-decode";
import { createSlice } from "@reduxjs/toolkit";

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    operator: null,
    ref: null,
    permissions: [],
    routes: {},
    alias: null,
    admin: 0,
    operatorToken: null,
    exp: null,
    iat: null,
  },
  reducers: {
    initSession: (state, action) => {
      const { accessToken } = action.payload;

      if (!accessToken) return state;

      const decoded = decode(accessToken);

      return decoded;
    },
  },
});

// Selectors
export const selectOperator = (state) => state.session.operator;

export const { initSession } = sessionSlice.actions;

export default sessionSlice.reducer;
