import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: null,
  currentWinnersTickets: [],
  contestantsChoices: [],
  ruthlessChoiceTotals: [],
  audienceRuthlessChoices: {
    shareTotal: 0,
    stealTotal: 0,
  },
  audienceRuthlessChoicesForWinners: null,
  ruthlessContestantDecisionTime: null,
  ruthlessClaimPrizeTime: null,
  prizeData: null,
};

const ruthlessFinalSlice = createSlice({
  name: "ruthless",
  initialState,
  reducers: {
    resetRuthlessState: () => initialState,
    syncRuthlessData: (state, action) => {
      const { step, tickets, choices, ruthlessChoiceTotals, audienceRuthlessChoices, audienceRuthlessChoicesForWinners, contestantsDecisionScreenTime, ruthlessClaimPrizeTime, prizeData } =
        action.payload;

      state.step = step;
      state.currentWinnersTickets = tickets;
      state.contestantsChoices = choices;
      state.ruthlessChoiceTotals = ruthlessChoiceTotals;
      state.audienceRuthlessChoices = audienceRuthlessChoices;
      state.audienceRuthlessChoicesForWinners = audienceRuthlessChoicesForWinners;
      state.ruthlessContestantDecisionTime = contestantsDecisionScreenTime;
      state.ruthlessClaimPrizeTime = ruthlessClaimPrizeTime;
      state.prizeData = prizeData;
    },
  },
});

// Selectors
export const selectRuthlessStep = (state) => state.ruthless.step;
export const selectCurrentWinnersTickets = (state) => state.ruthless.currentWinnersTickets;
export const selectContestantsChoices = (state) => state.ruthless.contestantsChoices;
export const selectAmIContestant = createSelector(
  [selectCurrentWinnersTickets, selectRuthlessStep, (_, myTickets) => myTickets.map((ticket) => ticket.uuid)],
  (currentWinnersTickets, ruthlessStep, myTicketsIds) =>
    currentWinnersTickets.some((ticket) => myTicketsIds.includes(ticket.uuid) && (ruthlessStep !== "ruthless_claim_prize_screen" || !ticket.isExistingWinner))
);
export const selectRuthlessChoiceTotals = (state) => state.ruthless.ruthlessChoiceTotals;
export const selectAudienceRuthlessChoices = (state) => state.ruthless.audienceRuthlessChoices;
export const selectAudienceRuthlessChoicesForWinners = (state) => state.ruthless.audienceRuthlessChoicesForWinners;
export const selectRuthlessContestantDecisionTime = (state) => state.ruthless.ruthlessContestantDecisionTime;
export const selectRuthlessClaimPrizeTime = (state) => state.ruthless.ruthlessClaimPrizeTime;
export const selectPrizeData = (state) => state.ruthless.prizeData;

export const { syncRuthlessData, resetRuthlessState } = ruthlessFinalSlice.actions;

export default ruthlessFinalSlice.reducer;
