import Hostplaceholder from "../../ui/components/Hostplaceholder";
import PrizeballPrize from "../../../public/video/prizeball-prize.mp4";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ball } from "@ruthless/component-library";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { VideoPlaceholder, useVideoManager } from "../../ui/components/VideoManager";
import { selectCurrentBall } from "../../game/gameSlice";

const WinningPrize = () => {
  const currentBall = useSelector(selectCurrentBall);
  const dispatch = useDispatch();

  // useVideoManager(SCREENS.WINNING_PRIZE, () => {
  //   setPrizeBallPrizeLoaded(true);
  // });

  // useEffect(() => {
  //   dispatch(setCurrentScreen(SCREENS.WINNING_PRIZE));

  //   // const timeoutId = setTimeout(() => {
  //   //   setPrizeBallPrizeLoaded(true);
  //   // }, 46000);

  //   return () => {
  //     dispatch(setCurrentScreen(null));

  //     // if (timeoutId) {
  //     //   clearTimeout(timeoutId);
  //     // }
  //   };
  // }, []);

  return (
    <div className="relative -top-10">
      <Ball variant="cashQualifier" className="z-10 w-[175px] short:w-[210px]" number={currentBall} color="gold" />
      {/* <Hostplaceholder clip={PrizeballPrize} required={false} /> */}
      <VideoPlaceholder
        src={PrizeballPrize}
        required={false}
        onVideoEnd={() => {
          console.log("VideoPlaceholder: WinningPrize");
          //setPrizeBallPrizeLoaded(true);
        }}
        // duration={46000}
      />
    </div>
  );
};

export default WinningPrize;
