import { AUTHENTICATION_EXPIRED_CODE, Player } from "@vindral/web-sdk";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "../../globals";
import useDelay from "../../shared/hooks/useDelay";
import { setIsLiveHostVisible } from "../hostSlice";
import VideoOverlay from "./VideoOverlay";

const HostPlayer = () => {
  const isDesktopOrLarger = useMediaQuery({ query: "(min-width: 1280px)" });
  const delay = useDelay();

  const [isConnected, setIsConnected] = useState(false);
  const [needsUserInteraction, setNeedsUserInteraction] = useState(false);

  const playerElement = useRef(null);
  const playerInstance = useRef(
    new Player(
      {
        url: process.env.REACT_APP_VINDRAL_URL,
        channelId: process.env.REACT_APP_VINDRAL_CHANNEL_ID,
        logLevel: "warn",
        //burstEnabled: true,
        //sizeBasedResolutionCapEnabled: false,
        //minBufferTime: 200,
        //channelGroupId: process.env.REACT_APP_VINDRAL_CHANNEL_GROUP_ID,
      },
      { controlsEnabled: false }
    )
  );

  const audioOnly = useSelector((s) => s.host.audioOnly);
  const streamToken = useSelector((s) => s.config["STREAM_TOKEN"]);
  const [tokenNeedsRefresh, setTokenNeedsRefresh] = useState(true);

  const currentScreen = useSelector((state) => state.ui.currentScreen);
  const isChatOpen = useSelector((state) => state.chat.chatOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLiveHostVisible(true));

    return () => {
      dispatch(setIsLiveHostVisible(false));
    };
  }, []);

  useEffect(() => {
    playerInstance.current.core.on("error", handleError);
    playerInstance.current.core.on("needs user input", handleNeedsUserInput);
    playerInstance.current.core.on("is live", handleIsLive);
    playerInstance.current.core.on("connection state", handleConnectionStateChange);

    playerInstance.current.attach(playerElement.current);

    playerInstance.current.core.play();
    // delay(5000).then(() => {
    //   console.log("ATTEMPT TO CONNECT");
    //   playerInstance.current.core.connect();
    // });

    return () => {
      playerInstance.current.core.off("error", handleError);
      playerInstance.current.core.off("needs user input", handleNeedsUserInput);
      playerInstance.current.core.off("is live", handleIsLive);
      playerInstance.current.core.off("connection state", handleConnectionStateChange);

      playerInstance.current.unload();
    };
  }, []);

  const handleError = (err) => {
    console.log("VINDRAL: Error", err);

    if (err === AUTHENTICATION_EXPIRED_CODE) setTokenNeedsRefresh(true);
  };

  const handleNeedsUserInput = () => {
    console.log("VINDRAL: Needs user input");
    setNeedsUserInteraction(true);
  };

  const handleIsLive = (data) => {
    console.log("VINDRAL: Vindral connection succeeded", data);
  };

  const handleConnectionStateChange = (data) => {
    console.log("VINDRAL: connection state changed", data);

    if (data === "connected") {
      delay(5000).then(() => {
        setIsConnected(true);
      });
    } else {
      setIsConnected(false);
    }
  };

  const handleVideoClick = () => {
    if (!needsUserInteraction) return;

    // old approach
    // playerInstance.current.playOverlay.hide();
    // playerInstance.current.core.muted = false;

    playerInstance.current.core.play();
    setNeedsUserInteraction(false);
  };

  const shouldHideHost =
    (isChatOpen && !isDesktopOrLarger) ||
    audioOnly ||
    !isConnected ||
    [SCREENS.ROF_GAME_OUTCOMES, SCREENS.VOTING_RESULTS, SCREENS.REVEAL, SCREENS.GAME_OUTCOMES_MAIN, SCREENS.PAST_VOTES, SCREENS.AUDIENCE_VOTE].includes(currentScreen);

  return (
    <>
      <div id="host_player_container" onClick={handleVideoClick} className={`fixed bottom-0 left-0 right-0 mx-auto w-[270px] short:w-[330px] aspect-square ${shouldHideHost ? "hidden" : "visible"}`}>
        <div ref={playerElement} className={"flex justify-center h-full mx-auto vindralWrapper relative pointer-events-none"}>
          {isConnected && <div className="absolute -top-[1px] left-0 h-[25px] right-0 bg-gradient-to-b from-[rgb(11,53,119)] overlay" />}
        </div>
        <VideoOverlay isVisible={needsUserInteraction} />
      </div>
    </>
  );
};

export default HostPlayer;
