import { motion } from "framer-motion";
import LiveGame from "./LiveGame";
import { useSelector } from "react-redux";
import moment from "moment";
import { useGetRoomsQuery } from "../../../api/api";

const LiveList = () => {
  // const [roomsLoading, rooms] = useRooms(operator, { timeout: 600000 });
  const { data: rooms, isLoading: isLoadingRooms } = useGetRoomsQuery(operator);

  const { liveTickets } = useSelector((state) => state.livegames);
  const { operator } = useSelector((state) => state.session);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      style={{ originX: 0, originY: 0 }}
      className="bg-gray-700 text-white p-4 rounded-md absolute top-1/2 left-1/2 min-w-[12rem]"
    >
      <div className="border-b w-full">Live Games Running:</div>
      {isLoadingRooms ? (
        <span>Loading...</span>
      ) : (
        <ul className="mt-4 flex flex-col space-y-2">
          {liveTickets?.map((ticket, index) => {
            const room = rooms && rooms.filter((room) => room.id === ticket.room_id)[0];

            return <LiveGame tickets={ticket.tickets} key={index} room={room} schedule_id={ticket.schedule_id} time={moment(ticket.scheduleTime)} />;
          })}
        </ul>
      )}
    </motion.div>
  );
};

export default LiveList;
