import { motion } from "framer-motion";
import { useState } from "react";
import { tickVariants } from "../animations";
import { Container, UserIcon, CTA } from "@ruthless/component-library";

const WelcomeScreen = ({ active, playerName, onContinueToGame, welcomeBack }) => {
  const [tickState, setTickState] = useState("checked");

  if (!active) return null;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={`flex flex-col h-full w-full justify-start items-center content-start`}>
      <div className="mx-auto flex flex-col justify-start items-center w-full">
        <div className={`mb-2 ${welcomeBack && "hidden"}`}>
          <svg className="mx-auto" width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="-16 -16 100 100">
            <motion.path
              variants={tickVariants}
              initial="unchecked"
              animate={tickState}
              onAnimationComplete={() => {
                setTickState("done");
              }}
              fillRule="evenodd"
              clipRule="evenodd"
              fill="transparent"
              d="M0 34C0 15.2223 15.2223 0 34 0C52.7777 0 68 15.2223 68 34C68 52.7777 52.7777 68 34 68C15.2223 68 0 52.7777 0 34ZM32.0567 40.0933L47.4185 24.7315C48.3319 23.8804 49.7553 23.9055 50.6381 24.7883C51.5209 25.6711 51.546 27.0945 50.6949 28.0079L33.6949 45.0079C32.7898 45.9118 31.3236 45.9118 30.4185 45.0079L19.6003 34.1897C18.7492 33.2763 18.7743 31.8529 19.6571 30.9701C20.5399 30.0873 21.9633 30.0622 22.8767 30.9133L32.0567 40.0933Z"
              stroke="#F9B70D"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <h3 className="text-lg text-white text-center mb-6">Welcome {welcomeBack && `Back`}</h3>
        <Container className="p-6 flex justify-center items-center flex-col w-full rounded-2xl">
          <div className="flex justify-center items-center text-white text-lg font-bold h-10 mb-4 space-x-2">
            <UserIcon className="fill-[#76AAE0] w-6 h-6" />
            <p>{playerName}</p>
          </div>

          <CTA className="w-full" onClick={onContinueToGame}>
            Get Started
          </CTA>
        </Container>
      </div>
    </motion.div>
  );
};

WelcomeScreen.propTypes = {};
WelcomeScreen.defaultProps = {};

export default WelcomeScreen;
