import { useDispatch, useSelector } from "react-redux";
import ReactHowler from "react-howler";
import { setTrack } from "../../reducer";

const BackgroundMusic = () => {
  const { music, volume, mute, track } = useSelector((state) => state.sound);

  const assetUrl = useSelector((s) => s?.config?.assetUrl);

  const dispatch = useDispatch();

  const onEndHandler = () => {
    let currentIndex = music.findIndex((song) => song === track);

    dispatch(setTrack(music[(currentIndex + 1) % music.length]));
  };

  if (track === "" || track === undefined || assetUrl === undefined) return null;

  return <ReactHowler html5={true} src={`${assetUrl}/audio/ruthless/music/${track}.webm`} onEnd={onEndHandler} volume={volume.background} mute={mute}></ReactHowler>;
};

export default BackgroundMusic;
