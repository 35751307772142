import { useState, useEffect, useRef } from "react";

import { Ball } from "@ruthless/component-library";
import { motion } from "framer-motion";
import { ref, query, onValue, equalTo, orderByChild, child } from "firebase/database";

import {
  selectBallHistory,
  selectCashQualifierPrizes,
  selectGameState,
  selectCurrentStep,
  selectRecentLineWinners,
  syncGameDataWithStep,
  setInitialGameData,
  selectCurrentBall,
  selectHasInitialDataLoaded,
} from "../gameSlice";
import { useDispatch, useSelector } from "react-redux";
import Currency from "../../ui/components/Currency";
import Ticket from "./Ticket";

import ROFScreen from "../../ruthlessFinal/screens/ROFScreen";
import { selectShouldShowPrizeBallEndingSplashScreen, showPrizeBallEndingSplashScreen, syncPrizeBallData } from "../../prizeball/reducer";
import { PrizeBall } from "../../prizeball";
import { syncRuthlessData } from "../../ruthlessFinal/reducer";
import { SPLASH_SCREENS } from "../../globals";
import useDelay from "../../shared/hooks/useDelay";
import useFirebase from "../../shared/hooks/useFirebase";
import useSplashScreen from "../../shared/hooks/useSplashScreen";

const Game = ({ roomId, scheduleId, scheduleTime, goBackToRoom, currency, setWinnerModalActive, winnerModalActive, tickets, initialGameUuid }) => {
  const {
    firebase: { database, envName },
  } = useFirebase();

  const finishedFirstRender = useRef(false);

  const delay = useDelay();
  // Fix the session reducer later

  // const [loadingTickets, tickets, errorTickets] = useTicketsForGame(roomId, scheduleId, scheduleTime);
  const { isSplashScreenActive, showSplashScreen } = useSplashScreen();

  // useEffect(() => {
  //   if (isTicketsSuccess && !tickets) {
  //     goBackToRoom();
  //   }
  // }, [isTicketsSuccess]);

  // const { currentBall } = useSelector((s) => s.game);

  const hasInitialDataLoaded = useSelector(selectHasInitialDataLoaded);
  const currentBall = useSelector(selectCurrentBall);
  const gameState = useSelector(selectGameState);
  const ballHistory = useSelector(selectBallHistory);
  const cashQualifierPrizes = useSelector(selectCashQualifierPrizes);
  const currentStep = useSelector(selectCurrentStep);
  const recentLineWinners = useSelector(selectRecentLineWinners);
  const shouldShowPrizeBallEndingSplashScreen = useSelector(selectShouldShowPrizeBallEndingSplashScreen);

  const [lastWinnerModalShownForStep, setLastWinnerModalShownForStep] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onValue(ref(database, `/${envName}/Client/${initialGameUuid}`), (snapshot) => {
      const details = snapshot.val();

      const gameState = details?.gameState;
      const prizeBall = details?.prizeBall;
      const ruthless = details?.ruthless;

      console.log("FINAL DETAILS", details);

      if (!hasInitialDataLoaded) {
        dispatch(setInitialGameData(details));
      }

      // If gameState exist it means that the game has started.
      if (gameState) {
        dispatch(syncGameDataWithStep(details));
      }

      if (prizeBall && gameState === "prize_ball") {
        dispatch(syncPrizeBallData(prizeBall));
      }

      if (ruthless && gameState === "ruthless") {
        dispatch(syncRuthlessData(ruthless));
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!gameState) {
      finishedFirstRender.current = true;
      return;
    }

    if (recentLineWinners.step === currentStep && lastWinnerModalShownForStep !== currentStep) {
      console.log("Winners announced in this step, show the modal");
      setWinnerModalActive(true);

      delay(3500).then(() => {
        setWinnerModalActive(false);
        setLastWinnerModalShownForStep(currentStep);
      });
    } else if (gameState === "prize_ball") {
      console.log("Prize ball state, show the splash screen");

      showSplashScreen(SPLASH_SCREENS.PRIZE_BALL);
    } else {
      console.log("Nothing special happened, just show the game");
    }

    finishedFirstRender.current = true;
  }, [gameState, currentStep, lastWinnerModalShownForStep]);

  useEffect(() => {
    if (!shouldShowPrizeBallEndingSplashScreen || gameState !== "cash_qualifier") return;

    console.log("Prize ball has finished, show back to cash qualifier splash screen");

    showSplashScreen(SPLASH_SCREENS.CASH_QUALIFIER_BACK_TO, () => {
      dispatch(showPrizeBallEndingSplashScreen(false));
    });
  }, [gameState, shouldShowPrizeBallEndingSplashScreen]);

  const getRoundData = () => {
    const { type } = recentLineWinners;

    switch (type) {
      case "oneLine":
        return {
          roundText: "Round 2",
          prizeValue: parseInt(cashQualifierPrizes["twoLine"], 10),
        };
      case "twoLine":
        return {
          roundText: "Round 3",
          prizeValue: parseInt(cashQualifierPrizes["fullHouse"], 10),
        };
      case "fullHouse":
        return {
          roundText: "Game Over",
          prizeValue: parseInt(cashQualifierPrizes["fullHouse"], 10),
        };
      default:
        return {
          roundText: "Round 1",
          prizeValue: parseInt(cashQualifierPrizes["oneLine"], 10),
        };
    }
  };

  if (!finishedFirstRender.current) {
    return null;
  }

  if (gameState === "prize_ball" && !winnerModalActive) {
    return <PrizeBall tickets={tickets} />;
  }

  if (gameState === "ruthless" && !winnerModalActive) {
    return (
      <section className="flex-1">
        <ROFScreen tickets={tickets} />
      </section>
    );
  }

  if (isSplashScreenActive) {
    return null;
  }

  const roundData = getRoundData();

  return (
    <section className="w-full relative">
      {/* <HostConnection roomId={roomId} gameId={gameUuid} />
  <Host roomId={roomId} gameId={gameUuid} /> */}
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="select-none flex flex-col">
        <div className="flex justify-center items-center absolute left-0 right-0 -top-10">
          <Ball variant="cashQualifier" className="z-[1] w-[160px] short:w-[210px]" number={currentBall} isAnimationEnabled={true} />
        </div>
        <div className="flex justify-between w-full mt-[100px] short:mt-[145px] md:mx-auto md:max-w-[400px]">
          <div className="text-white">
            <div className="text-xs">{roundData.roundText}</div>
            <div className="font-bold text-base short:text-lg flex font-pdm-secondary">{roundData.prizeValue ? <Currency value={roundData.prizeValue} currency={currency} /> : "Starting soon"}</div>
          </div>
          <div className="text-white flex-shrink-0">
            <div className="text-xs">Call No.</div>
            <div className="font-bold text-base short:text-lg flex justify-end">{ballHistory.length > 0 ? ballHistory.length : "..."}</div>
          </div>
        </div>
        <div className="mt-2 short:mt-4">
          {/* {!!loadingTickets && <ClipLoader />} */}
          {tickets &&
            tickets.tickets &&
            tickets.tickets.map((ticket, index) => {
              return <Ticket ticket={ticket} ticketData={ticket.data} ballHistory={ballHistory} index={index} key={ticket.uuid} />;
            })}
        </div>
      </motion.div>
    </section>
  );
};

export default Game;
