export const SECTIONS = {
  PRE_GAME: "preGame",
  CASH_QUALIFIER: "cashQualifier",
  PRIZE_BALL: "prizeBall",
  ROF: "reelsOfFate",
  RUTHLESS_FINAL: "ruthlessFinal",
  GAME_END: "gameEnd",
};

export const SCREENS = {
  ROOM_LIST: [SECTIONS.PRE_GAME, "roomListScreen"].join("."),
  PRE_GAME_ROOM: [SECTIONS.PRE_GAME, "waitingRoom"].join("."),
  CLAIM_TICKET: [SECTIONS.PRE_GAME, "claimTicketScreen"].join("."),
  CHOOSE_PRIZE: [SECTIONS.PRE_GAME, "choosePrizeScreen"].join("."),
  ABOUT_TO_START: [SECTIONS.PRE_GAME, "aboutToStartScreen"].join("."),
  PRIZE_BALL_INTRO: [SECTIONS.PRIZE_BALL, "prizeBallIntro"].join("."),
  PRIZES_NOT_LOADED: [SECTIONS.PRIZE_BALL, "prizesNotLoaded"].join("."),
  PRIZE_BALL_CONGRATS: [SECTIONS.PRIZE_BALL, "congrats"].join("."),
  CASH_QUALIFIER_END: [SECTIONS.ROF, "cashQualifierEnd"].join("."),
  ROF_GET_READY: [SECTIONS.ROF, "getReady"].join("."),
  TIME_FOR_RUTHLESS: [SECTIONS.ROF, "timeForRuthless"].join("."),
  ROF_GAME_OUTCOMES: [SECTIONS.ROF, "gameOutcomes"].join("."),
  RUTHLESS_INTRO: [SECTIONS.RUTHLESS_FINAL, "intro"].join("."),
  AUDIENCE_HOST: [SECTIONS.RUTHLESS_FINAL, "audienceHost"].join("."),
  BIG_REVEAL_INTRO: [SECTIONS.RUTHLESS_FINAL, "bigRevealIntro"].join("."),
  VOTING_RESULTS: [SECTIONS.RUTHLESS_FINAL, "votingResults"].join("."),
  REVEAL: [SECTIONS.RUTHLESS_FINAL, "reveal"].join("."),
  GAME_OUTCOMES_MAIN: [SECTIONS.RUTHLESS_FINAL, "gameOutcomesMain"].join("."),
  PAST_VOTES: [SECTIONS.RUTHLESS_FINAL, "pastVotes"].join("."),
  AUDIENCE_VOTE: [SECTIONS.RUTHLESS_FINAL, "audienceVote"].join("."),
  RUTHLESS_WINNERS: [SECTIONS.RUTHLESS_FINAL, "ruthlessWinners"].join("."),
  GAME_SPONSORS: [SECTIONS.RUTHLESS_FINAL, "gameSponsors"].join("."),
  TICKETS: "ticketsScreen",
  PROFILE: "profileScreen",
};

export const SPLASH_SCREENS = {
  PRE_GAME: [SECTIONS.PRE_GAME, "splash"].join("."),
  CASH_QUALIFIER_TIME_TO: [SECTIONS.CASH_QUALIFIER, "timeToSplash"].join("."),
  CASH_QUALIFIER_BACK_TO: [SECTIONS.CASH_QUALIFIER, "backToSplash"].join("."),
  PRIZE_BALL: [SECTIONS.PRIZE_BALL, "splash"].join("."),
  ROF: [SECTIONS.ROF, "splash"].join("."),
  RUTHLESS_TIME_TO_PLAY: [SECTIONS.RUTHLESS_FINAL, "timeToPlaySplash"].join("."),
  RUTHLESS_FINAL: [SECTIONS.RUTHLESS_FINAL, "splash"].join("."),
  RUTHLESS_REVEAL: [SECTIONS.RUTHLESS_FINAL, "revealSplash"].join("."),
};

export const GAME_STATES = {
  CASH_QUALIFIER: "cashQualifier",
};
