// player/components/Avatar.js
export const avatarImgVariants = {
  from: {
    pathLength: 0,
  },
  to: {
    pathLength: 1,
  },
};

// player/components/ChooseNameScreen.js
export const chooseNameScreenCntr = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
    transition: {
      delay: 0.6,
    },
  },
};

// player/components/IntroScreen.js
export const introScreenVariants = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

// player/components/PlayerNameModal.js
export const logoVariants = {
  big: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  smol: {
    opacity: 1,
    scale: 0.8,
    y: -100,
  },
  pre: {
    opacity: 0,
    scale: 2,
    y: -200,
  },
};

export const mainVariants = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

// player/components/Profile.js
export const profileTitle = {
  from: {
    x: typeof window !== "undefined" ? window.innerWidth : 0,
  },
  to: {
    x: 0,
  },
};

// player/components/WelcomeScreen.js
export const welcomeScreenVar = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.6,
    },
  },
};

export const tickVariants = {
  unchecked: {
    pathLength: 0,
    fill: "rgba(249, 183, 13, 0)",
    transition: { delay: 0.1, duration: 2 },
  },
  checked: {
    pathLength: 1,
    fill: "rgba(249, 183, 13, 0)",
    transition: { delay: 0.5, duration: 2 },
  },
  done: {
    strokeWidth: 0,
    fill: "rgba(249, 183, 13, 1)",
    transition: {
      duration: 1,
    },
  },
};
