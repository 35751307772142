import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactHowler from "react-howler";
import { setEffectPlaying } from "../../reducer";
import { playEffect } from "../../actions";

const SoundEffects = () => {
  const { effect, effectPlaying, volume, mute, formats, playbackRate } = useSelector((state) => state.sound);
  const [sound, setSound] = useState("");
  const assetUrl = useSelector((s) => s?.config?.assetUrl);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!effect) return;
    setSound(effect);
  }, [effect]);

  const onEndHandler = () => {
    dispatch(playEffect("", 1));
    dispatch(setEffectPlaying(false));
    setSound("");
  };

  if (sound === "" || sound === undefined || assetUrl == undefined) return null;

  return (
    <ReactHowler
      format={formats}
      onEnd={onEndHandler}
      html5={true}
      playing={effectPlaying}
      src={`${assetUrl}/audio/ruthless/effects/${sound}.webm`}
      volume={volume.effects}
      mute={mute}
      rate={playbackRate}
    />
  );
};

export default SoundEffects;
