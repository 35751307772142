import { CoreLights, Logo, LoadingBar } from "@ruthless/component-library";
import { motion } from "framer-motion";

const LogoScreen = ({ loading, techDifficulties, renderContent }) => {
  return (
    <motion.div className="w-full h-full">
      <div className="w-full h-full items-center justify-center select-none bg-[#031023] overflow-auto">
        <div className="fixed appBg top-0 left-0 right-0 bottom-[10%] md:bg-contain pointer-events-none" />
        <CoreLights className="flex flex-col md:max-w-2xl lg:max-w-xl mx-auto min-h-[600px]" top={false}>
          <div className="h-full">
            <motion.div className="h-[55%] flex flex-col justify-center items-center mt-12">
              <Logo className="z-[1]" variant="large" showLights showGradient />
            </motion.div>
            <motion.div className="flex justify-center relative z-10 h-[45%] w-10/12 md:w-full mx-auto" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {loading ? (
                <LoadingBar showLabel={true} className="w-full md:w-3/4 mx-auto" />
              ) : techDifficulties ? (
                <h1 className="text-center text-yellow-400 text-4xl font-bold">We Are Currently Experiencing Technical Difficulties</h1>
              ) : (
                renderContent
              )}
            </motion.div>
          </div>
        </CoreLights>
      </div>
    </motion.div>
  );
};

export default LogoScreen;
