import { useEffect, useState } from "react";
import AvailablePrizes from "./AvailablePrizes";
import Intro from "./Intro";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentScreen, setCurrentSection } from "../../ui/uiSlice";
import { SCREENS, SECTIONS, SPLASH_SCREENS } from "../../globals";
import { AnimatePresence, motion } from "framer-motion";
import { useVideoManager } from "../../ui/components/VideoManager";
import { selectPrizeBallStep } from "../reducer";
import useSplashScreen from "../../shared/hooks/useSplashScreen";

const PrizeBall = ({ tickets }) => {
  const dispatch = useDispatch();
  const { isSplashScreenActive } = useSplashScreen();

  const step = useSelector(selectPrizeBallStep);

  // useVideoManager(SCREENS.PRIZE_BALL_INTRO, () => {
  //   setIntroLoaded(true);
  // });

  useEffect(() => {
    dispatch(setCurrentSection(SECTIONS.PRIZE_BALL));
    dispatch(setCurrentScreen(SCREENS.PRIZE_BALL_INTRO));

    return () => {
      dispatch(setCurrentSection(SECTIONS.CASH_QUALIFIER));
      dispatch(setCurrentScreen(null));
    };
  }, []);

  if (isSplashScreenActive) {
    return null;
  }

  // Prototype of the working animate presence implementation.
  return (
    <AnimatePresence exitBeforeEnter>
      {step === "intro_screen" ? (
        <motion.div key="prizeBallIntro" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="flex flex-col justify-center items-center relative -top-10">
          <Intro />
        </motion.div>
      ) : (
        <motion.div key="availablePrizes" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="h-full w-full flex flex-col flex-1 items-center">
          <AvailablePrizes tickets={tickets} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PrizeBall;
