import "moment";
import "moment-timezone";

import { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";

import { configureStore } from "./src";
import AppFlow from "./AppFlow";
import { SoundWrapper } from "./src/sound/index";
import { ThemeProvider } from "@ruthless/component-library";
import { ref, get } from "firebase/database";
import { SplashScreenProvider } from "./src/shared/hooks/useSplashScreen";
import LogoScreen from "./src/shared/components/LogoScreen";
import { Tooltip } from "react-tooltip";
import useFirebase from "./src/shared/hooks/useFirebase";

// TODO: Need to supply initialstate. Config should be initialState.
const App = function ({ config, isServerSide, playerData }) {
  const isDevelopment = process.env.NODE_ENV !== "production";

  const {
    firebase: { isInitialized, database },
  } = useFirebase();

  if (typeof window !== "undefined") {
    const loader = document.querySelector("div.loader");
    loader.classList.add("hidden");
    const container = document.querySelector("div#container");

    container.classList.add("w-full", "h-full");
    container.classList.remove("hidden");

    if (!isDevelopment) {
      (async () => {
        const Sentry = await import("@sentry/react");
        const { Integrations } = await import("@sentry/tracing");
        Sentry.init({
          environment: process.env.NODE_ENV,
          dsn: process.env.REACT_APP_SENTRY_DSN,
          integrations: [new Integrations.BrowserTracing()],
          tracesSampleRate: 1.0,
        });
      })();
    }
  }

  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState(null);

  useEffect(() => {
    if (!database || !isInitialized) return;

    get(ref(database, "technicalDifficulties/Platform"))
      .then((snapshot) => {
        setStore(configureStore(config, isServerSide, playerData, snapshot.exists() ? snapshot.val() : false));
      })
      .catch((error) => {
        console.log("Error recieving platform tech difficulties", error);

        setStore(configureStore(config, isServerSide, playerData, false));
      })
      .finally(() => setLoading(false));
  }, [isInitialized]);

  return (
    <>
      {loading ? (
        <ThemeProvider>
          <LogoScreen loading={loading} />
        </ThemeProvider>
      ) : (
        <Provider store={store}>
          <IntlProvider locale="en" defaultLocale="en">
            <SplashScreenProvider>
              <ThemeProvider>
                <AppFlow config={config} />
              </ThemeProvider>
              <SoundWrapper />
            </SplashScreenProvider>
          </IntlProvider>
        </Provider>
      )}
      <Tooltip id="ruthless" />
    </>
  );
};

export default App;
