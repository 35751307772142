import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../components/Card";
import { PulseLoader } from "react-spinners";
import { setTrack } from "../../sound/reducer";
import { setCurrentScreen, setCurrentSection } from "../../ui/uiSlice";
import { SCREENS, SECTIONS } from "../../globals";
import { useGetRoomsQuery } from "../../api/api";
import { selectOperator } from "../../session/sessionSlice";
import { withRouter } from "react-router-dom";

const RoomList = ({ history }) => {
  // const [newRooms, setNewRooms] = useState([]);
  // const [roomsLoading, rooms, error] = useRooms(operator, { timeout: 60000 });

  const operator = useSelector(selectOperator);

  const { data: rooms, isLoading: isLoadingRooms, isError } = useGetRoomsQuery(operator);
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    dispatch(setCurrentSection(SECTIONS.PRE_GAME));
    dispatch(setCurrentScreen(SCREENS.ROOM_LIST));
    dispatch(setTrack("general-1"));

    return () => {
      dispatch(setCurrentScreen(null));
    };
  }, []);

  // useEffect(() => {
  //   const roomsWithAds = rooms?.map(({ id: roomId, props: { name, lobby_image, room_image } }, i) => (
  //     <Card
  //       key={roomId}
  //       roomId={roomId}
  //       index={i}
  //       onRequestRoom={() => onRequestRoom(roomId, { name, image: lobby_image, room_image: room_image })}
  //       name={name}
  //       image={lobby_image}
  //       operator={operator}
  //       isSelected={i === selectedId}
  //     />
  //   ));

  //   // for (let i = 2; i < roomsWithAds?.length; i += 3) {
  //   //   roomsWithAds.splice(i, 0, <Advertisement roomsCardChild={roomsCardChild} image="http://eprints.glos.ac.uk/4559/3/Screen%20Shot%202017-04-23%20at%2017.41.06.png" url="https://www.pdm-ltd.com/" />);
  //   // }

  //   setNewRooms(roomsWithAds);
  // }, [rooms, selectedId]);

  const handleDragEnd = (_, { offset }) => {
    if (Math.abs(offset.x) < 150) return;

    const nextSelectedId = offset.x > 0 ? selectedId - 1 : selectedId + 1;

    if (nextSelectedId < 0 || nextSelectedId > rooms.length - 1) return;

    setSelectedId(nextSelectedId);
  };

  return (
    <>
      {isLoadingRooms ? (
        <div className="fixed h-full w-full flex items-center justify-center">
          <PulseLoader size={30} color={"#1D91F8"} loading={isLoadingRooms} />
        </div>
      ) : (
        <>
          {isError ? (
            <p>Error.</p>
          ) : (
            <div className="flex flex-col justify-center items-center h-full">
              <motion.div className="flex w-full max-w-lg space-x-4" drag="x" dragDirectionLock dragSnapToOrigin={true} onDragEnd={handleDragEnd}>
                {rooms.map(({ id: roomId, props: { name, lobby_image, room_image } }, index) => (
                  <motion.div
                    key={index}
                    className="w-full"
                    initial={{ opacity: 0 }}
                    animate={{ x: `${index - selectedId * 100}%`, opacity: 1, scale: index === selectedId ? 1 : 0.8 }}
                    transition={{ type: "spring", stiffness: 200, damping: 20 }}
                  >
                    <Card
                      key={roomId}
                      roomId={roomId}
                      index={index}
                      onRequestRoom={() =>
                        history.push({
                          pathname: `/room/${roomId}`,
                          state: {},
                        })
                      }
                      name={name}
                      image={lobby_image}
                      operator={operator}
                      isSelected={index === selectedId}
                    />
                  </motion.div>
                ))}
              </motion.div>
              <div className="mt-10 space-y-5">
                {rooms.map((_, i) => (
                  <div key={`dots-${i}`} className={`w-2 h-2 rounded-full ${i === selectedId ? "bg-white" : "bg-white/20"}`} />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(RoomList);
