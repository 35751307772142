import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import goldenConfetti from "../../../public/lotte/golden-confetti.json";
import { AnimatePresence, motion } from "framer-motion";

import pcImg from "../../../public/img/prizes/pc.png";
import ps5Img from "../../../public/img/prizes/ps5.jpg";
import xboxImg from "../../../public/img/prizes/xbox.jpg";
import { playEffect } from "../../sound/actions";
import { clickFx, confirmationFx } from "../../sound/utils/constants";
import { PrizeSelect, CTA } from "@ruthless/component-library";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { useBuyGameTicketMutation, useGetBalanceQuery, useGetTicketTypeForScheduleIdQuery } from "../../api/api";
import { sendMessageFromFrameToParent } from "../../shared/utils/browserUtils";

// Step 7 - Ruthless Storyboard V3
const ChoosePrize = ({ roomId, nextGameStart, setHasPurchasedTicket, luckyFateNumber }) => {
  const { data: ticketType, isLoading: isLoadingTicketType } = useGetTicketTypeForScheduleIdQuery(nextGameStart.schedule.ticket_type_id);

  const { prizeBallPrizes } = useSelector((state) => state.prizeBall);
  const { data: balanceData, isLoading: isLoadingBalance } = useGetBalanceQuery();

  const [selectedPrize, setSelectedPrize] = useState("");
  const prizeRefs = [useRef(), useRef(), useRef()];

  const [buyTicket, { isLoading: isPendingTicketPurchase }] = useBuyGameTicketMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.CHOOSE_PRIZE));
  }, []);

  const handleConfirm = async () => {
    try {
      if (!ticketType) throw new Error("No matching ticket type found");
      if (!nextGameStart) throw new Error("No nextGameStart found");

      await buyTicket({
        roomId,
        ticketId: ticketType.ticket.id,
        quantity: 1, // currently we only support buying one ticket
        luckyFateNumber: parseInt(luckyFateNumber),
        prize: selectedPrize,
        walletId: balanceData.balance.walletId,
        schedule: {
          time: nextGameStart.date.toDate().toString(),
          id: nextGameStart.schedule.id,
          locale: nextGameStart.schedule.locale,
        },
      });

      dispatch(playEffect(confirmationFx));
      sendMessageFromFrameToParent("joinedGame", { roomId, scheduleId: nextGameStart.schedule.id, scheduleTime: nextGameStart.date.getTime() });
      setHasPurchasedTicket(true);
    } catch (error) {
      console.error("Error while buying tickets", error);
    }
  };

  const getCoverImg = (prizeTitle) => {
    switch (prizeTitle) {
      case "Gaming PC":
        return pcImg;
      case "PS5":
        return ps5Img;
      case "XBOXX":
        return xboxImg;
      default:
        return `/img/prizes/${prizeTitle}.png`;
    }
  };

  if (isLoadingTicketType || isLoadingBalance) {
    return null;
  }

  return (
    <motion.div className="flex flex-col items-center h-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <motion.div key="prizeSelect" className="flex flex-col items-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <div className="text-white text-center mb-10 mt-5 font-bold">Choose your prize</div>
        <div className="flex justify-center items-center z-10 mb-8 space-x-3 xs:space-x-6">
          {prizeBallPrizes.map((prize, index) => (
            <PrizeSelect
              ref={prizeRefs[index]}
              key={prize}
              animationData={goldenConfetti}
              isSelected={selectedPrize === prize}
              onPrizeSelect={(state) => {
                dispatch(playEffect(clickFx));
                setSelectedPrize(state ? prize : "");

                if (state) {
                  prizeRefs[index].current?.goToAndPlay(0, false);
                }
              }}
              prize={{ imageUrl: getCoverImg(prize), title: prize }}
              titlePosition="bottom"
              className="min-w-[96px]"
            />
          ))}
        </div>
        <AnimatePresence>
          {selectedPrize !== "" && (
            <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, scale: 0 }} className="flex justify-center">
              <CTA onClick={handleConfirm} className="w-5/6 md:w-fit" isLoading={isPendingTicketPurchase}>
                Choose
              </CTA>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default ChoosePrize;
