import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { useDispatch, useSelector } from "react-redux";
import Controls from "../../sound/components/Controls";
import { setControlsOpen } from "../../sound/reducer";
import { playEffect } from "../../sound/actions";
import RuthlessCounter from "../../game/components/RuthlessCounter";
import { menuOpenFx, clickFx } from "../../sound/utils/constants";
import { Logo, IconContainer, ChevronLeftIcon, MusicLineIcon, MusicLineMuteIcon, CogIcon, DoorExitIcon } from "@ruthless/component-library";
import { SCREENS } from "../../globals";
import { useMediaQuery } from "react-responsive";

const Navigation = () => {
  const location = useLocation();
  const history = useHistory();

  const [isInGame, setIsInGame] = useState(false);

  const { controlsOpen, mute } = useSelector((state) => state.sound);
  const currentScreen = useSelector((state) => state.ui.currentScreen);

  const dispatch = useDispatch();

  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    if (location.pathname.startsWith("/game/")) {
      setIsInGame(true);
    } else {
      setIsInGame(false);
    }
  }, [location.pathname]);

  const handleControlSound = (e) => {
    e.stopPropagation();
    if (e.target.classList.contains("navBackground")) {
      dispatch(setControlsOpen(false));
    }
  };

  return (
    <nav onClick={handleControlSound} className="flex justify-between items-center px-4 pt-4 short:pb-8 pb-6">
      <div className="flex items-center flex-shrink-0 text-white z-20 flex-1">
        <span className="font-semibold text-xl tracking-tight">
          {currentScreen === SCREENS.TICKETS ? (
            <IconContainer items={[{ icon: ChevronLeftIcon }]} label="Back" onClick={() => history.goBack()} />
          ) : (
            <Link onClick={() => dispatch(playEffect(clickFx))} to="/">
              <Logo variant="small" showLights={true} />
            </Link>
          )}
        </span>
      </div>
      {isInGame && isTabletOrLarger && (
        <div className="flex justify-center items-center relative z-20 flex-1">
          <RuthlessCounter />
        </div>
      )}
      <div className="flex items-center bg-transparent z-20 navBackground space-x-3 flex-1 justify-end">
        <div className="relative">
          <IconContainer
            items={
              isTabletOrLarger
                ? [
                    {
                      icon: CogIcon,
                      onClick: () => {
                        history.push("/profile");
                      },
                    },
                    {
                      icon: DoorExitIcon,
                      onClick: () => {
                        history.push("/profile");
                      },
                    },
                    {
                      icon: mute ? MusicLineMuteIcon : MusicLineIcon,
                      name: "sound",
                      onClick: () => {
                        dispatch(playEffect(menuOpenFx));
                        dispatch(setControlsOpen(!controlsOpen));
                      },
                    },
                  ]
                : [
                    {
                      icon: mute ? MusicLineMuteIcon : MusicLineIcon,
                      name: "sound",
                      onClick: () => {
                        dispatch(playEffect(menuOpenFx));
                        dispatch(setControlsOpen(!controlsOpen));
                      },
                    },
                  ]
            }
          />
          <AnimatePresence>{controlsOpen && <Controls />}</AnimatePresence>
        </div>
      </div>
    </nav>
  );
};

Navigation.defaultProps = {};

export default Navigation;
