import { RECEIVE_STREAM_TOKEN, RECEIVE_CONFIG, RECEIVE_TOKEN, RESET_STREAM_TOKEN } from "./actionTypes";

export default (state = {}, { type, ...extras }) => {
  switch (type) {
    case RECEIVE_TOKEN: {
      const { token } = extras;
      return { ...state, __ruthless_t: token };
    }
    case RECEIVE_CONFIG: {
      const { config } = extras;
      config.assetUrl = process.env.NODE_ENV === "development" ? "http://localhost:8080" : `https://cdn.bingosolutions.com/${process.env.REACT_APP_CDN_SUBDOMAIN}/public`;
      return config;
    }
    case RECEIVE_STREAM_TOKEN: {
      const { token } = extras.data;
      return { ...state, STREAM_TOKEN: token };
    }
    case RESET_STREAM_TOKEN: {
      return { ...state, STREAM_TOKEN: undefined };
    }
    default:
      return state;
  }
};
