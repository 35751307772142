import { useState } from "react";
import { Decider as DeciderComponent } from "@ruthless/component-library";

const Decider = ({ onValueChange }) => {
  const [state, setState] = useState(null);

  const handleValueChange = (choice) => {
    setState(choice);
    onValueChange?.(choice);
  };

  return <DeciderComponent onValueChange={handleValueChange} value={state} />;
};

export default Decider;
