import { useEffect } from "react";
import Hostplaceholder from "../../../ui/components/Hostplaceholder";
import Screen4849Vid from "../../../../public/video/screen-48-49.mp4";
import { useDispatch } from "react-redux";
import { setCurrentScreen } from "../../../ui/uiSlice";
import { SCREENS, SPLASH_SCREENS } from "../../../globals";
import useSplashScreen from "../../../shared/hooks/useSplashScreen";
import { VideoPlaceholder } from "../../../ui/components/VideoManager";

const TheBigRevealIntro = () => {
  const dispatch = useDispatch();
  // const { showSplashScreen } = useSplashScreen();

  useEffect(() => {
    console.log("big reveal intro - screen 48 - 49");
    dispatch(setCurrentScreen(SCREENS.BIG_REVEAL_INTRO));
    // showSplashScreen(SPLASH_SCREENS.RUTHLESS_REVEAL);

    return () => {
      dispatch(setCurrentScreen(null));
    };

    // const timeout = setTimeout(() => {
    //   setBigRevealIntroLoaded(true);
    // }, 9500);

    // return () => clearTimeout(timeout);
  }, []);

  return <VideoPlaceholder src={Screen4849Vid} required={false} />;
  // return <Hostplaceholder clip={Screen4849Vid} required={false} />;
};

export default TheBigRevealIntro;
