import BackgroundMusic from "./BackgroundMusic";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setMute, setEffectsVolume, setBackgroundVolume, setPlaybackRate } from "../../reducer";
import SoundEffects from "./SoundEffects";

const SoundWrapper = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBackgroundVolume(0.2));
    dispatch(setEffectsVolume(1));
    dispatch(setMute(false));
    dispatch(setPlaybackRate(1));
  }, []);

  return (
    <>
      <BackgroundMusic />
      <SoundEffects />
    </>
  );
};

export default SoundWrapper;
