import "@babel/polyfill";

import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./src/styles/styles.css";

import App from "./App";
import { FirebaseProvider } from "./src/shared/hooks/useFirebase";

const config = { ...window.__REACT_APP_CONFIG__ };
console.log("Config data before deletion:", config);
delete window.__REACT_APP_CONFIG__;
const playerData = Object.freeze({ ...window.__REACT_USER_DATA__ });
console.log("Player data before deletion:", playerData);
delete window.__REACT_USER_DATA__;

hydrate(
  <BrowserRouter>
    <FirebaseProvider config={config}>
      <App config={config} isServerSide={false} playerData={playerData} />
    </FirebaseProvider>
  </BrowserRouter>,
  document.getElementById("container")
);
