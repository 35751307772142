import { PLATFORM } from "./actionTypes";

const initialState = {
	platform: false
}

export default (state = initialState, action) => {
	switch (action.type) {
		case PLATFORM: {
			return { ...state, platform: action.payload };
		}
		default:
			return state;
	}
};