import Hostplaceholder from "../../ui/components/Hostplaceholder";
import PrizeballAvailable from "../../../public/video/prizeball-available.mp4";
import VotedPrizes from "./VotedPrizes";
import WinningPrize from "./WinningPrize";
import PrizeBallReels from "./PrizeBallReels";
import PrizeBallCongrats from "./PrizeBallCongrats";
import PrizeBallClaim from "./PrizeBallClaim";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentWinnersTickets, selectPrizeBallStep } from "../reducer";
import { VideoPlaceholder } from "../../ui/components/VideoManager";
import { Ball } from "@ruthless/component-library";
import { selectCurrentBall, selectGameUuid } from "../../game/gameSlice";
import API from "../../shared/libs/API";

// Step 22, 23 - Ruthless Storyboard V3
const AvailablePrizes = ({ tickets }) => {
  // const dispatch = useDispatch();

  const currentBall = useSelector(selectCurrentBall);
  const currentWinnersTickets = useSelector(selectCurrentWinnersTickets);
  const gameUuid = useSelector(selectGameUuid);
  const ticketUuid = tickets.tickets[0].uuid;
  const amIWinner = currentWinnersTickets.some((ticket) => ticket.uuid === ticketUuid && !ticket.prizeClaimed);

  // const [availPrizesLoaded, setAvailPrizesLoaded] = useState(false);
  // const [votedLoaded, setVotedLoaded] = useState(false);
  // const [prizeBallPrizeLoaded, setPrizeBallPrizeLoaded] = useState(false);
  // const [claimed, setClaimed] = useState(false);
  // const [respin, setRespin] = useState(false);

  const step = useSelector(selectPrizeBallStep);

  const handlePrizeClaim = (onSuccessCallback) => async () => {
    try {
      await API.claimPrizeBallPrize(gameUuid, ticketUuid);

      onSuccessCallback?.();
    } catch (error) {
      console.error("Error claiming prize ball prize", error);
    }
  };

  // !availPrizesLoaded
  if (step === "available_prizes_intro_Screen") {
    //return <Hostplaceholder clip={PrizeballAvailable} required={false} />;
    console.log('STEP: "available_prizes_intro_Screen"');
    return (
      <>
        <Ball variant="cashQualifier" className="z-10 w-[175px] short:w-[210px] -top-10" number={currentBall} color="gold" />
        <VideoPlaceholder
          src={PrizeballAvailable}
          required={false}
          // onVideoEnd={() => {
          //   console.log("VideoPlaceholder: AvailablePrizes");
          //   //setAvailPrizesLoaded(true);
          //   dispatch(setCurrentScreen(null));
          // }}
          // duration={34000}
        />
      </>
    );
  }

  if (step === "voted_prizes_screen") {
    console.log('STEP: "voted_prizes_screen"');
    return <VotedPrizes />;
  }

  if (step === "pre_reels_spin_screen") {
    console.log('STEP: "pre_reels_spin_screen"');
    return <WinningPrize />;
  }

  // show reels if user is not a winner
  if (step === "reels_spin_screen" || (step === "claim_prize_screen" && !amIWinner)) {
    return <PrizeBallReels />;
  }

  // all prize ball winners go to claim prize screen
  if (step === "claim_prize_screen" && amIWinner) {
    return <PrizeBallClaim handlePrizeClaim={handlePrizeClaim} />;
  }

  if (step === "congrats_screen") {
    return <PrizeBallCongrats />;
  }

  return null;
};

export default AvailablePrizes;
