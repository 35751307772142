import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "../../../ui/components/Chart";
import AdScreen from "./AdScreen";
import animationData from "../../../../public/lotte/golden-confetti.json";
import { StealShare } from "@ruthless/component-library";
import { useLottie } from "lottie-react";
import { setCurrentScreen } from "../../../ui/uiSlice";
import { SCREENS } from "../../../globals";
import { selectAudienceRuthlessChoices, selectAudienceRuthlessChoicesForWinners } from "../../reducer";
import useDelay from "../../../shared/hooks/useDelay";

const AudienceVotingResult = ({ type, isWinner, progress }) => {
  const { View, goToAndPlay } = useLottie({ animationData, loop: false, autoplay: false, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } });

  const handleAnimationComplete = () => isWinner && goToAndPlay(0, false);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative">
        <div className="absolute inset-0 scale-150">{View}</div>
        <div className="w-full border border-[#E4A400] p-2 rounded-[32px] shadow-[0px_0px_32px_#FFC123] space-y-2 text-white text-xl font-bold stroke-[#679EF2] fill-transparent bg-pdm-blues-darkest">
          <StealShare variant={type} className="capitalize">
            {type}
          </StealShare>
        </div>
      </div>

      <Chart color={type === "share" ? "green" : "red"} progress={progress} onAnimationComplete={handleAnimationComplete} />
    </div>
  );
};

const VotingResults = () => {
  const delay = useDelay();
  const dispatch = useDispatch();

  const audienceRuthlessChoices = useSelector(selectAudienceRuthlessChoices);
  const audienceRuthlessChoicesForWinners = useSelector(selectAudienceRuthlessChoicesForWinners);

  const [firstLoaded, setFirstLoaded] = useState(false);
  const [showAd, setShowAd] = useState(false);

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.VOTING_RESULTS));

    delay(5000).then(() => {
      setFirstLoaded(true);
    });

    delay(10000).then(() => {
      setShowAd(true);
    });
  }, []);

  const calculateAudienceTotalVotes = () => {
    const total = audienceRuthlessChoices.shareTotal + audienceRuthlessChoices.stealTotal;

    if (isNaN(total) || total === 0) {
      console.warn("Encountered total of 0 or total is not a number", total);
      return { sharePercentage: 0, stealPercentage: 0, winner: false };
    }

    const sharePercentage = (audienceRuthlessChoices.shareTotal / total) * 100;
    const stealPercentage = (audienceRuthlessChoices.stealTotal / total) * 100;

    return {
      sharePercentage: Math.round(sharePercentage * 10) / 10,
      stealPercentage: Math.round(stealPercentage * 10) / 10,
      winner: sharePercentage === stealPercentage ? false : sharePercentage > stealPercentage ? "share" : "steal",
    };
  };

  if (!showAd && firstLoaded) {
    const { winner, sharePercentage, stealPercentage } = calculateAudienceTotalVotes();

    return (
      <div className="w-full ">
        <div className="text-center text-white text-2xl font-bold mt-2 md:w-3/4 md:mx-auto">It’s time to find out what our audience would do!</div>

        <div className="flex items-center justify-center mt-12 space-x-10">
          <AudienceVotingResult type="share" isWinner={winner === "share"} progress={sharePercentage} />
          <AudienceVotingResult type="steal" isWinner={winner === "steal"} progress={stealPercentage} />
        </div>
      </div>
    );
  }

  if (showAd && firstLoaded) {
    return <AdScreen />;
  }

  return (
    <div className="w-full">
      <div className="text-center text-white text-2xl font-bold mt-2 md:w-3/4 md:mx-auto">What did our audience think our contestants would do?</div>

      <div className="flex items-center justify-between mt-12">
        {Object.keys(audienceRuthlessChoicesForWinners).map((ticketUuid, index) => {
          const { shareTotal, stealTotal, playerAlias } = audienceRuthlessChoicesForWinners[ticketUuid];

          const sumVotes = shareTotal + stealTotal;

          const chartColor = () => {
            if (shareTotal > stealTotal) {
              return "green";
            }
            if (stealTotal > shareTotal) {
              return "red";
            }

            if (shareTotal === shareTotal) {
              return "gold";
            }
          };

          const majorVotes = stealTotal >= shareTotal ? stealTotal : shareTotal;

          const progressPercentage = majorVotes !== 0 ? Math.round((majorVotes / sumVotes) * 100) : 0;
          const roundedPercentage = Math.round(progressPercentage * 10) / 10;

          return (
            <div key={index} className="h-full flex flex-col items-center justify-center text-white flex-1">
              <div className="break-all px-3 text-center h-12 flex justify-center items-center">{playerAlias}</div>
              <Chart color={chartColor()} progress={roundedPercentage} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VotingResults;
