import { createSlice } from "@reduxjs/toolkit";

const host = createSlice({
  name: "host",
  initialState: {
    isLive: false,
    audioOnly: false,
    isLiveHostVisible: false,
  },
  reducers: {
    setIsLive(state, action) {
      state.isLive = action.payload;
    },
    setAudioOnly(state, action) {
      state.audioOnly = action.payload;
    },
    setIsLiveHostVisible: (state, action) => {
      state.isLiveHostVisible = action.payload;
    },
  },
});

// Selectors
export const selectIsLiveHostVisible = (state) => state.host.isLiveHostVisible;

export const { setIsLive, setAudioOnly, setIsLiveHostVisible } = host.actions;

export default host.reducer;
