import { useState, useEffect, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { selectRecentLineWinners } from "../gameSlice";
import BingoTickets from "./BingoTickets";

const Ticket = ({ ticket, ticketData, ballHistory }) => {
  const [ballsToGoInRows, setBallsToGoInRows] = useState([5, 5, 5]);
  const [parsedTicketData, setParsedTicketData] = useState();

  const recentWinners = useSelector(selectRecentLineWinners);

  useEffect(() => {
    setParsedTicketData(JSON.parse(ticketData));
  }, [ticketData]);

  /**
   * When ballHistory updates, find the intersection of ballHistory and our ticket's numbers
   * store in numToGo indexed by ticket row
   */
  useEffect(() => {
    if (!parsedTicketData) return;
    if (!ballHistory || ballHistory.length === 0) return;

    let newNumsToGo = [];
    parsedTicketData.numbers.map((numberRow, rowIndex) => {
      const intersection = ballHistory.filter((x) => numberRow.includes(x));
      newNumsToGo[rowIndex] = 5 - intersection.length;
    });

    setBallsToGoInRows(newNumsToGo);
  }, [ballHistory, parsedTicketData]);

  const getCurrentRoundFromWinners = () => {
    const { type } = recentWinners;

    return type === "fullHouse" ? "Game Over" : type === "twoLine" ? "Round 3" : type === "oneLine" ? "Round 2" : "Round 1";
  };

  /** Returns information about how many balls are left to go for the current round/prize */
  const getNumberOfRemainingBalls = () => {
    const round = getCurrentRoundFromWinners();

    switch (round) {
      case "Round 3": {
        const num2g = ballsToGoInRows.reduce((acc, val) => (acc += val));

        return Math.min(5, num2g);
      }
      case "Round 2": {
        const twoLineCombinations = [ballsToGoInRows[0] + ballsToGoInRows[1], ballsToGoInRows[0] + ballsToGoInRows[2], ballsToGoInRows[1] + ballsToGoInRows[2]];

        return Math.min(5, Math.min(...twoLineCombinations));
      }
      case "Round 1": {
        return Math.min(...ballsToGoInRows);
      }
      default: {
        return 5;
      }
    }
  };

  if (!parsedTicketData) return null;

  return (
    <BingoTickets
      remainingNumberOfBalls={getNumberOfRemainingBalls()}
      ballsToGoInRows={ballsToGoInRows}
      currentRound={getCurrentRoundFromWinners()}
      purchaseId={ticket.purchase_id}
      ticketData={parsedTicketData.numbers}
      ballHistory={ballHistory}
    />
  );
};

export default Ticket;
