import splashSvgBlue from "../../../public/img/newAssets/splashBgBlue.svg";
import splashSvgRed from "../../../public/img/newAssets/splashBgRed.svg";
import { Frame, Logo } from "@ruthless/component-library";
import { motion } from "framer-motion";
import { SPLASH_SCREENS } from "../../globals";

const SplashScreen = ({ currentSplashScreen }) => {
  const renderCurrentScreen = () => {
    switch (currentSplashScreen) {
      case SPLASH_SCREENS.PRE_GAME:
        return <Logo variant="large" showLights showGradient className="z-10" />;
      case SPLASH_SCREENS.CASH_QUALIFIER_TIME_TO:
        return (
          <div className="text-center">
            <div className="font-bold text-2xl text-[#F54C52] leading-[60px]">It’s time for the</div>
            <div className="text-[122px] leading-[90px] splashScreenText font-pdm-secondary font-bold">CASH</div>
            <div className="text-[64px] leading-[50px] splashScreenText font-pdm-secondary font-bold">QUALIFIER</div>
          </div>
        );
      case SPLASH_SCREENS.PRIZE_BALL:
        return (
          <div className="text-center">
            <div className="font-bold text-2xl text-[#FFC123] leading-[60px]">It’s time for the</div>
            <div className="text-[122px] leading-[90px] splashScreenText font-pdm-secondary font-bold">PRIZE</div>
            <div className="text-[144px] leading-[105px] splashScreenText font-pdm-secondary font-bold">BALL</div>
          </div>
        );
      case SPLASH_SCREENS.CASH_QUALIFIER_BACK_TO:
        return (
          <div className="text-center">
            <div className="font-bold text-2xl text-[#F54C52] leading-[60px]">It’s back to the</div>
            <div className="text-[122px] leading-[90px] splashScreenText font-pdm-secondary font-bold">CASH</div>
            <div className="text-[64px] leading-[50px] splashScreenText font-pdm-secondary font-bold">QUALIFIER</div>
          </div>
        );
      case SPLASH_SCREENS.ROF:
        return (
          <div className="text-center">
            <div className="font-bold text-2xl text-[#F54C52] leading-[60px]">It’s time for the</div>
            <div className="text-[108px] leading-[85px] splashScreenText font-pdm-secondary font-bold">REELS</div>
            <div className="text-[140px] leading-[100px] splashScreenText font-pdm-secondary font-bold">FATE</div>
          </div>
        );
      case SPLASH_SCREENS.RUTHLESS_TIME_TO_PLAY:
      case SPLASH_SCREENS.RUTHLESS_FINAL:
        return (
          <>
            {currentSplashScreen === SPLASH_SCREENS.RUTHLESS_TIME_TO_PLAY && <div className="absolute top-4 text-white text-2xl font-bold">It’s time to play</div>}
            <div className="text-center">
              <Logo variant="large" showLights={true} />
              <div className="textWithBgClip text-5xl font-bold font-pdm-secondary text-white relative -top-4">THE FINAL</div>
            </div>
          </>
        );
      case SPLASH_SCREENS.RUTHLESS_REVEAL:
        return (
          <div className="text-center">
            <div className="font-bold text-2xl text-[#F54C52] leading-[60px]">It’s time for the</div>
            <div className="text-[200px] leading-[145px] splashScreenText font-pdm-secondary font-bold">BIG</div>
            <div className="text-[85px] leading-[60px] splashScreenText font-pdm-secondary font-bold">REVEAL</div>
          </div>
        );
      default:
        return null;
    }
  };

  const isRuthlessSplash = currentSplashScreen === SPLASH_SCREENS.RUTHLESS_TIME_TO_PLAY || currentSplashScreen === SPLASH_SCREENS.RUTHLESS_FINAL;

  return (
    <motion.div
      className="fixed inset-0 z-[100] py-3 xs:py-6 px-3 xs:px-6 md:px-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ background: isRuthlessSplash ? "radial-gradient(102.82% 50% at 50% 50%, #931B37 0%, #100306 100%)" : "radial-gradient(102.82% 50% at 50% 50%, #0e4aa6 0%, #072552 100%)" }}
    >
      <div className="absolute inset-0" style={{ background: `url(${isRuthlessSplash ? splashSvgRed : splashSvgBlue}) center center no-repeat` }} />

      <div className="container h-full flex flex-col">
        <Frame className="flex flex-col flex-1">
          <div className="flex flex-col justify-center items-center h-full">{renderCurrentScreen()}</div>
        </Frame>
      </div>
    </motion.div>
  );
};

export default SplashScreen;
