import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useNextGame from "../hooks/useNextGame";
import { setTrack } from "../../sound/reducer";
import PrePurchaseScreen from "../../pregame/components/PrePurchaseScreen";
import { withRouter } from "react-router-dom";
import { useGetScheduleQuery } from "../../api/api";
import { selectOperator } from "../../session/sessionSlice";
import Reminder from "../../pregame/components/Reminder";

const PreGamePurchase = ({ match }) => {
  // const countDown = useRef(null);
  const dispatch = useDispatch();

  //const { data: rooms } = useGetRoomsQuery(operator);
  const operator = useSelector(selectOperator);
  const { data: schedules } = useGetScheduleQuery({ operator, scheduleIds: [match.params.roomId] });

  // const [loadingTicketType, ticketType] = useTicketType(isLoadingSchedules, schedules);
  // const { data: ticketTypes, isLoading: isLoadingTicketTypes } = useGetTicketTypesForScheduleIdQuery({ schedules }, { skip: !schedules });
  const [nextGameStart, numTicketsPurchased] = useNextGame(match.params.roomId, operator, false);

  const [hasPurchasedTicket, setHasPurchasedTicket] = useState(false);
  const [aboutToStart, setAboutToStart] = useState(false);
  // const [maxTickets, setMaxTickets] = useState(0);
  //const [numTicketsCached, setNumTicketsCached] = useState(0);
  //const [numTicketsToBuy, setNumTicketsToBuy] = useState(1);
  //const [joinButton, setJoinButton] = useState(false);
  //const [allowedToBuy, setAllowedToBuy] = useState(false);
  //const [cutoffSeconds, setCutOffSeconds] = useState(10);
  //const [confirming, setConfirming] = useState(false);
  // const [currency, setCurrency] = useState();

  //const joinReadyAnimation = useAnimation();
  // const matchingTicketType = ticketTypes?.find((ticketType) => ticketType.id === nextGameStart?.id);

  // function handleNumTicketsChange(newValue) {
  //   setNumTicketsToBuy(newValue);
  // }

  // useEffect(() => {
  //   if (nextGameStart) {
  //     setMaxTickets(nextGameStart.schedule.max_tickets_per_user);
  //   }
  // }, [nextGameStart]);

  // useEffect(() => {
  //   if (nextGameStart !== undefined && countDown.current) {
  //     countDown.current.start();
  //     // if (countDown.current.calcTimeDelta().total / 1000 > cutoffSeconds) {
  //     //   setAllowedToBuy(true);
  //     // }
  //   }
  // }, [nextGameStart, countDown]);

  // useEffect(() => {
  //   if (!isLoadingTicketTypes && schedules && nextGameStart && matchingTicketType) {
  //     setCurrency(matchingTicketType.currency.code);
  //   }
  // }, [isLoadingTicketTypes, nextGameStart]);

  // useEffect(() => {
  //   if (tickets && schedules && nextGameStart) {
  //     const gameKey = getKey(match.params.roomId, nextGameStart.schedule.id, nextGameStart.date.toString(), nextGameStart.schedule.ticket_type_id);

  //     const roomGames = tickets.tickets[gameKey];
  //     if (roomGames) {
  //       const { numTickets } = tickets.tickets[gameKey];
  //       setNumTicketsCached(numTickets);
  //     }
  //   }
  // }, [tickets, nextGameStart]);

  useEffect(() => {
    // setBgMusic to sbb-music-slowmotion
    dispatch(setTrack("sbb-music-slowmotion"));
  }, []);

  // const onCountdownTick = (data) => {
  //   if (data.total / 1000 <= cutoffSeconds && allowedToBuy) {
  //     setAllowedToBuy(false);
  //   } else if (countDown.current.calcTimeDelta().total / 1000 > cutoffSeconds) {
  //     setAllowedToBuy(true);
  //   }
  // };

  // const setJoinable = () => {
  //   if (numTicketsCached > 0) {
  //     // If we have tickets, set to join  button
  //     dispatch(playEffect("deep-tone"));
  //     joinReadyAnimation.start({
  //       scale: [1, 1.05, 1.1, 1.15, 1.2, 1.2, 1.15, 1.1, 1.05, 1],
  //       opacity: [1, 0.8, 1, 0.8, 1],
  //     });
  //     setJoinButton(true);
  //   } else {
  //     // If no tickets, restart timer on next scheduled game (after 1s)
  //     setJoinButton(false);
  //     // setRefreshTimeout(
  //     //   setTimeout(() => {
  //     //     setRefresh(true);
  //     //   }, 1000)
  //     // );
  //   }
  // };

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(refreshTimeout);
  //   };
  // }, [refreshTimeout]);

  // const handleGameJoin = () => {
  //   if (nextGameStart) {
  //     history.push({
  //       pathname: `/game_room/${match.params.roomId}`,
  //       state: {},
  //     });
  //     //onJoinGame(match.params.roomId, nextGameStart.date.getTime());
  //   }
  // };

  if (!schedules) return null;
  if (!nextGameStart) return null;
  // if (!matchingTicketType) return null;

  return (
    <>
      {hasPurchasedTicket ? (
        <Reminder
          scheduleTime={nextGameStart.date.toDate().toString()}
          roomId={match.params.roomId}
          // joinGame={joinGame}
          setAboutToStart={setAboutToStart}
          aboutToStart={aboutToStart}
          //allowedToBuy={allowedToBuy}
          //countDown={countDown}
          //onTick={onTick}
          //setJoinable={setJoinable}
          //cutoffSeconds={cutoffSeconds}
          nextGameStart={nextGameStart}
          numTicketsPurchased={numTicketsPurchased}
        />
      ) : (
        <PrePurchaseScreen
          numTicketsPurchased={numTicketsPurchased}
          nextGameStart={nextGameStart}
          setHasPurchasedTicket={setHasPurchasedTicket}
          room={{ id: match.params.roomId }}
          //allowedToBuy={allowedToBuy}
          //cutoffSeconds={cutoffSeconds}
          //setJoinable={setJoinable}
          //onTick={onCountdownTick}
          //countDown={countDown}
          // ticketType={matchingTicketType}
          //numTicketsToBuy={1}
          //setConfirming={setConfirming}
          //handleGameJoin={handleGameJoin}
        />
      )}
    </>
  );
};

export default withRouter(PreGamePurchase);
