import { createContext, useContext, useEffect, useRef, useState } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children, config }) => {
  const [envName, setEnvName] = useState(null);
  const [database, setDatabase] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!config) return;

    const firebaseApp = initializeApp({
      databaseURL: config.FIREBASE_DATABASE_URL,
      apiKey: config.FIREBASE_API_KEY,
      authDomain: config.FIREBASE_AUTH_DOMAIN,
      projectId: config.FIREBASE_PROJECT_ID,
      storageBucket: config.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
      appId: config.FIREBASE_APP_ID,
      measurementId: config.FIREBASE_MEASUREMENT_ID,
    });

    const db = getDatabase(firebaseApp);
    const auth = getAuth();
    const analytics = getAnalytics();

    signInAnonymously(auth)
      .then(() => {
        console.log("signed in");
      })
      .catch((e) => {
        console.error(e);
        throw new Error("Failed to sign into Firebase");
      });

    setEnvName(config.FIREBASE_ENV_NAME);
    setDatabase(db);
    setAnalytics(analytics);
    setIsInitialized(true);
  }, []);

  return <FirebaseContext.Provider value={{ envName, database, analytics, isInitialized }}>{children}</FirebaseContext.Provider>;
};

const useFirebase = () => {
  const context = useContext(FirebaseContext);

  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }

  /**
   * Custom wrapper around firebase onValue.
   * @param key - The key to listen to
   * @param onValueCallback - The callback to call when the value changes
   * @param customRef - (Optional) A custom ref to use instead of the default database ref. Useful for querying.
   */
  const onValueListener = (key, onValueCallback, customRef) => {
    const { isInitialized, database } = context;

    if (!isInitialized) return;

    const unsubscribe = onValue(customRef ?? ref(database, key), onValueCallback);

    return unsubscribe;
  };

  return { firebase: context, onValueListener };
};

export default useFirebase;
