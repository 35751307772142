import { motion } from "framer-motion";
import { useEffect } from "react";
import OopsPNG from "../../../public/img/emoji/1F9B9-1F3FC-200D-2642-FE0F.png";

import { useDispatch } from "react-redux";
import { playEffect } from "../../sound/actions";

const errorParentVar = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
    transition: {
      type: "tween",
    },
  },
};

const errorImgVar = {
  from: {
    scale: 0,
    opacity: 0,
  },
  to: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

const Error = ({ error, resetErrorBoundary }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(playEffect("sbb-fx-beep-sharpstring"));
  }, []);

  return (
    <motion.div
      variants={errorParentVar}
      initial="from"
      animate="to"
      className={`fixed top-0 bottom-0 flex items-center left-0 right-0 z-40 w-full mx-auto pb-16 bg-pdm-main-modal bg-opacity-90`}
      style={{ backdropFilter: "blur(9px)" }}
    >
      <div className="mx-auto relative flex flex-col justify-between items-center w-10/12 max-w-md bg-pdm-main-white rounded-xl">
        <div className="absolute right-5 top-3">
          <button
            onClick={resetErrorBoundary}
            className="ml-4 bg-gradient-to-t my-1 focus:outline-none from-pdm-button-secondary-top to-pdm-button-secondary-bottom rounded-full w-7 md:h-7 text-pdm-main-white font-bold text-lg"
          >
            X
          </button>
        </div>
        <div className="mt-12">
          <motion.img variants={errorImgVar} initial="from" animate="to" src={OopsPNG} alt="Oops" />
        </div>
        <div>
          <h1 className="mx-auto text-pdm-main-base text-2xl font-bold p-3 mt-6">Oh no!</h1>
        </div>
        <div className="mx-auto p-3 w-3/4 text-center">Something went wrong. Our cyber-shamans will look into this precognitively. Please try again later.</div>
        <div>{error.toString()}</div>
        <button onClick={resetErrorBoundary} className="button-primary h-14 w-3/4 m-8">
          :(
        </button>
      </div>
    </motion.div>
  );
};

export default Error;
