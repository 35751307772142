import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Container, Input, CTA, Decider } from "@ruthless/component-library";

import { chooseNameScreenCntr } from "../animations";
import { useDispatch } from "react-redux";
import { getDefaultChoices } from "../actions";
import { PulseLoader } from "react-spinners";
import API from "../../shared/libs/API";
import { RECEIVE_TOKEN } from "../../config/actionTypes";
import { letsBegin, setName } from "../reducer";

const ChooseNameScreen = ({ active }) => {
  const [chatName, setChatName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasDefaultRuthlessChoice, setHasDefaultRuthlessChoice] = useState(false);
  const [ruthlessChoice, setRuthlessChoice] = useState(null);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await API.getDefaultChoice();

      setHasDefaultRuthlessChoice(!!data.ruthlessChoice);
      setIsLoading(false);
    } catch (error) {
      console.error("Could not get default choice", error);
    }
  };

  const handleContinue = async () => {
    try {
      const promises = [API.postUserName(chatName)];

      if (!hasDefaultRuthlessChoice) {
        promises.push(API.postDefaultRuthlessChoice(ruthlessChoice));
      }

      const [postUsernameResponse] = await Promise.all(promises);

      dispatch({
        type: RECEIVE_TOKEN,
        token: postUsernameResponse.data.locals.accessToken,
      });
      dispatch(setName({ name: chatName }));
      dispatch(letsBegin());
    } catch (error) {
      console.error(error);
      setError("Something went wrong.");
    }
  };

  if (!active) return null;

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={`modal-content h-full w-full`}>
      <div className="flex flex-col items-center justify-start h-full mx-auto">
        <div className="mb-6 text-white text-center">Please enter your chat name and define your default choice for Ruthless</div>
        <Container className="p-6 flex flex-col justify-center items-center space-y-4 rounded-2xl">
          <Input placeholder="Chat Name" variant="default" id="chatName" value={chatName} onChange={(e) => setChatName(e.target.value)} error={error} />

          {isLoading ? (
            <div className="w-full flex items-center justify-center">
              <PulseLoader size={30} color={"#B0F716"} loading={isLoading} />
            </div>
          ) : (
            <>
              {!hasDefaultRuthlessChoice && <Decider onValueChange={(choice) => setRuthlessChoice(choice)} value={ruthlessChoice} />}
              <CTA disabled={chatName.length < 3 || !ruthlessChoice} onClick={handleContinue}>
                Continue
              </CTA>
            </>
          )}
        </Container>
      </div>
    </motion.div>
  );
};

ChooseNameScreen.propTypes = {};
ChooseNameScreen.defaultProps = {};

export default ChooseNameScreen;
