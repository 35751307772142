import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
// import { setIsRuthlessFinal } from "../../ruthlessFinal/reducer";
import GameOutcomeVid from "../../../public/video/screen37-38.mp4";
import Hostplaceholder from "../../ui/components/Hostplaceholder";
import animationData from "../../../public/lotte/golden-confetti.json";
import confettiCelebration from "../../../public/lotte/18353-confetti-celebration.json";
import { AnimatePresence, motion } from "framer-motion";
import { useLottie } from "lottie-react";

import { Container, Logo, Reels, StealShare } from "@ruthless/component-library";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { useMediaQuery } from "react-responsive";
import WinnersContainer from "../../ui/components/WinnersContainer";
import { VideoPlaceholder } from "../../ui/components/VideoManager";
import { selectPlayerAliases } from "../../game/gameSlice";
import useDelay from "../../shared/hooks/useDelay";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 1,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const choiceVariants = {
  hidden: {
    opacity: 0,
    x: 50,
    scale: 1,
  },
  show: {
    opacity: 1,
    x: 0,
  },
  exit: { scale: 0 },
};

// eslint-disable-next-line react/display-name
const SelectedWinner = forwardRef(({ currentWinner, index, winner, example }, ref) => {
  const { View, goToAndPlay } = useLottie({ animationData, loop: false, autoplay: false, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } });

  useImperativeHandle(ref, () => ({
    play: () => goToAndPlay(0, false),
  }));

  return (
    <Container className="rounded-2xl p-3 short:p-4 flex justify-between items-center" color={currentWinner === index || currentWinner === "all" ? "gold" : ""}>
      <div className="break-all relative">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-60 h-40">{View}</div>
        <motion.div initial={{ color: "#ffffff" }} animate={{ color: index === currentWinner || currentWinner === "all" ? "#FFD362" : "#ffffff" }}>
          {winner}
        </motion.div>
      </div>
      <div className="flex bg-pdm-blues-darkest p-2 rounded-[48px]">
        <StealShare id={`share-${index ?? ""}-${example === "share"}`} variant={example === "share" ? "share" : ""} variants={choiceVariants} initial="hidden" animate="show" exit="exit">
          Share
        </StealShare>

        <StealShare id={`steal-${index ?? ""}-${example === "steal"}`} variant={example === "steal" ? "steal" : ""} variants={choiceVariants} initial="hidden" animate="show" exit="exit">
          Steal
        </StealShare>
      </div>
    </Container>
  );
});

const GameOutcomes = () => {
  const delay = useDelay();

  const isTallScreen = useMediaQuery({ query: fullConfig.theme.screens.short.raw });
  const isMobile = useMediaQuery({ query: "(max-device-width: 640px)" });

  const playerAliases = useSelector(selectPlayerAliases);

  const stageMap = [
    { choices: ["steal", "", ""], winnerIndex: null, nextStageDelay: 500 },
    { choices: ["steal", "share", ""], winnerIndex: null, nextStageDelay: 500 },
    { choices: ["steal", "share", "share"], winnerIndex: 0, nextStageDelay: 3000, winnerDelay: 3000 },
    { choices: ["share", "share", "share"], winnerIndex: "all", nextStageDelay: 5000, winnerDelay: 3000 },
    { choices: ["share", "steal", "steal"], winnerIndex: 0, winnerDelay: 5000 },
  ];

  const reelsRef = useRef();
  const winnerRefs = [useRef(null), useRef(null), useRef(null)];

  const [stage, setStage] = useState(null);
  const [examples, setExamples] = useState(["", "", ""]);
  const [currentWinner, setCurrentWinner] = useState(null);
  const [showReel, setShowReel] = useState(false);
  const [showWinner, setShowWinner] = useState(false);

  const [{ selectedWinners, finalWinner }, setSelectedWinners] = useState({ selectedWinners: [], finalWinner: "" });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("game outcomes - screen 37-38");
    dispatch(setCurrentScreen(SCREENS.ROF_GAME_OUTCOMES));

    setSelectedWinners(getWinners());
  }, []);

  const getWinners = () => {
    const NUMBER_OF_WINNERS = 3;
    const playerAliasesPool = [...playerAliases];
    const winners = [];

    const randomIndex = Math.floor(Math.random() * playerAliasesPool.length);

    for (let i = 0; i < NUMBER_OF_WINNERS; i++) {
      const winner = playerAliasesPool.splice(randomIndex, 1).pop();

      if (!winner) console.error("Could not select random winner.");

      winners.push(winner);
    }

    return { selectedWinners: winners, finalWinner: playerAliasesPool[randomIndex] };
  };

  const sequence = async () => {
    const { choices, winnerIndex, nextStageDelay, winnerDelay } = stageMap[stage];
    const DEFAULT_DELAY = 2000;

    setExamples(choices);

    if (winnerIndex !== null) {
      await delay(winnerDelay ?? DEFAULT_DELAY);
      setCurrentWinner(winnerIndex);

      if (winnerIndex === "all") {
        for (const winnerRef of winnerRefs) {
          winnerRef.current?.play();
        }
      } else {
        winnerRefs[winnerIndex].current?.play();
      }
    }

    if (stage < stageMap.length - 1) {
      await delay(nextStageDelay ?? DEFAULT_DELAY);

      setStage(stage + 1);
      setCurrentWinner(null);

      return;
    }

    await delay(5000);
    setShowReel(true);
  };

  const spinReel = async () => {
    await delay(4000);
    reelsRef.current.spin?.();
  };

  const handleSpinComplete = async () => {
    await delay(4000);
    setShowWinner(true);
  };

  useEffect(() => {
    if (stage !== null) {
      sequence();
    }
  }, [stage]);

  useEffect(() => {
    if (showReel) {
      spinReel();
    }
  }, [showReel]);

  return (
    <>
      <div className="flex flex-col h-full relative z-[1]">
        <div className="flex flex-col items-center relative -top-16">
          <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.5 }}>
            <Logo variant="large" showLights={true} width={isTallScreen ? null : "250px"} height={isTallScreen ? null : "180px"} />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
            className="textWithBgClip text-4xl short:text-5xl font-bold font-pdm-secondary text-white relative -top-2 short:-top-4"
          >
            THE FINAL
          </motion.div>
        </div>
        {/* <Hostplaceholder clip={GameOutcomeVid} required={false} /> */}

        <AnimatePresence exitBeforeEnter>
          {showReel ? (
            showWinner ? (
              <motion.div key="showWinner" variants={item} initial="hidden" animate="show" exit={{ opacity: 0 }} className="relative w-full md:w-3/4 mx-auto mt-8">
                <WinnersContainer winners={[finalWinner]} />
              </motion.div>
            ) : (
              <motion.div key="showReel" className="mt-auto text-white flex flex-col items-center space-y-1 short:space-y-2" variants={item} initial="hidden" animate="show" exit={{ opacity: 0 }}>
                <Container className="rounded-2xl p-4 flex justify-between items-center w-full">
                  <div className="break-all relative">
                    <div>{finalWinner}</div>
                  </div>
                  <div className="flex bg-pdm-blues-darkest p-2 rounded-[48px]">
                    <StealShare variant="share" variants={choiceVariants} initial="hidden" animate="show" exit="exit">
                      Share
                    </StealShare>

                    <StealShare variants={choiceVariants} initial="hidden" animate="show" exit="exit">
                      Steal
                    </StealShare>
                  </div>
                </Container>
                <div className="text-xl font-bold">+</div>
                <Reels
                  variant={isMobile ? "small" : "large"}
                  ref={reelsRef}
                  animationData={confettiCelebration}
                  items={playerAliases}
                  winners={[null, finalWinner, null]}
                  lockedReels={[1, 3]}
                  onSpinComplete={handleSpinComplete}
                />
              </motion.div>
            )
          ) : (
            <motion.div
              key="showChoices"
              className="mt-auto mb-2 space-y-3 text-white"
              variants={container}
              initial="hidden"
              animate="show"
              exit={{ opacity: 0 }}
              onAnimationComplete={async () => {
                await delay(2000);
                setStage(0);
              }}
            >
              {selectedWinners.map((winner, index) => (
                <motion.div key={winner} variants={item}>
                  <SelectedWinner ref={winnerRefs[index]} currentWinner={currentWinner} index={index} example={examples[index]} winner={winner} />
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <VideoPlaceholder
        src={GameOutcomeVid}
        required={false}
        // onVideoEnd={() => {
        //   console.log("VideoPlaceholder: GameOutcomes");
        //   showSplashScreen(SPLASH_SCREENS.RUTHLESS_FINAL, () => {
        //     // dispatch(setIsRuthlessFinal(true));
        //   });
        // }}
        // duration={55500}
      />
    </>
  );
};

export default GameOutcomes;
