import { useState } from "react";
import useInterval from "use-interval";
import ClaimModal from "../../shared/components/ClaimModal";
import { useSelector } from "react-redux";
import { selectPrizeData, selectRuthlessClaimPrizeTime } from "../reducer";
import API from "../../shared/libs/API";
import { selectGameUuid } from "../../game/gameSlice";

const RuthlessClaimPrize = ({ tickets }) => {
  const ruthlessClaimPrizeTime = useSelector(selectRuthlessClaimPrizeTime);
  const [count, setCount] = useState(ruthlessClaimPrizeTime / 1000);
  const ruthlessPrizeData = useSelector(selectPrizeData);
  const gameUuid = useSelector(selectGameUuid);

  useInterval(() => {
    setCount((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
    });
  }, 1000);

  const handlePrizeClaim = async () => {
    try {
      await API.claimRuthlessPrize(gameUuid, tickets[0].uuid);
    } catch (error) {
      console.error(error);
    }
  };

  return <ClaimModal count={count} winningPrize={ruthlessPrizeData.prizeValue} onClaimButton={handlePrizeClaim} />;
};

export default RuthlessClaimPrize;
