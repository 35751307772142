import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentScreen, setCurrentSection } from "../../../ui/uiSlice";
import { SCREENS, SECTIONS } from "../../../globals";
import GameSponsors from "../GameSponsors";
import { PaleLights } from "@ruthless/component-library";
import { useLottie } from "lottie-react";
import { selectCurrentWinnersTickets, selectPrizeData } from "../../reducer";
import confettiCelebration from "../../../../public/lotte/18353-confetti-celebration.json";
import { motion } from "framer-motion";
import ruthlessWinnerBall from "../../../../public/img/newAssets/ruthlessWinnerBall.png";
import useDelay from "../../../shared/hooks/useDelay";

const RuthlessEnding = () => {
  const [isShowingGameSponsors, setIsShowingGameSponsors] = useState(false);

  const { View, goToAndPlay } = useLottie({ animationData: confettiCelebration, loop: false, autoplay: false, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } });

  const currentWinnersTickets = useSelector(selectCurrentWinnersTickets);
  const ruthlessPrizeData = useSelector(selectPrizeData);

  const dispatch = useDispatch();
  const delay = useDelay();

  useEffect(() => {
    dispatch(setCurrentSection(SECTIONS.GAME_END));
    dispatch(setCurrentScreen(SCREENS.RUTHLESS_WINNERS));

    delay(7000).then(() => {
      setIsShowingGameSponsors(true);
    });
  }, []);

  const transformTemplate =
    (x, y) =>
    ({ rotate }) =>
      `translateX(${x}) translateY(${y}) rotate(${rotate}) translateZ(0)`;

  if (isShowingGameSponsors) {
    return <GameSponsors />;
  }

  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <PaleLights strongLight={false} className="absolute top-0 left-[20%]" transformTemplate={transformTemplate("0", "-50%")} />
      <PaleLights strongLight={true} className="absolute top-0 left-[60%]" transformTemplate={transformTemplate("0", "-50%")} />

      <PaleLights strongLight={true} className="absolute bottom-0 left-[20%]" transformTemplate={transformTemplate("0", "50%")} />
      <PaleLights strongLight={false} className="absolute bottom-0 left-[60%]" transformTemplate={transformTemplate("0", "50%")} />

      <PaleLights strongLight={true} className="absolute top-0 left-0" transformTemplate={transformTemplate("-50%", "-50%")} />
      <PaleLights strongLight={false} className="absolute top-[20%] left-0" transformTemplate={transformTemplate("-50%", "-50%")} />
      <PaleLights strongLight={true} className="absolute top-[40%] left-0" transformTemplate={transformTemplate("-50%", "-50%")} />
      <PaleLights strongLight={false} className="absolute top-[60%] left-0" transformTemplate={transformTemplate("-50%", "-50%")} />
      <PaleLights strongLight={true} className="absolute top-[80%] left-0" transformTemplate={transformTemplate("-50%", "-50%")} />
      <PaleLights strongLight={false} className="absolute top-[100%] left-0" transformTemplate={transformTemplate("-50%", "-50%")} />

      <PaleLights strongLight={false} className="absolute top-0 right-0" transformTemplate={transformTemplate("50%", "-50%")} />
      <PaleLights strongLight={true} className="absolute top-[20%] right-0" transformTemplate={transformTemplate("50%", "-50%")} />
      <PaleLights strongLight={false} className="absolute top-[40%] right-0" transformTemplate={transformTemplate("50%", "-50%")} />
      <PaleLights strongLight={true} className="absolute top-[60%] right-0" transformTemplate={transformTemplate("50%", "-50%")} />
      <PaleLights strongLight={false} className="absolute top-[80%] right-0" transformTemplate={transformTemplate("50%", "-50%")} />
      <PaleLights strongLight={true} className="absolute top-[100%] right-0" transformTemplate={transformTemplate("50%", "-50%")} />

      <div className="flex flex-col items-center justify-between w-full h-full relative">
        <div className="absolute inset-0">{View}</div>
        <motion.div className="text-center" initial={{ opacity: 0, y: -150 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
          <div className="font-pdm-primary font-bold text-white uppercase mt-10 leading-[19px]">The Winner Of</div>
          <div className="font-pdm-secondary font-bold text-white uppercase text-[44px] short:text-[64px] leading-[61px] short:leading-[81px] drop-shadow-2xl">{ruthlessPrizeData.prizeValue}</div>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 1 }} className="w-[50%] short:w-full flex justify-center items-center">
          <img src={ruthlessWinnerBall} alt="Ruthless Winner Ball" />
        </motion.div>
        <motion.div
          className="p-5 short:p-10 text-white font-pdm-primary text-xl short:text-3xl font-bold text-center"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: [0, 1.5, 1] }}
          transition={{ delay: 2 }}
          onAnimationComplete={() => goToAndPlay(0, false)}
        >
          {currentWinnersTickets.map((ticket) => ticket.playerAlias).join(", ")}
        </motion.div>
      </div>
    </div>
  );
};

export default RuthlessEnding;
