module.exports = {
  content: ["./src/**/*.js", "./AppFlow.js", "./App.js", "./index.js"],
  plugins: [require("@tailwindcss/forms")],
  mode: "jit",
  theme: {
    important: true,
    screens: {
      xs: "389px",
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
    },
    extend: {
      fontFamily: {
        "pdm-primary": "Raleway",
        "pdm-secondary": "Rajdhani",
      },
      screens: {
        short: { raw: "(min-height: 770px)" },
      },
      colors: {
        "cobalt-blue": {
          900: "#031023",
        },
        "pdm-blues": {
          darkest: "#072149",
        },
      },
    },
  },
};
