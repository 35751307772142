import { useSelector } from "react-redux";
import Decider from "../../../ui/components/Decider";
import { useState } from "react";
import { selectCurrentWinnersTickets } from "../../reducer";

const VoteOnContestants = ({ setTotalVotes, totalVotes }) => {
  const currentWinnersTickets = useSelector(selectCurrentWinnersTickets);

  const handleValueChange = (choice, playerAlias) => {
    setTotalVotes([...totalVotes, { playerAlias, choice }]);
  };

  return (
    <>
      <div className="text-white text-lg font-bold text-center mt-2 mb-10">Vote on what you think our contestants will do!</div>
      <div className="space-y-5 flex flex-col justify-center items-center">
        {currentWinnersTickets.map((player) => {
          return (
            <div key={player.uuid}>
              <div className="text-center text-white text-xs mb-2">{player.playerAlias}</div>
              <Decider onValueChange={(choice) => handleValueChange(choice, player.playerAlias)} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default VoteOnContestants;
