import { AnimateSharedLayout, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { Reels } from "@ruthless/component-library";
import confettiCelebration from "../../../public/lotte/18353-confetti-celebration.json";
import { useMediaQuery } from "react-responsive";
import useSplashScreen from "../../shared/hooks/useSplashScreen";
import { SPLASH_SCREENS } from "../../globals";
import { selectCurrentWinnersTickets } from "../../ruthlessFinal/reducer";
import { selectPlayerAliases } from "../../game/gameSlice";
import useDelay from "../../shared/hooks/useDelay";

const ROF = () => {
  const delay = useDelay();
  const isMobile = useMediaQuery({ query: "(max-device-width: 640px)" });

  const currentWinnersTickets = useSelector(selectCurrentWinnersTickets);
  const playerAliases = useSelector(selectPlayerAliases);

  const reelsRef = useRef();

  useEffect(() => {
    spinReels();
  }, []);

  const spinReels = async () => {
    await delay(2000);
    reelsRef.current.spin?.();
  };

  const winners = currentWinnersTickets.map((player) => player.playerAlias);

  return (
    <div className="h-full w-full relative">
      <div className="flex flex-col w-full items-center">
        <div className="text-white font-bold text-center text-xl mt-2 mb-6">Our finalists</div>
        <Reels variant={isMobile ? "small" : "large"} ref={reelsRef} animationData={confettiCelebration} items={Array.from(new Set([...winners, ...playerAliases]))} winners={winners} />
      </div>
    </div>
  );
};

export default ROF;
