import { motion } from "framer-motion";
import { useState } from "react";
import { Vote } from "@ruthless/component-library";

const parentVar = {
  from: {},
  to: {
    transition: {
      staggerChildren: 0.3,
      staggerDirection: -1,
    },
  },
};

const childVar = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: "anticipate",
    },
  },
};

const Chart = ({ progress, color = "", onAnimationComplete }) => {
  const [complete, setComplete] = useState(false);

  const greenBars = Math.floor(progress / 10);

  const handleAnimationComplete = (value) => {
    const currentValue = greenBars - value;

    if (currentValue === greenBars) {
      setComplete(true);
      onAnimationComplete?.();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="p-0.5 rounded-md mt-3 prizeBallResultsReveal">
        <motion.div variants={parentVar} initial="from" animate="to" className="w-[20px] h-[116px] bg-[#031023] rounded-md flex items-center">
          <div className="relative w-full h-[98px] flex justify-center">
            <div className="absolute space-y-0.5 top-0">
              {Array.from(Array(10).keys()).map((v) => (
                <Vote key={v} filled={false} />
              ))}
            </div>
            <div className="absolute space-y-0.5 bottom-0">
              {Array.from(Array(greenBars).keys()).map((v) => (
                <motion.div key={v} variants={childVar} onAnimationComplete={() => handleAnimationComplete(v)}>
                  <Vote filled={true} color={color} />
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
      <motion.div className="text-white pt-1.5" initial={{ opacity: 0 }} animate={{ opacity: complete || progress === 0 ? 1 : 0 }}>
        {progress}%
      </motion.div>
    </div>
  );
};

export default Chart;
