import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { default as ImageManifest } from "../../../public/img/brand_rooms_static/manifest.json";
import { useWindowSize } from "react-use";

const responsiveImgPath =
  process.env.NODE_ENV !== "development"
    ? "https://cdn.bingosolutions.com/" + process.env.REACT_APP_CDN_SUBDOMAIN + "/public/img/brand_rooms_static/"
    : "http://localhost:8080/img/brand_rooms_static/";

const initial = { opacity: 0 };
const animate = { opacity: 1 };

const ResponsivePicture = ({ imgPath, ...props }) => {
  const [imgSrc, setImgSrc] = useState("");
  const windowSize = useWindowSize();

  useEffect(() => {
    const matchedImage = ImageManifest.filter((v) => v.s.p === imgPath).pop();

    if (matchedImage) {
      const svg = matchedImage.f.filter((v) => v.f === "svg").pop();
      setImgSrc(`${responsiveImgPath}${svg.p}`);

      let size = "xl";
      if (windowSize.width < 320) {
        size = "sm";
      } else if (windowSize.width < 640) {
        size = "md";
      } else if (windowSize.width < 1440) {
        size = "lg";
      }

      if (size === "xl" && matchedImage.f.filter((v) => v.f === "webp" && v.b === size).pop() === undefined) {
        size = "lg";
      }
      const img = matchedImage.f.filter((v) => v.f === "webp" && v.b === size).pop();

      const imageLoader = new Image();
      imageLoader.src = responsiveImgPath + img.p;

      imageLoader.onload = () => {
        setImgSrc(responsiveImgPath + img.p);
      };
    }
  }, [imgPath, windowSize]);

  return <motion.img className={props.className} initial={initial} animate={animate} src={imgSrc} />;
};

export default ResponsivePicture;
