import useDelay from "./useDelay";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const SplashScreenContext = createContext(null);

export const SplashScreenProvider = ({ children }) => {
  const delay = useDelay();

  const [currentSplashScreen, setCurrentSplashScreen] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const durationValue = useRef(3000);
  const onCompleteCallback = useRef(null);

  useEffect(() => {
    if (!isActive) return;

    delay(durationValue.current).then(() => {
      onCompleteCallback.current?.();

      setIsActive(false);
    });
  }, [isActive]);

  const showSplashScreen = (splashName, onSplashComplete, duration = 3000) => {
    if (isActive) return;

    setCurrentSplashScreen(splashName);
    setIsActive(true);

    onCompleteCallback.current = onSplashComplete;
    durationValue.current = duration;
  };

  return <SplashScreenContext.Provider value={{ currentSplashScreen, showSplashScreen, isSplashScreenActive: isActive }}>{children}</SplashScreenContext.Provider>;
};

const useSplashScreen = () => {
  const context = useContext(SplashScreenContext);

  if (!context) {
    throw new Error("useSplashScreen must be used within a SplashScreenProvider");
  }

  return context;
};

export default useSplashScreen;
