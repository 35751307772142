import Hostplaceholder from "../../ui/components/Hostplaceholder";
import CashQEnd from "../../../public/video/cashq-end.mp4";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { VideoPlaceholder, useVideoManager } from "../../ui/components/VideoManager";
import { setTrack } from "../../sound/reducer";

// Sreen 31
const IntroROF = () => {
  const dispatch = useDispatch();

  // useVideoManager(SCREENS.CASH_QUALIFIER_END, () => {
  //   setIntroRof(true);
  // });

  useEffect(() => {
    dispatch(setTrack(null));
    dispatch(setCurrentScreen(SCREENS.CASH_QUALIFIER_END));

    // setTimeout(() => {
    //   setIntroRof(true);
    // }, 34500);

    return () => {
      dispatch(setCurrentScreen(null));
    };
  }, []);

  // useEffect(() => {
  //   dispatch(setWinningPlayers([]));
  //   dispatch(resetReels([false, false, false]));
  // }, []);

  return (
    <VideoPlaceholder
      src={CashQEnd}
      required={false}
      // onVideoEnd={() => {
      //   console.log("VideoPlaceholder: IntroROF");
      //   setIntroRof(true);
      // }}
      // duration={34500}
    />
  );
  // return (
  //   <div className="w-full h-full flex flex-col items-center justify-around">
  //     <Hostplaceholder clip={CashQEnd} required={false} />
  //   </div>
  // );
};

export default IntroROF;
