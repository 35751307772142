import Hostplaceholder from "../../ui/components/Hostplaceholder";
import PrizeballCongrats from "../../../public/video/prizeball-congrats.mp4";
import { useEffect } from "react";
import { selectCurrentWinnersTickets, selectWinningPrizeAndCounts, setIsPrizeBallRound, setPrizeBallRoundFinished, showPrizeBallEndingSplashScreen } from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS, SPLASH_SCREENS } from "../../globals";
import WinnersContainer from "../../ui/components/WinnersContainer";
import useSplashScreen from "../../shared/hooks/useSplashScreen";
import { VideoPlaceholder, useVideoManager } from "../../ui/components/VideoManager";

const PrizeBallCongrats = () => {
  const dispatch = useDispatch();
  const { showSplashScreen } = useSplashScreen();

  const currentWinners = useSelector(selectCurrentWinnersTickets);
  const { winningPrize } = useSelector(selectWinningPrizeAndCounts);

  //const { features } = useSelector((state) => state.rof);
  //const winningPrize = useSelector((state) => state.prizeBall.winningPrizeAndCounts.winningPrize);

  //const prizeBallFeature = features[1];

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.PRIZE_BALL_CONGRATS));

    return () => {
      dispatch(showPrizeBallEndingSplashScreen(true));
    };
  }, []);

  // useEffect(() => {
  //   return () => {
  //     console.log("next game state", gameState);
  //     if (gameState === "cash_qualifier") {
  //       showSplashScreen(SPLASH_SCREENS.CASH_QUALIFIER_BACK_TO);
  //     }
  //   };
  // }, [gameState]);

  // useVideoManager(SCREENS.PRIZE_BALL_CONGRATS, () => {
  //   showSplashScreen(
  //     SPLASH_SCREENS.CASH_QUALIFIER_BACK_TO,
  //     () => {
  //       dispatch(setIsPrizeBallRound(false));
  //       dispatch(setPrizeBallRoundFinished(true));
  //     },
  //     2000
  //   );
  // });

  const getWinnersNames = () => currentWinners.map((winner) => winner.playerAlias);

  return (
    <>
      <div className="fixed inset-0 prizeBallWinnerModal z-[100]">
        <div className="flex flex-col w-3/4 mx-auto mt-16">
          <WinnersContainer winners={getWinnersNames()} title="Prize Ball" />
          <div className="mt-6 text-4xl text-white font-bold text-center">{winningPrize}</div>
        </div>
      </div>
      <VideoPlaceholder
        src={PrizeballCongrats}
        required={false}
        // onVideoEnd={() => {
        //   console.log("VideoPlaceholder: PrizeBallCongrats");
        //   showSplashScreen(
        //     SPLASH_SCREENS.CASH_QUALIFIER_BACK_TO,
        //     () => {
        //       // dispatch(setIsPrizeBallRound(false));
        //       // dispatch(setPrizeBallRoundFinished(true));
        //     },
        //     2000
        //   );
        // }}
        // duration={26000}
      />
    </>
  );
};

export default PrizeBallCongrats;
