import { useEffect, useRef } from "react";

const useDelay = () => {
  const timeoutIds = useRef([]);

  const clearAllTimeouts = () => {
    timeoutIds.current.forEach((id) => clearTimeout(id));
    timeoutIds.current = [];
  };

  useEffect(() => {
    return clearAllTimeouts;
  }, []);

  const delay = async (duration) => {
    let id;

    const promise = new Promise((resolve) => {
      id = setTimeout(resolve, duration);
      timeoutIds.current.push(id);
    });

    return promise.then(() => {
      timeoutIds.current = timeoutIds.current.filter((timeoutId) => timeoutId !== id);
    });
  };

  return delay;
};

export default useDelay;
