import { useRef, useEffect } from "react";
import Countdown from "react-countdown";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { motion } from "framer-motion";

import { setTrack } from "../../sound/reducer";
import Host from "../../host/components/Host";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS, SPLASH_SCREENS } from "../../globals";
import useSplashScreen from "../../shared/hooks/useSplashScreen";
import { useGetNumOfTicketsPurchasedForScheduleIdQuery, useGetScheduleQuery, useGetTicketsForGameQuery } from "../../api/api";
import { withRouter } from "react-router-dom";
import { selectOperator } from "../../session/sessionSlice";
import { setChatRoomId } from "../../chat/chatSlice";
import useHostConnection from "../../host/hooks/useHostConnection";

// TODO get cutoff from db
const cutoffSeconds = 10;

const PreGameRoom = ({ match, history, location }) => {
  const countdownRef = useRef();
  const isSplashCalled = useRef(false);
  const { showSplashScreen } = useSplashScreen();

  const operator = useSelector(selectOperator);

  const roomId = match.params.roomId;

  const dispatch = useDispatch();

  const isHostLive = useSelector((state) => state.host.isLive);

  const urlParams = new URLSearchParams(location.search);

  const { data: schedule } = useGetScheduleQuery({ operator, scheduleIds: [urlParams.get("scheduleId")] });
  const { data: tickets, isLoading: isLoadingTickets } = useGetTicketsForGameQuery(
    { roomId, scheduleId: schedule?.[0].id, scheduleLocale: schedule?.[0].locale, scheduleTime: parseInt(urlParams.get("date"), 10) },
    { skip: !schedule }
  );

  const { data: numTicketsPurchased } = useGetNumOfTicketsPurchasedForScheduleIdQuery(
    {
      roomId,
      scheduleId: schedule?.[0].id,
      scheduleLocale: schedule?.[0].locale,
      date: new Date(parseInt(urlParams.get("date"), 10)),
    },
    { skip: !schedule }
  );

  useHostConnection(roomId, tickets?.tickets?.[0].gameUuid);

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.PRE_GAME_ROOM));
    dispatch(setTrack("whip"));

    countdownRef.current?.start();
    dispatch(setChatRoomId(`${urlParams.get("scheduleId")}::${urlParams.get("date")}`));

    return () => {
      dispatch(setChatRoomId(null));
    };
  }, []);

  const handleJoinGame = () => {
    if (!isSplashCalled.current) {
      showSplashScreen(
        SPLASH_SCREENS.CASH_QUALIFIER_TIME_TO,
        () => {
          history.push({
            pathname: `/game/${roomId}/${urlParams.get("scheduleId")}/${urlParams.get("date")}`,
            state: {},
          });
        },
        2000
      );

      isSplashCalled.current = true;
    }
  };

  if (!numTicketsPurchased || isLoadingTickets) return null;

  return (
    <>
      {isHostLive && <Host />}
      <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <div className="h-full flex flex-col justify-between">
          <motion.div className="xs:pt-2 text-shadow-sm relative z-10 flex flex-col w-full items-center lg:mt-3">
            <div className="max-w-max lg:p-2 flex flex-col items-center mt-6">
              <div className=" text-white font-bold text-lg">Show starts in</div>
              <div className=" text-white font-bold uppercase">
                <button
                  className="font-bold uppercase"
                  onClick={() => {
                    handleJoinGame();
                  }}
                >
                  <Countdown
                    ref={countdownRef}
                    date={new Date(parseInt(urlParams.get("date"), 10))}
                    intervalDelay={1000}
                    onTick={() => {
                      if (countdownRef.current.calcTimeDelta().total / 1000 <= cutoffSeconds) {
                        //setAllowedToBuy(false);
                        if (numTicketsPurchased.numTickets > 0) {
                          handleJoinGame();
                        }
                      }
                    }}
                    onComplete={() => {
                      if (numTicketsPurchased.numTickets > 0) {
                        handleJoinGame();
                      }
                    }}
                    renderer={({ days, hours, minutes, seconds }) => (
                      <div className="text-[64px] font-pdm-secondary leading-[96px]">
                        {hours < 1 ? (
                          <div className="text-[#F54C52]">
                            {minutes.toString().padStart(2, 0)}:{seconds.toString().padStart(2, 0)}
                          </div>
                        ) : (
                          <div className="text-white">{moment(new Date(parseInt(urlParams.get("date"), 10))).calendar()}</div>
                        )}
                      </div>
                    )}
                  />
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default withRouter(PreGameRoom);
