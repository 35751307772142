import initialState from "./model";
import { createSlice } from "@reduxjs/toolkit";

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setName(state, action) {
      const { name } = action.payload;
      state.name = name;
    },
    setWallet(state, action) {
      const { currency, balance, walletId } = action.payload;
      state.balance = { currency, balance, walletId };
    },
    setBalance(state, action) {
      const { balance } = action.payload;
      if (isNaN(balance)) throw "Balance was not a number";
      state.balance.balance = balance;
    },
    setIsFirstTime(state, action) {
      state.firstTime = true;
    },
    letsBegin(state, action) {
      if (action.payload) {
        const { skipped } = action.payload;
        if (skipped) {
          state.welcomeBack = true;
        }
      }

      state.page = 2;
    },
    initGame(state, action) {
      state.page = 1;
    },
    continueToGame(state, action) {
      state.page = 3;
    },
    setGameInProgress(state, action) {
      state.gameInProgress = action.payload;
    },
  },
});

// Selectors
export const selectPlayerName = (state) => state.player.name;

export const { setName, setWallet, setBalance, setIsFirstTime, letsBegin, initGame, continueToGame, addNotification, removeNotification, setGameInProgress } = playerSlice.actions;

export default playerSlice.reducer;
