import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "@use-it/interval";

// import { onAudienceVotesForRuthless } from "../../actions";
import { Ball, Decider } from "@ruthless/component-library";
import { selectGameUuid } from "../../../game/gameSlice";
import API from "../../../shared/libs/API";

const VoteOnAudience = ({ setWhatWouldYouDo }) => {
  // const { gameId } = useSelector((state) => state.game.data);
  const gameUuid = useSelector(selectGameUuid);

  const [count, setCount] = useState(20);
  const [vote, setVote] = useState(null);

  const dispatch = useDispatch();

  useInterval(() => {
    setCount((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
    });
  }, 1000);

  const handleChoice = async (choice) => {
    setVote(choice);

    try {
      await API.postAudienceVoteForRuthless(choice, gameUuid);

      setWhatWouldYouDo(true);
    } catch (error) {
      console.error("Could not post audience vote for ruthless", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-between h-full">
      <div className="flex flex-col items-center">
        <Ball variant="timer" className="z-10 w-[170px]" number={count ?? 0} subtitle="seconds" color="red" total={20} />
        <div className="text-white text-lg font-bold text-center mb-4 mt-2">The real question is what would you do?</div>
      </div>
      <Decider onValueChange={handleChoice} value={vote} />
    </div>
  );
};

export default VoteOnAudience;
