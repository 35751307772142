import { useSelector } from "react-redux";
import { Container } from "@ruthless/component-library";
import ladbrokesLogo from "../../../../public/img/newAssets/ladbrokesLogo.png";
import ResponsivePicture from "../../../ui/components/ResponsiveImage";
import { useGetAdsQuery } from "../../../api/api";

const AdScreen = () => {
  const { data: ads, isLoading } = useGetAdsQuery();

  return (
    <div className="w-full">
      <div className="text-center text-white text-2xl font-bold">Other products from</div>
      <div className="flex justify-center mt-4">
        <img src={ladbrokesLogo} alt="Ladbrokes Logo" />
      </div>

      <div className="flex flex-col space-y-4 text-white mt-14 w-full md:w-3/4 md:mx-auto">
        {!isLoading &&
          ads.map((ad) => (
            <Container key={ad.id} className="p-4 rounded-2xl">
              <div className="text-2xl font-bold">{ad.title}</div>
              <ResponsivePicture imgPath={ad.imageLink} className="h-24 border border-black mt-4" alt={ad.description} />
            </Container>
          ))}
      </div>
    </div>
  );
};

export default AdScreen;
