import { useState, useEffect } from "react";
import AudienceHost from "./AudienceVotes/AudienceHost";
import AudienceVotes from "./AudienceVotes/AudienceVotes";
import PastVotesView from "./PastVotesView";
import useSplashScreen from "../../shared/hooks/useSplashScreen";
import useDelay from "../../shared/hooks/useDelay";

const AudienceView = () => {
  const delay = useDelay();
  const [pastVotesFinished, setPastVotesFinished] = useState(false);
  const [whatWouldYouDo, setWhatWouldYouDo] = useState(false);

  useEffect(() => {
    delay(4000).then(() => {
      setPastVotesFinished(true);
    });
  }, []);

  if (!pastVotesFinished) {
    console.log("Screen: RuthlessFinal -> AudienceView -> PastVote");
    return (
      <div className="flex flex-col h-full">
        <PastVotesView />
      </div>
    );
  }

  if (pastVotesFinished && !whatWouldYouDo) {
    console.log("Screen: RuthlessFinal -> AudienceView -> AudienceVotes");
    return (
      <div className="h-full w-full flex flex-col items-center justify-evenly">
        <AudienceVotes setWhatWouldYouDo={setWhatWouldYouDo} />
      </div>
    );
  }

  if (pastVotesFinished && whatWouldYouDo) {
    console.log("Screen: RuthlessFinal -> AudienceView -> AudienceHost");
    return (
      <div>
        <AudienceHost />
      </div>
    );
  }
};

export default AudienceView;
