import { createSelector } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { COOKIE_NAME_RUTHLESS_ACCESS_TOKEN } from "@ruthless/sdk";
import moment from "moment";
import { selectOperator } from "../session/sessionSlice";

const api = createApi({
  reducerPath: "apiService",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const accessToken = state?.config?.[COOKIE_NAME_RUTHLESS_ACCESS_TOKEN];

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Rooms", "Schedule", "Tickets", "PurchasedTickets", "TicketTypes", "TicketCount"],
  endpoints: (builder) => ({
    getAds: builder.query({
      query: () => "/operator/ads",
    }),
    getRooms: builder.query({
      query: (operator) => ({
        url: "/rooms",
        method: "POST",
        body: {
          operator,
        },
      }),
      providesTags: ["Rooms"],
      transformResponse: (response) => {
        return response.rooms;
      },
    }),
    getBalance: builder.query({
      query: () => "/operator/balance",
      providesTags: ["Balance"],
    }),
    getSchedule: builder.query({
      query: ({ operator, scheduleIds }) => ({
        url: "/scheduler/schedule",
        method: "POST",
        body: {
          operators: [operator],
          schedules: scheduleIds,
        },
      }),
      providesTags: ["Schedule"],
      transformResponse: (response) => {
        return response.schedule;
      },
    }),
    getTicketsForGame: builder.query({
      query: ({ roomId, scheduleId, scheduleLocale, scheduleTime }) => ({
        url: "/ticket/list",
        method: "post",
        body: {
          room: roomId,
          schedule: scheduleId,
          ts: moment(scheduleTime).tz(scheduleLocale).format("YYYY-MM-DD HH:mm:ss"),
        },
      }),
      providesTags: ["Tickets"],
    }),
    getUserPurchasedTickets: builder.query({
      query: (start = 0) => `/purchases?start=${start}`,
      transformResponse: (response) => {
        return Object.entries(response.tickets.reducedTickets ?? {}).map(([key, value]) => ({ ticketId: key, ...value }));
      },
      providesTags: ["PurchasedTickets"],
    }),
    getTicketTypeForScheduleId: builder.query({
      query: (scheduleId) => `/ticket/${scheduleId}`,
    }),
    getNumOfTicketsPurchasedForScheduleId: builder.query({
      query: ({ scheduleId, roomId, scheduleLocale, date }) => ({
        url: "/ticket/count",
        method: "post",
        body: {
          room: roomId,
          schedule: scheduleId,
          ts: moment(date).tz(scheduleLocale).format("YYYY-MM-DD HH:mm:ss"),
        },
      }),
      providesTags: ["TicketCount"],
    }),
    buyGameTicket: builder.mutation({
      query: ({ roomId, ticketId, quantity, luckyFateNumber, prize, walletId, schedule }) => ({
        url: "/purchase",
        method: "post",
        body: {
          room: roomId,
          ticket: ticketId,
          schedule: schedule.id,
          quantity,
          ts: moment(schedule.time).tz(schedule.locale).valueOf(),
          walletId,
          luckyFateNumber,
          prize,
        },
      }),
      invalidatesTags: ["PurchasedTickets", "Tickets", "Balance", "TicketCount"],
    }),
    sendChatMessage: builder.mutation({
      query: ({ roomId, message }) => ({
        url: `/chat/post/${roomId}`,
        method: "POST",
        body: {
          text: message,
        },
      }),
    }),
  }),
});

export const {
  useGetAdsQuery,
  useGetRoomsQuery,
  useGetScheduleQuery,
  useGetTicketsForGameQuery,
  useGetUserPurchasedTicketsQuery,
  useBuyGameTicketMutation,
  useGetTicketTypeForScheduleIdQuery,
  useGetNumOfTicketsPurchasedForScheduleIdQuery,
  useLazyGetNumOfTicketsPurchasedForScheduleIdQuery,
  useGetBalanceQuery,
  useSendChatMessageMutation,
} = api;

export const selectRooms = createSelector(
  (state) => state,
  selectOperator,
  (state, operator) => {
    return api.endpoints.getRooms.select(operator)(state)?.data ?? [];
  }
);
export const selectUniqueScheduleIdsFromRooms = createSelector(selectRooms, (rooms) => Array.from(new Set(rooms.map((room) => room.schedule_id).flat())));

export default api;
