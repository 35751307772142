import { setName, setWallet as _setWallet } from "./reducer";

const receiveAlias = (alias) => setName({ name: alias });

const receiveBalance = (currency, balance, walletId) => _setWallet({ currency, balance, walletId });

export const init = (playerData) => (dispatch, getState) =>
  Promise.resolve().then(() => {
    const playerName = playerData.alias;
    // True indicates that this is a new player who needs a name
    if (playerName !== undefined && playerName !== true) {
      return dispatch(receiveAlias(playerName));
    }
  });

export const setWallet = (balances) => (dispatch, getState) =>
  Promise.resolve().then(() => {
    // TODO handle multiple wallets
    return dispatch(receiveBalance(balances[0].currency, balances[0].price, balances[0].walletId));
  });

export const getBalance = () => (dispatch, getState) =>
  Promise.resolve()
    .then(() => dispatch({ type: `api/operator/balance`, method: "get" }))
    .then(({ data }) => {
      const { balance } = data;
      if (balance) {
        return dispatch(receiveBalance(balance.currency, balance.price, balance.walletId));
      }
      throw "Failed to update balance.";
    })
    .catch((err) => {
      console.log({ err });
      return;
    });

// export const getDefaultChoices = () => (dispatch, getState) => {
//   return Promise.resolve()
//     .then(() => {
//       return dispatch({
//         type: `api/scheduler/default-ruthless-choice`,
//         method: "get",
//       });
//     })
//     .then(({ data, error }) => {
//       if (error) {
//         throw new Error(`could not get default choices: ${error}`);
//       }

//       return data;
//     });
// };

// export const setDefaultChoice = (ruthlessChoice) => (dispatch, getState) => {
//   Promise.resolve()
//     .then(() => {
//       return dispatch({
//         type: `api/scheduler/default-ruthless-choice`,
//         method: "patch",
//         data: {
//           ruthlessChoice,
//         },
//       });
//     })
//     .then(({ data, error }) => {
//       if (error) {
//         throw new Error(`could not set default choices: ${error}`);
//       }
//     });
// };
