import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import ladbrokesSponsor from "../../../public/img/newAssets/ladbrokesSponsor.png";

const GameSponsors = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.GAME_SPONSORS));

    return () => {
      dispatch(setCurrentScreen(null));
    };
  }, []);

  return (
    <div className="w-full text-center text-white font-bold mt-2 md:w-3/4 md:mx-auto">
      <div className="text-lg">Show sponsored by</div>
      <div className="flex flex-col justify-center items-center mt-4 space-y-4">
        <img src={ladbrokesSponsor} />
      </div>
    </div>
  );
};

export default GameSponsors;
