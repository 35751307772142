import moment from "moment";
import { motion } from "framer-motion";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, CTA, InfoCircleIcon, MoneyHandIcon, PoundCircleIcon, TimerIcon } from "@ruthless/component-library";
import ResponsivePicture from "../../ui/components/ResponsiveImage";
import Currency from "../../ui/components/Currency";
import { useGetTicketTypeForScheduleIdQuery } from "../../api/api";

const Ticket = ({ gotoGame, gotoPreGameRoom, gotoRoom, ticket, time, room, reorder, status }) => {
  // const ticketType = useSelector((state) => state.ticket.ticketTypes[ticket.ticket_type_id]);
  const { data: ticketType } = useGetTicketTypeForScheduleIdQuery(ticket.ticket_type_id);

  const { liveTickets } = useSelector((state) => state.livegames);

  const [currency, setCurrency] = useState();
  const [price, setPrice] = useState(); //ticketType.ticket;
  // const { code } = currency;

  const [inProgress, setInProgress] = useState(false);
  const [startingSoon, setStartingSoon] = useState(false);

  useEffect(() => {
    if (ticketType) {
      setCurrency(ticketType.currency);
      setPrice(ticketType.ticket.price);
    }
  }, [ticketType]);

  useEffect(() => {
    if (!liveTickets) return;
    if (liveTickets.length > 0) {
      setInProgress(true);
    } else {
      setInProgress(false);
    }
  }, [liveTickets]);

  useEffect(() => {
    const timeUntil = moment.duration(time.diff(Date.now())).asMinutes();
    setStartingSoon(timeUntil > 0 && timeUntil <= 15);
  }, [time]);

  const [sortOrder, setSortOrder] = useState(0);

  useEffect(() => {
    const distance = Math.floor(Math.abs(Date.now().valueOf() - new Date(ticket.scheduleTime).valueOf()) + (inProgress ? 10000 : startingSoon ? 8000 : 0));
    setSortOrder(distance);
  }, [time, ticket, inProgress, startingSoon]);

  const enterRoom = () => {
    if (inProgress) {
      gotoGame(room.id, ticket.schedule_id, time.valueOf());
    } else if (startingSoon) {
      gotoPreGameRoom(room.id, ticket.schedule_id);
    } else {
      gotoRoom(room.id, ticket.schedule_id);
    }
  };

  if (!currency) return null;

  return (
    <>
      <motion.div className="w-full mb-6 md:w-10/12" whileHover={{ scale: 1.02 }}>
        <Container className="p-4 w-full rounded-2xl">
          <div className="flex justify-between items-center text-lg font-bold text-white mb-3">
            <p>{room && room.props?.name}</p>
            <InfoCircleIcon className="stroke-white" />
          </div>
          <ResponsivePicture imgPath={`${room && room.props.schedule_image}`} alt="Room Logo" className="w-full rounded-lg max-h-28" />
          <div className="flex justify-between items-center font-bold text-white px-2 py-4 font-pdm-secondary">
            <div className="flex justify-center items-center">
              <TimerIcon className="stroke-[#F54C52]" />
              <span className="text-[#F54C52] ml-2">{status}</span>
            </div>
            <div className="flex items center justify-center stroke-[#A4A4A4]">
              <MoneyHandIcon className="mr-2" />
              <div>{ticketType && <Currency value={30} style="currency" currency={currency?.code} />}</div>
            </div>
            <div className="flex justify-center items-center">
              <PoundCircleIcon className="mr-2 stroke-[#A4A4A4]" />
              <Currency value={price * ticket.tickets} currency={currency?.code} style="currency" />
            </div>
          </div>
          <CTA onClick={enterRoom} className="w-full">
            {(inProgress && "JUMP IN!") || (startingSoon && "JOIN NOW!") || "JOIN ROOM"}
          </CTA>
        </Container>
      </motion.div>
    </>
  );
};

const memoTicket = memo(Ticket);

export default memoTicket;
