import Hostplaceholder from "../../ui/components/Hostplaceholder";
import PrizeballIntro from "../../../public/video/prizeball-intro.mp4";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setTrack } from "../../sound/reducer";
import { Ball } from "@ruthless/component-library";
import { VideoPlaceholder } from "../../ui/components/VideoManager";
import { selectCurrentBall } from "../../game/gameSlice";

// Step 21 - Ruthless Storyboard V3
const Intro = () => {
  const dispatch = useDispatch();
  const currentBall = useSelector(selectCurrentBall);

  // useEffect(() => {
  //   dispatch(setTrack("general-2"));
  // }, []);

  return (
    <>
      <Ball variant="cashQualifier" className="z-10 w-[175px] short:w-[210px]" number={currentBall} color="gold" />
      {/* <Hostplaceholder clip={PrizeballIntro} required={false} /> */}
      <VideoPlaceholder
        src={PrizeballIntro}
        required={false}
        // onVideoEnd={() => {
        //   console.log("VideoPlaceholder: Intro");
        //   // setIntroLoaded(true);
        // }}
        // duration={4000}
      />
    </>
  );
};

export default Intro;

// intro longer 15s
// pre reels 2s longer
