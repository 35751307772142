import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoIcon } from "@ruthless/component-library";

import { playEffect } from "../../sound/actions";
import { positiveFx } from "../../sound/utils/constants";
import { selectRuthlessCounter } from "../gameSlice";

const RuthlessCounter = () => {
  const ruthlessCounter = useSelector(selectRuthlessCounter);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ruthlessCounter === 0) return;

    dispatch(playEffect(positiveFx));
  }, [ruthlessCounter]);

  return (
    <>
      <div className="flex justify-center items-center text-white space-x-2">
        <div>
          <LogoIcon className="fill-red-500" />
        </div>
        <motion.span key={ruthlessCounter} initial={{ y: -100 }} animate={{ y: 0 }} className="text-lg ml-1">
          {ruthlessCounter}
        </motion.span>
      </div>
    </>
  );
};

export default RuthlessCounter;
