import HostPlaceholder from "../../../public/img/ruthless/hostplaceholder.png";
import { setTrack } from "../../sound/reducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// This component will be removed soon in favour of VideoManager.js
const Hostplaceholder = ({ clip, className, loop = true, required = true }) => {
  const hostIsLive = useSelector((s) => s.host.isLive);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTrack(undefined));
  }, []);

  if ((hostIsLive && !required) || !clip) {
    return null;
  }

  return (
    <div className={`relative opacity-80 ${className}`}>
      <video controlsList={"nodownload nofullscreen noremoteplayback"} disablePictureInPicture={true} disableRemotePlayback={true} loop={loop} playsInline={true} autoPlay={true} className={"w-full h-auto max-h-full"}>
        <source src={clip} type={"video/mp4"} />
      </video>
    </div>
  );
};

export default Hostplaceholder;
