import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPrizes } from "../../prizeball/reducer";
import ChoosePrize from "./ChoosePrize";
import ClaimTicket from "./ClaimTicket";
import LuckyFateNumber from "./LuckyFateNumber";
import { AnimatePresence } from "framer-motion";
import { withRouter } from "react-router-dom";
import Countdown from "../../pregame/components/Countdown";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { Tooltip } from "react-tooltip";

const PrePurchaseScreen = ({ numTicketsPurchased, nextGameStart, allowedToBuy, cutoffSeconds, setJoinable, onTick, countDown, room, numTicketsToBuy, setHasPurchasedTicket, history }) => {
  const [ticketClaimed, setTicketClaimed] = useState(false);
  const [luckyFateSelected, setLuckyFateSelected] = useState(false);
  //const [choosePrize, setChoosePrize] = useState(false);
  // const [aboutToStart, setAboutToStart] = useState(false);
  const [luckyFateNumber, setLuckyFateNumber] = useState("1");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrizes(nextGameStart.schedule.id));
  }, []);

  const handleOnCountDownComplete = () => {
    history.replace(`/`);
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {!ticketClaimed && (
        <ClaimTicket
          key="claimTicket"
          numTicketsPurchased={numTicketsPurchased}
          nextGameStart={nextGameStart}
          //allowedToBuy={allowedToBuy}
          //cutoffSeconds={cutoffSeconds}
          //setJoinable={setJoinable}
          //onTick={onTick}
          //countDown={countDown}
          setTicketClaimed={setTicketClaimed}
          // ticketType={ticketType}
        />
      )}

      {ticketClaimed && !luckyFateSelected && (
        <LuckyFateNumber key="luckyFateNumber" setLuckyFateSelected={setLuckyFateSelected} setLuckyFateNumber={setLuckyFateNumber} luckyFateNumber={luckyFateNumber} />
      )}

      {ticketClaimed && luckyFateSelected && (
        <ChoosePrize
          key="choosePrize"
          roomId={room.id}
          //room={room}
          //setChoosePrize={setChoosePrize}
          //choosePrize={choosePrize}
          // setAboutToStart={setAboutToStart}
          // aboutToStart={aboutToStart}
          //handleGameJoin={handleGameJoin}
          //allowedToBuy={allowedToBuy}
          //countDown={countDown}
          //onTick={onTick}
          //setJoinable={setJoinable}
          //cutoffSeconds={cutoffSeconds}
          nextGameStart={nextGameStart}
          numTicketsPurchased={numTicketsPurchased}
          //numTicketsToBuy={numTicketsToBuy}
          // ticketType={ticketType}
          luckyFateNumber={luckyFateNumber}
          setHasPurchasedTicket={setHasPurchasedTicket}
        />
      )}
      <div className="mt-auto">
        <div className="flex justify-center items-center text-white font-bold">
          <div>Time until game starts:</div>
          <div>
            <Countdown classNames="font-pdm-secondary font-bold mt-[3px] ml-1" nextGameStart={nextGameStart} onComplete={handleOnCountDownComplete} />
          </div>
          <QuestionMarkCircleIcon className="w-5 fill-white ml-1" data-tooltip-id="ruthless" data-tooltip-content="You will be moved to lobby when the time expires." />
          <Tooltip />
        </div>
      </div>
    </AnimatePresence>
  );
};

export default withRouter(PrePurchaseScreen);
