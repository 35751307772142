import React from "react";
import { motion } from "framer-motion";
import { Ball, CTA, Container } from "@ruthless/component-library";

const ClaimModal = ({ count, winningPrize, onClaimButton }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ background: "radial-gradient(76.67% 35.43% at 50% 39.1%, rgba(103, 158, 242, 0.9) 0%, rgba(7, 33, 73, 0.9) 100%)" }}
      className="fixed inset-0 z-[100]"
    >
      <div className="w-3/4 h-full mx-auto flex flex-col items-center justify-center">
        <div className="w-full md:max-w-[320px]">
          <div className="relative -top-16 text-white text-center">Your time is now! Claim your prize before the time’s out!</div>
          <Container className="w-full rounded-[40px] relative">
            <div className="m-1 border border-dashed border-white/20 rounded-[36px] flex flex-col items-center">
              <div className="relative -top-12">
                <Ball variant="timer" className="z-10 w-[170px]" number={count ?? 0} subtitle="seconds" color="red" total={10} />
                <div className="absolute -top-9 -bottom-9 -right-9 -left-9 border border-white/20 aspect-square rounded-full" />
                <div className="absolute -top-64 -bottom-64 -right-64 -left-64 border border-white/20 aspect-square rounded-full" />
                <div className="absolute -top-80 -bottom-80 -right-80 -left-80 border border-white/20 aspect-square rounded-full" />
              </div>
              <div className="py-2 w-10/12 text-center bg-black/20 mb-4 rounded-2xl font-pdm-secondary">
                <div className="textWithBgClip text-2xl font-bold">{winningPrize}</div>
              </div>
              <CTA className="w-11/12 mb-3" onClick={onClaimButton}>
                CLAIM
              </CTA>
            </div>
          </Container>
        </div>
      </div>
    </motion.div>
  );
};

export default ClaimModal;
