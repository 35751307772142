import axiosInstance from "./axios";

class API {
  static postAudienceVoteForWinners = (audienceChoices, gameUuid) => {
    return axiosInstance.post("/game/audience-ruthless-choices-for-winners", {
      audienceChoices,
      gameUuid,
    });
  };

  static postAudienceVoteForRuthless = (choice, gameUuid) => {
    return axiosInstance.post("/game/audience-ruthless-choice", {
      choice,
      gameUuid,
    });
  };

  static postRuthlessChoice = (choice, gameUuid, ticketUuid, userId) => {
    return axiosInstance.post("/game/make-ruthless-choice", {
      choice,
      gameUuid,
      ticketUuid,
      userId,
    });
  };

  static getDefaultChoice = () => {
    return axiosInstance.get("/scheduler/default-ruthless-choice");
  };

  static getPrizeBallPrizes = (scheduleId) => {
    return axiosInstance.get(`/operator/prize-ball-prizes/${scheduleId}`);
  };

  static claimPrizeBallPrize = (gameUuid, ticketUuid) => {
    return axiosInstance.patch("/game/claim-prize-ball-prize", { gameUuid, ticketUuid });
  };

  static claimRuthlessPrize = (gameUuid, ticketUuid) => {
    return axiosInstance.patch("/game/claim-ruthless-prize", { gameUuid, ticketUuid });
  };

  static postUserName = (name) => {
    return axiosInstance.post("/operator/alias", { name });
  };

  static postDefaultRuthlessChoice = (choice) => {
    return axiosInstance.patch("/scheduler/default-ruthless-choice", { ruthlessChoice: choice });
  };
}

export default API;
