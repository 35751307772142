import { typeCheck } from "type-check";

import { RECEIVE_CONFIG } from "./actionTypes";

const receiveConfig = (config) => ({
  type: RECEIVE_CONFIG,
  config,
});

export const init = (config) => (dispatch, getState) => {
  // TODO: Need more detailed validation of the config object.
  if (!typeCheck("Object", config)) {
    throw new Error(`Expected Object config, encountered ${config}.`);
  }
  console.log("receive config", config);
  return dispatch(receiveConfig(config));
};
