import { configureStore } from "@reduxjs/toolkit";
import { init as configs } from "./config";
import { init as playerData } from "./player";
import api from "./api/api";
import rootReducer from "./rootReducer";
import { setPlatformTechDifficulties } from "./tech-difficulties/actions";
import axiosInstance from "./shared/libs/axios";
import { COOKIE_NAME_RUTHLESS_ACCESS_TOKEN } from "@ruthless/sdk";
import { initSession } from "./session/sessionSlice";

export default (serverDefinedConfig, isServerSide, userData, platformTechDifficulties) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  });

  let { dispatch } = store;

  const accessToken = serverDefinedConfig[COOKIE_NAME_RUTHLESS_ACCESS_TOKEN];

  if (accessToken) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  }

  dispatch(configs(serverDefinedConfig));
  dispatch(setPlatformTechDifficulties(platformTechDifficulties === null ? false : platformTechDifficulties));
  dispatch(initSession({ isServerSide, accessToken }));
  dispatch(playerData(userData));

  return store;
};
