import { Vote } from "@ruthless/component-library";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { selectRuthlessChoiceTotals } from "../reducer";

const PastVotesView = () => {
  const ruthlessChoiceTotals = useSelector(selectRuthlessChoiceTotals);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.PAST_VOTES));
  }, []);

  const getPastVoteResults = () => {
    if (ruthlessChoiceTotals) {
      const results = ruthlessChoiceTotals.map(({ shareTotal, stealTotal, playerAlias }) => {
        const totalVotes = shareTotal + stealTotal;

        const sharePercentage = (shareTotal / totalVotes) * 100;
        const greenBlocks = Math.floor(sharePercentage / 10);

        return {
          name: playerAlias,
          shareBlocks: greenBlocks,
        };
      });

      return results;
    }

    return [];
  };

  return (
    <>
      <div className="text-white text-center font-bold text-lg mt-10">Results of how contestants have voted in the past:</div>
      <div className="space-y-4 mt-auto">
        {getPastVoteResults().map((result) => (
          <div key={result.name} className="flex flex-col justify-center items-center">
            <div className="text-white text-sm font-bold mb-2">{result.name}</div>
            <div className="w-full p-1 bg-white/20 rounded-[32px]">
              <div className="containerBg rounded-[32px] px-3 short:px-6 py-1.5 short:py-2.5 flex justify-between items-center border border-black/20">
                <div className="uppercase font-pdm-secondary font-bold text-base short:text-xl text-[#87E09E]">Share</div>
                <div className="flex space-x-0.5">
                  {Array.from(Array(10).keys()).map((v) => (
                    <Vote key={v} filled={true} color={v + 1 <= result.shareBlocks ? "green" : "red"} stack="vertically" />
                  ))}
                </div>
                <div className="uppercase font-pdm-secondary font-bold text-base short:text-xl text-[#F88286]">Steal</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PastVotesView;
