import Button from "@ruthless/component-library/dist/components/Buttons/CTA/CTA";
import { motion } from "framer-motion";

const Popup = ({ title, body, confirmButtonText = "Confirm", onConfirmCallback, cancelButtonText = "Cancel", onCancelCallback }) => {
  return (
    <motion.div className="absolute inset-0 bg-black/40 z-10 flex justify-center items-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: 0.5 }}>
      <motion.dialog
        open
        className="p-4 rounded-lg bg-pdm-blues-darkest text-white w-full max-w-[90%] md:max-w-lg text-center md:text-left"
        initial={{ y: -600 }}
        animate={{ y: 0 }}
        exit={{ scale: 0 }}
        transition={{ y: { delay: 0.5 } }}
      >
        <div className="font-pdm-secondary text-3xl font-bold">{title}</div>
        <div className="my-3">{body}</div>
        <div className="flex justify-between">
          <Button onClick={onConfirmCallback}>{confirmButtonText}</Button>
          <Button variant="secondary" onClick={onCancelCallback}>
            {cancelButtonText}
          </Button>
        </div>
      </motion.dialog>
    </motion.div>
  );
};

export default Popup;
