import Countdown from "react-countdown";
import moment from "moment";

const CountdownToNextGame = ({ countDownRef, nextGameStart, onComplete, onTick, classNames }) => {
  return (
    <Countdown
      ref={countDownRef}
      date={nextGameStart ? nextGameStart.date.toDate() : Date.now()}
      onComplete={onComplete}
      onTick={(data) => {
        if (onTick) {
          onTick(data);
        }
      }}
      intervalDelay={200}
      renderer={({ days, hours, minutes, seconds }) => (
        <div className={classNames}>
          {hours < 1 ? (
            <div className="text-[#F54C52]">
              {minutes.toString().padStart(2, 0)}:{seconds.toString().padStart(2, 0)}
            </div>
          ) : (
            <div className="text-white">{moment(nextGameStart.date.toDate()).calendar()}</div>
          )}
        </div>
      )}
    />
  );
};

export default CountdownToNextGame;
