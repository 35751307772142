import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../shared/libs/API";

// Get Prizes
export const getPrizes = createAsyncThunk("prizeBall/getPrizes", async (scheduleId) => {
  try {
    const { data } = await API.getPrizeBallPrizes(scheduleId);

    return data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  step: null,
  winningPrizeAndCounts: null,
  currentWinnersTickets: [],
  prizeBallClaimTime: null,
  spinNumber: null,
  shouldShowEndingSplashScreen: false,
};

const prizeBallSlice = createSlice({
  name: "prizeBall",
  initialState,
  reducers: {
    resetPrizeBallState: () => initialState,
    syncPrizeBallData: (state, action) => {
      const { step, winningPrizeAndCounts, tickets, prizeBallClaimTime, spinNumber } = action.payload;

      state.step = step;
      state.winningPrizeAndCounts = winningPrizeAndCounts;
      state.currentWinnersTickets = tickets;
      state.prizeBallClaimTime = prizeBallClaimTime;
      state.spinNumber = spinNumber;
    },
    showPrizeBallEndingSplashScreen: (state, action) => {
      state.shouldShowEndingSplashScreen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrizes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrizes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.prizeBallPrizes = action.payload;
      })
      .addCase(getPrizes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.prizeBallPrizes = [];
        state.message = action.payload;
      });
  },
});

// Selectors
export const selectPrizeBallStep = (state) => state.prizeBall.step;
export const selectWinningPrizeAndCounts = (state) => state.prizeBall.winningPrizeAndCounts;
export const selectCurrentWinnersTickets = (state) => state.prizeBall.currentWinnersTickets;
export const selectPrizeBallClaimTime = (state) => state.prizeBall.prizeBallClaimTime;
export const selectSpinNumber = (state) => state.prizeBall.spinNumber;
export const selectShouldShowPrizeBallEndingSplashScreen = (state) => state.prizeBall.shouldShowEndingSplashScreen;

export const { syncPrizeBallData, resetPrizeBallState, showPrizeBallEndingSplashScreen } = prizeBallSlice.actions;

export default prizeBallSlice.reducer;
