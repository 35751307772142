import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "../../ui/components/Chart";
import { PrizeSelect } from "@ruthless/component-library";
import pcImg from "../../../public/img/prizes/pc.png";
import ps5Img from "../../../public/img/prizes/ps5.jpg";
import xboxImg from "../../../public/img/prizes/xbox.jpg";
import animationData from "../../../public/lotte/golden-confetti.json";
import { selectWinningPrizeAndCounts } from "../reducer";

const VotedPrizes = () => {
  const refs = [useRef(), useRef(), useRef()];

  const winningPrizeAndCounts = useSelector(selectWinningPrizeAndCounts);

  const [winningPrizes, setWinningPrizes] = useState({});
  const [totalVotes, setTotalVotes] = useState(null);

  useEffect(() => {
    if (totalVotes === null && winningPrizeAndCounts === undefined) return;

    const filterdWinningPrizes = Object.fromEntries(Object.entries(winningPrizeAndCounts).filter(([key]) => !key.includes("winningPrize")));

    setWinningPrizes(filterdWinningPrizes);

    const values = Object.values(filterdWinningPrizes);

    const sum = values.reduce((acc, val) => {
      return acc + val;
    }, 0);

    setTotalVotes(sum);
  }, [totalVotes, winningPrizeAndCounts]);

  const getCoverImg = (prizeTitle) => {
    switch (prizeTitle) {
      case "Gaming PC":
        return pcImg;
      case "PS5":
        return ps5Img;
      case "XBOXX":
        return xboxImg;
      default:
        return `/img/prizes/${prizeTitle}.png`;
    }
  };

  const handleAnimationComplete = async (index) => {
    const highestVotedPrize = Object.keys(winningPrizes).reduce((a, b) => (winningPrizes[a] > winningPrizes[b] ? a : b));
    const highestVotedPrizeIndex = Object.keys(winningPrizes).findIndex((v) => v === highestVotedPrize);

    if (index === highestVotedPrizeIndex) {
      refs[index].current?.goToAndPlay?.(0, false);
    }
  };

  return (
    <div className="w-full">
      <div className="pointer-events-none mt-5 flex justify-center items-center space-x-3 xs:space-x-6 scale-[0.85] short:scale-100 -translate-y-5 short:translate-y-0">
        {typeof totalVotes === "number"
          ? Object.keys(winningPrizes).map((prize, index) => {
              const totalPrizeVotesPercent = Math.round((winningPrizes[prize] / totalVotes) * 100);
              const roundedPrizePercent = Math.round(totalPrizeVotesPercent * 10) / 10;

              return (
                <div key={index}>
                  <PrizeSelect ref={refs[index]} isSelected={false} animationData={animationData} prize={{ id: prize, title: prize, imageUrl: getCoverImg(prize) }} titlePosition="top" />
                  <Chart title={prize} key={prize} color="gold" progress={roundedPrizePercent || 0} onAnimationComplete={() => handleAnimationComplete(index)} />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default VotedPrizes;
