import AboutToStart from "./AboutToStart";
import Countdown from "../../pregame/components/Countdown";
import { createCalendarReminder } from "../utils/createCalendarReminder";
import { useDispatch } from "react-redux";
import { playEffect } from "../../sound/actions";
import { confirmationFx } from "../../sound/utils/constants";
import { AnimatePresence, motion } from "framer-motion";
import { Container, IconContainer, CTA, CalendarIcon } from "@ruthless/component-library";

// Step 8 - Ruthless Storyboard V3
const Reminder = ({ roomId, setAboutToStart, aboutToStart, allowedToBuy, countDown, onTick, setJoinable, cutoffSeconds, nextGameStart, numTicketsPurchased, scheduleTime }) => {
  const dispatch = useDispatch();

  return (
    <motion.div className="h-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <AnimatePresence exitBeforeEnter>
        {aboutToStart ? (
          <AboutToStart
            key="aboutToStart"
            roomId={roomId}
            //allowedToBuy={allowedToBuy}
            //countDown={countDown}
            //onTick={onTick}
            //setJoinable={setJoinable}
            //cutoffSeconds={cutoffSeconds}
            nextGameStart={nextGameStart}
            numTicketsPurchased={numTicketsPurchased}
          />
        ) : (
          <motion.div key="addToCalendar" className="flex flex-col justify-center items-center h-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="text-center text-white mb-6 short:mb-12">
              <div>You can wait here!</div>
              <div className="font-bold text-lg">Next game starts in</div>
              <Countdown
                classNames="text-[46px] short:text-[64px] font-bold font-pdm-secondary leading-[76px] short:leading-[96px]"
                //countDownRef={countDown}
                nextGameStart={nextGameStart}
              />
            </div>
            <Container className="flex flex-col justify-center items-center px-3 short:px-6 py-4 short:py-8 text-white w-full md:w-[360px] rounded-2xl">
              <IconContainer items={[{ icon: CalendarIcon }]} />
              <div className="text-center my-4 short:my-6">
                <div className="font-bold">Don’t miss it!</div>
                <div>Add the game to your calendar and get ready.</div>
              </div>
              <CTA variant="secondary" onClick={() => createCalendarReminder(scheduleTime)}>
                add to calendar
              </CTA>
            </Container>
            <CTA
              className="my-4 short:my-6"
              onClick={() => {
                dispatch(playEffect(confirmationFx));
                setAboutToStart(true);
              }}
            >
              Continue
            </CTA>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Reminder;
