import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import moment from "moment";

const LiveGame = ({ room, schedule_id, time, tickets }) => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  const enterRoom = () => {
    history.push(`/game/${room.id}/${schedule_id}/${time.valueOf()}`);
  };

  useEffect(() => {
    if (location.pathname === `/game/${room.id}/${schedule_id}/${time.valueOf()}`) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location.pathname]);

  if (!show) return null;

  return (
    <>
      <div
        onClick={enterRoom}
        className="bg-green-600 hover:bg-green-500 py-1 px-3 rounded-sm cursor-pointer text-center bg-opacity-75 flex flex-col items-start"
      >
        {room && room.props?.name} - {tickets} tickets
        <span className="text-xs text-pdm-main-base-lightest">
          Start time: {moment(time).format("LT")}
        </span>
      </div>
    </>
  );
};

export default LiveGame;
