import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasInitialDataLoaded: false,
  ballSequence: [],
  currentBall: 0,
  currentStep: 0,
  cashQualiferPrizes: {
    oneLine: null,
    twoLine: null,
    fullHouse: null,
  },
  gameState: null,
  hasPrizeBallCompleted: false,
  lineWinners: {},
  gameUuid: null,
  hasGameEnded: false,
  hasGameStarted: false,
  roomId: null,
  scheduleId: null,
  scheduleTime: null,
  gameTechDifficulties: false,
  ruthlessCounter: 0,
  playerAliases: [],
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    resetGameState: () => initialState,
    setGameTechDifficulties(state, action) {
      state.gameTechDifficulties = action.payload;
    },
    setInitialGameData: (state, action) => {
      const { gameUuid, hasGameEnded, hasGameStarted, roomId, scheduleId, scheduleTime } = action.payload;

      state.gameUuid = gameUuid;
      state.hasGameEnded = hasGameEnded;
      state.hasGameStarted = hasGameStarted;
      state.roomId = roomId;
      state.scheduleId = scheduleId;
      state.scheduleTime = scheduleTime;

      state.hasInitialDataLoaded = true;
    },
    syncGameDataWithStep: (state, action) => {
      const { ballSequence, currentBall, currentStep, gameState, cashQualiferPrizes, lineWinners, hasPrizeBallCompleted, hasGameEnded, ruthlessCounter, playerAliases } = action.payload;

      // We only set this once during initial load.
      if (state.ballSequence.length === 0) {
        state.ballSequence = ballSequence;
      }

      if (lineWinners) {
        state.lineWinners = lineWinners;
      }

      // probably best to always have this value available in the state
      state.hasPrizeBallCompleted = hasPrizeBallCompleted ?? false;
      state.cashQualiferPrizes = cashQualiferPrizes;
      state.currentBall = currentBall;
      state.currentStep = currentStep;
      state.gameState = gameState;
      state.hasGameEnded = hasGameEnded;
      state.ruthlessCounter = ruthlessCounter;
      state.playerAliases = playerAliases ?? [];
    },
  },
});

// Selectors
export const selectHasInitialDataLoaded = (state) => state.game.hasInitialDataLoaded;
export const selectGameUuid = (state) => state.game.gameUuid;
export const selectCurrentStep = (state) => state.game.currentStep;
export const selectCurrentBall = (state) => state.game.currentBall;
export const selectBallSequence = (state) => state.game.ballSequence;
export const selectGameHasEnded = (state) => state.game.hasGameEnded;
export const selectGameHasStarted = (state) => state.game.hasGameStarted;
export const selectGameState = (state) => state.game.gameState;
export const selectLineWinners = (state) => state.game.lineWinners;
export const selectGameTechDifficulties = (state) => state.game.gameTechDifficulties;
export const selectRecentLineWinners = createSelector(
  selectLineWinners,
  selectCurrentStep,
  (lineWinners, currentStep) =>
    Object.entries(lineWinners)
      .map(([key, value]) => ({ step: parseInt(key, 10), ...value }))
      .filter(({ step }) => step <= currentStep)
      .pop() ?? { type: null, winners: [], step: null }
);
export const selectCashQualifierPrizes = (state) => state.game.cashQualiferPrizes;
export const selectBallHistory = createSelector(selectCurrentStep, selectBallSequence, (currentStep, ballSequence) => ballSequence.slice(0, currentStep));
export const selectHasPrizeBallCompleted = (state) => state.game.hasPrizeBallCompleted;
export const selectRuthlessCounter = (state) => state.game.ruthlessCounter;
export const selectPlayerAliases = (state) => state.game.playerAliases;

export const { resetGameState, setGameTechDifficulties, setInitialGameData, syncGameDataWithStep } = gameSlice.actions;

export default gameSlice.reducer;
