import { useEffect } from "react";
import IntroRuthlessFinalVid from "../../../public/video/screen-39-40-41.mp4";
import Hostplaceholder from "../../ui/components/Hostplaceholder";
import { useDispatch } from "react-redux";
import { setCurrentScreen } from "../../ui/uiSlice";
import { SCREENS } from "../../globals";
import { VideoPlaceholder } from "../../ui/components/VideoManager";

const IntroRuthlessFinal = ({ setHasIntroFinished }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentScreen(SCREENS.RUTHLESS_INTRO));

    // setTimeout(() => {
    //   setHasIntroFinished(true);
    //   dispatch(setCurrentScreen(null));
    // }, 43500);
  }, []);

  return (
    <VideoPlaceholder
      src={IntroRuthlessFinalVid}
      required={false}
      // onVideoEnd={() => {
      //   console.log("VideoPlaceholder: IntroRuthlessFinal");
      //   setHasIntroFinished(true);
      //   dispatch(setCurrentScreen(null));
      // }}
      // duration={43500}
    />
  );
  // return <Hostplaceholder clip={IntroRuthlessFinalVid} required={false} />;
};

export default IntroRuthlessFinal;
