import { useEffect, useState } from "react";
import ChooseNameScreen from "./ChooseNameScreen";
import IntroScreen from "./IntroScreen";
import WelcomeScreen from "./WelcomeScreen";
import { setIsFirstTime } from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import { useGetRoomsQuery, useGetScheduleQuery } from "../../api/api";
import LogoScreen from "../../shared/components/LogoScreen";
import useDelay from "../../shared/hooks/useDelay";

const RenderContent = ({ onStartGame, skipToWelcome, page, playerName, welcomeBack, gotoGreeter, setFinished, rooms, operator }) => {
  const delay = useDelay();
  const dispatch = useDispatch();

  const { data: schedules, isSuccess } = useGetScheduleQuery({ operator, scheduleIds: Array.from(new Set(rooms.map((room) => room.schedule_id).flat())) });

  // useEffect(() => {
  //   if (isSuccess) {
  //     dispatch(getTicketTypesByScheduleIds(schedules));
  //   }
  // }, [isSuccess]);

  return (
    <div className="w-full md:w-[368px]">
      <IntroScreen onStartGame={onStartGame} skipNameScreen={skipToWelcome} active={page === 0} />
      <ChooseNameScreen active={page === 1} />
      <WelcomeScreen
        playerName={playerName}
        welcomeBack={welcomeBack}
        onContinueToGame={() => {
          console.log("continue to game");

          delay(1000).then(() => {
            setFinished(true);
          });

          gotoGreeter();
        }}
        active={page === 2}
      />
    </div>
  );
};

const PlayerNameModal = ({ onStartGame, page, playerName, welcomeBack, gotoGreeter }) => {
  const operator = useSelector((s) => s.session.operator);
  const { data: rooms, isSuccess: isSuccessfullyLoadedRooms } = useGetRoomsQuery(operator);

  const [finished, setFinished] = useState(false);
  const [skipToWelcome, setSkipToWelcome] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (page === 0 && playerName !== undefined) {
      setSkipToWelcome(true);
    }
    dispatch(setIsFirstTime(true));
  }, [playerName, page]);

  if (finished || !isSuccessfullyLoadedRooms) return null;

  return (
    <>
      <LogoScreen
        renderContent={
          <RenderContent
            onStartGame={onStartGame}
            skipToWelcome={skipToWelcome}
            page={page}
            playerName={playerName}
            welcomeBack={welcomeBack}
            gotoGreeter={gotoGreeter}
            setFinished={setFinished}
            operator={operator}
            rooms={rooms}
          />
        }
      />
    </>
  );
};

export default PlayerNameModal;
