import { useDispatch, useSelector } from "react-redux";
import useFirebase from "../../shared/hooks/useFirebase";
import { useEffect } from "react";
import { setIsLive, setAudioOnly, selectIsLiveHostVisible } from "../hostSlice";

const useHostConnection = (roomId, gameId) => {
  const dispatch = useDispatch();

  const isHostLive = useSelector(selectIsLiveHostVisible);

  const {
    firebase: { envName },
    onValueListener,
  } = useFirebase();

  useEffect(() => {
    if (!gameId) return;

    const unsubscribe = onValueListener(`/${envName}/HostLive/${roomId}/${gameId}`, (snapshot) => {
      const hostLiveData = snapshot.val();

      if (!hostLiveData) {
        dispatch(setIsLive(false));
        dispatch(setAudioOnly(false));

        return;
      }

      const audioData = hostLiveData["audioOnly"];
      const isLive = hostLiveData["live"];

      if (typeof audioData !== "boolean" || typeof isLive !== "boolean") {
        console.error("Invalid data received from HostLive");
        return;
      }

      dispatch(setIsLive(isLive));
      dispatch(setAudioOnly(audioData));
    });

    return () => {
      unsubscribe();
    };
  }, [isHostLive, gameId]);
};

export default useHostConnection;
